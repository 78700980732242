// @mui
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import Stack from "@mui/material/Stack"
import Divider from "@mui/material/Divider"
import CardHeader from "@mui/material/CardHeader"
import { CreditApplication, UploadedFile } from "../../../types"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material"
import { useState } from "react"
import FileListDialog from "./FileListDialog"

// ----------------------------------------------------------------------

export default function CreditApplicationDetailsInfo({
  application,
}: {
  application: CreditApplication
}) {
  const [viewDocument, setViewDocument] = useState<string | undefined>(
    undefined,
  )

  const [viewQST, setViewQST] = useState(undefined)
  const [viewFileList, setViewFileList] = useState(false)

  const fetchQST = (qstNumber: string) => {
    fetch("https://svcnab2b.revenuquebec.ca/2019/02/ValidationTVQ/" + qstNumber)
      .then((res) => {
        return res.json()
      })
      .then((data) => setViewQST(data))
      .catch((err) => {
        console.log(err)
      })
  }

  const renderUser = (
    <>
      <CardHeader title="Submitted By" />
      <Stack direction="row" sx={{ p: 3, gap: 3 }}>
        <Stack
          spacing={1.5}
          alignItems="flex-start"
          sx={{ typography: "body2" }}
        >
          <Stack direction="row" alignItems="center">
            <Box
              component="span"
              sx={{ color: "text.secondary", width: 120, flexShrink: 0 }}
            >
              Name
            </Box>
            {application.data.firstName + " " + application.data.lastName}
          </Stack>
          <Stack direction="row" alignItems="center">
            <Box
              component="span"
              sx={{ color: "text.secondary", width: 120, flexShrink: 0 }}
            >
              Email
            </Box>
            {application.data.email}
          </Stack>
        </Stack>
      </Stack>
    </>
  )

  const renderBusiness = (
    <>
      <CardHeader title="Business Info" />
      <Stack direction="row" sx={{ p: 3, gap: 3 }}>
        <Stack
          spacing={1.5}
          alignItems="flex-start"
          sx={{ typography: "body2" }}
        >
          <Stack direction="row" alignItems="center">
            <Box
              component="span"
              sx={{ color: "text.secondary", width: 120, flexShrink: 0 }}
            >
              Name
            </Box>
            {application.data.legalBusinessName}
          </Stack>
          <Stack direction="row" alignItems="center">
            <Box
              component="span"
              sx={{ color: "text.secondary", width: 120, flexShrink: 0 }}
            >
              DBA
            </Box>
            {application.data.businessDba}
          </Stack>
          <Stack direction="row" alignItems="center">
            <Box
              component="span"
              sx={{ color: "text.secondary", width: 120, flexShrink: 0 }}
            >
              Type
            </Box>
            {application.data.businessType}
          </Stack>
        </Stack>
        <Stack spacing={1.5} sx={{ typography: "body2" }}>
          <Stack direction="row" alignItems="center">
            <Box
              component="span"
              sx={{ color: "text.secondary", width: 120, flexShrink: 0 }}
            >
              GST/HST #
            </Box>
            {application.data.hstNumber}
          </Stack>
          {application.data.qstNumber && (
            <Stack direction="row" alignItems="center">
              <Box
                component="span"
                sx={{ color: "text.secondary", width: 120, flexShrink: 0 }}
              >
                QST #
              </Box>
              {application.data.qstNumber}
              <Button
                variant="contained"
                onClick={() => {
                  fetchQST(application.data.qstNumber)
                }}
              >
                Verify
              </Button>
              <Dialog
                open={!!viewQST}
                onClose={() => setViewQST(undefined)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  QST Search Result
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {viewQST && viewQST["Resultat"]
                      ? Object.keys(viewQST["Resultat"]).map((key) => {
                          return (
                            <div key={key}>
                              {key}: {viewQST["Resultat"][key]}
                            </div>
                          )
                        })
                      : ""}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setViewQST(undefined)} autoFocus>
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </Stack>
          )}

          <Stack direction="row" alignItems="center">
            <Box
              component="span"
              sx={{ color: "text.secondary", width: 120, flexShrink: 0 }}
            >
              Alcohol Permit #
            </Box>
            {application.data.alcoholPermitNumber}
          </Stack>
          <Stack direction="row" alignItems="center">
            <Box
              component="span"
              sx={{ color: "text.secondary", width: 120, flexShrink: 0 }}
            >
              Licenses
            </Box>
            {(!application.files || application.files.length === 0) && (
              <>None</>
            )}
            {application.files && application.files.length > 0 && (
              <Button
                id="view-licenses"
                variant="contained"
                onClick={() => {
                  setViewFileList(true)
                }}
              >
                View Files
              </Button>
            )}
          </Stack>
        </Stack>
      </Stack>
    </>
  )

  const renderAP = (
    <>
      <CardHeader title="Accounts Payable" />
      <Stack direction="row" sx={{ gap: 3 }}>
        <Stack spacing={1.5} sx={{ p: 3, typography: "body2" }}>
          <Stack direction="row" alignItems="center">
            <Box
              component="span"
              sx={{ color: "text.secondary", width: 120, flexShrink: 0 }}
            >
              Email
            </Box>
            {application.data.apEmail}
          </Stack>
        </Stack>
        <Stack spacing={1.5} sx={{ p: 3, typography: "body2" }}>
          <Stack direction="row" alignItems="center">
            <Box
              component="span"
              sx={{ color: "text.secondary", width: 120, flexShrink: 0 }}
            >
              Phone number
            </Box>
            {application.data.apPhoneNumber}
          </Stack>
        </Stack>
      </Stack>
    </>
  )

  const renderAddress = (
    <>
      <CardHeader title="Address" />
      <Stack direction="row" sx={{ gap: 3 }}>
        <Stack spacing={1.5} sx={{ p: 3, typography: "body2" }}>
          <Stack direction="row" alignItems="center">
            <Box
              component="span"
              sx={{ color: "text.secondary", width: 120, flexShrink: 0 }}
            >
              Address Line 1
            </Box>
            {application.data.address}
          </Stack>
          <Stack direction="row" alignItems="center">
            <Box
              component="span"
              sx={{ color: "text.secondary", width: 120, flexShrink: 0 }}
            >
              Address Line 2
            </Box>
            {application.data.addressLine2}
          </Stack>
          <Stack direction="row" alignItems="center">
            <Box
              component="span"
              sx={{ color: "text.secondary", width: 120, flexShrink: 0 }}
            >
              City
            </Box>
            {application.data.city}
          </Stack>
        </Stack>
        <Stack spacing={1.5} sx={{ p: 3, typography: "body2" }}>
          <Stack direction="row" alignItems="center">
            <Box
              component="span"
              sx={{ color: "text.secondary", width: 120, flexShrink: 0 }}
            >
              Region
            </Box>
            {application.data.region}
          </Stack>
          <Stack direction="row" alignItems="center">
            <Box
              component="span"
              sx={{ color: "text.secondary", width: 120, flexShrink: 0 }}
            >
              Postal Code
            </Box>
            {application.data.postCode}
          </Stack>
          <Stack direction="row" alignItems="center">
            <Box
              component="span"
              sx={{ color: "text.secondary", width: 120, flexShrink: 0 }}
            >
              Country
            </Box>
            {application.data.country}
          </Stack>
        </Stack>
      </Stack>
    </>
  )

  const renderOther = (
    <>
      <CardHeader title="Other" />
      <Stack spacing={1.5} sx={{ p: 3, typography: "body2" }}>
        <Stack direction="row" alignItems="center">
          <Box
            component="span"
            sx={{ color: "text.secondary", width: 120, flexShrink: 0 }}
          >
            Referral
          </Box>
          {application.data.hasReferral}{" "}
          {application.data.hasReferral === "Yes" && application.data.referrer}
        </Stack>
        <Stack direction="row" alignItems="center">
          <Box
            component="span"
            sx={{ color: "text.secondary", width: 120, flexShrink: 0 }}
          >
            Products Interested
          </Box>
          {application.data.categoriesInterested &&
            application.data.categoriesInterested.length > 0 &&
            application.data.categoriesInterested.join(", ")}
        </Stack>
      </Stack>
    </>
  )

  return (
    <Card>
      {renderUser}

      <Divider sx={{ borderStyle: "dashed" }} />

      {renderBusiness}

      <Divider sx={{ borderStyle: "dashed" }} />

      {renderAP}

      <Divider sx={{ borderStyle: "dashed" }} />

      {renderAddress}

      <Divider sx={{ borderStyle: "dashed" }} />

      {renderOther}

      <FileListDialog
        open={viewFileList}
        onClose={(file) => {
          setViewFileList(false)
          if (file) {
            setViewDocument((file as UploadedFile).file)
          }
        }}
        options={application.files}
      />

      {viewDocument && (
        <Dialog
          open={viewDocument !== undefined}
          onClose={() => {
            setViewDocument(undefined)
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <DialogTitle>View Files</DialogTitle>
          <DialogContent>
            <Box>
              {viewDocument.includes(".pdf") && (
                <iframe
                  src={viewDocument}
                  style={{
                    width: "1000px",
                    height: "75vh",
                  }}
                />
              )}
              {!viewDocument.includes(".pdf") && <img src={viewDocument} />}
            </Box>
          </DialogContent>
          {viewDocument.includes(".pdf") && (
            <DialogActions>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    setViewDocument(undefined)
                  }}
                >
                  Close
                </Button>
              </Box>
            </DialogActions>
          )}
          {!viewDocument.includes(".pdf") && (
            <DialogActions>
              <Button
                onClick={() => {
                  setViewDocument(undefined)
                }}
              >
                Close
              </Button>
            </DialogActions>
          )}
        </Dialog>
      )}
    </Card>
  )
}
