export const isFileValid = (file: File): boolean => {
  //   This function will validate when the file type is empty and ensure
  //   that the extension is .msg because browsers do not support identifying
  //   the file type for .msg files.

  if (file.type === "") {
    if (file.name.split(".").pop() === "msg") {
      return true
    }
    return false
  }
  return true
}
