import { useQuery } from "react-query"
import { ListBusiness } from "../../types"

export function useListInternalBusiness() {
  return useQuery<ListBusiness[]>("/internal/business", {
    retry: (failureCount, error) => {
      if (failureCount < 1) {
        return true
      }
      // do not retry on 404
      return (error as any)?.response?.status !== 404
    },
  })
}