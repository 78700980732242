import { useQuery } from "react-query"
import { OpenCorporateSearchInterface } from "src/types/vendors.types"
import { Converter } from "../converter"

/**
 * Is not enabled by default. use `refetch` to trigger on demand
 */
export function useOpenCorporateSearch(
  queryParam: string,
  perPage: number,
  page: number,
) {
  const queryKey = `/document/open_corporates_company_search?q=${queryParam}&per_page=${perPage}&page=${page}`

  return useQuery<OpenCorporateSearchInterface[]>(queryKey, {
    retry: (failureCount, error) => {
      if (failureCount < 1) {
        return true
      }
      // do not retry on 404
      return (error as any)?.response?.status !== 404
    },
    select: (data) => Converter.toOpenCorporateSearchArray(data),
  })
}

/**
 * Is not enabled by default. use `refetch` to trigger on demand
 */
export function useOpenCorporateGetCompanies(
  jurisdictionCode: string,
  companyNumber: string,
  perPage: number | undefined,
  page: number | undefined,
) {
  const queryKey = `/document/open_corporates_get_companies?jurisdiction_code=${jurisdictionCode}&company_number=${companyNumber}&per_page=${perPage}&page=${page}`

  const { isLoading, data, error, refetch } = useQuery<any>(queryKey, {
    retry: (failureCount, error) => {
      if (failureCount < 1) {
        return true
      }
      // do not retry on 404
      return (error as any)?.response?.status !== 404
    },
    select: (data) => Converter.toOpenCorporateGetCompanies(data),
  })
  return { isLoading, data: data || undefined, error, refetch }
}

/**
 * Is not enabled by default. use `refetch` to trigger on demand
 */
export function useOpenCorporateGetCompaniesEvents(
  jurisdictionCode: string,
  companyNumber: string,
  perPage: number | undefined,
  page: number | undefined,
) {
  const queryKey = `/document/open_corporates_get_companies_events?jurisdiction_code=${jurisdictionCode}&company_number=${companyNumber}&per_page=${perPage}&page=${page}`

  const { isLoading, data, error, refetch } = useQuery<any[]>(queryKey, {
    retry: (failureCount, error) => {
      if (failureCount < 1) {
        return true
      }
      // do not retry on 404
      return (error as any)?.response?.status !== 404
    },
    select: (data) => Converter.toOpenCorporateGetCompaniesEventsArray(data),
  })
  return { isLoading, data: data || [], error, refetch }
}
