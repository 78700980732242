import { useQuery } from "react-query"
import { ApplicationCount } from "src/types"

export function useCashApplicationCount() {
  return useQuery<ApplicationCount>("/cod/count", {
    retry: (failureCount, error) => {
      if (failureCount < 1) {
        return true
      }
      // do not retry on 404
      return (error as any)?.response?.status !== 404
    },
  })
}
