import { QueryClient } from "react-query"
import { useTypedMutation } from "../useTypedMutation"
import { ClientExperianCredentials } from "src/types"

export function useDeleteInternalExperianCredentials(onSuccess?: () => void) {
  const ENDPOINT = "/internal/experian/"
  const d = useTypedMutation<ClientExperianCredentials>(
    "/internal/experian/",
    onSuccess,
  )

  const execute = (body: ClientExperianCredentials) => {
    d.mutate(
      {
        method: "delete",
        endpoint: ENDPOINT + body.id + "/",
        body: body,
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
        },
      },
    )
  }
  return { ...d, execute }
}
