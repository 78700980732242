import { useQuery } from "react-query"
import { UserToBusiness } from "src/types"

export function useUserToBusinesses() {
  const djangoResponse = useQuery<UserToBusiness[]>("/associations/", {
    retry: (failureCount, error) => {
      if (failureCount < 1) {
        return true
      }
      // do not retry on 404
      return (error as any)?.response?.status !== 404
    },
  })

  return { ...djangoResponse }
}
