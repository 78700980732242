import { useTypedMutation } from "../useTypedMutation"

export function useAssignCashApplication() {
  const ENDPOINT = "/cod/base/assign"
  const post = useTypedMutation<{
    applicationId: string
    userId: string
  }>("post/cod/base/assign")

  const execute = (
    applicationId: string,
    userId: string,
    onSuccess?: () => void,
  ) => {
    post.mutate(
      {
        endpoint: ENDPOINT,
        body: {
          applicationId,
          userId,
        },
      },
      {
        onSuccess: async () => {
          if (onSuccess) onSuccess()
        },
      },
    )
  }
  return { ...post, execute }
}
