import { QueryClient } from "react-query"
import { useTypedMutation } from "../useTypedMutation"

export function usePatchRequestedCustomDocument(onPatchSuccess?: () => void) {
  const ENDPOINT = "/application/base/request_custom_document"

  const patch = useTypedMutation<FormData>(
    "patch/application/base/request_custom_document",
    onPatchSuccess,
  )

  const execute = (requestId: string, file: File) => {
    const formData = new FormData()
    formData.append("file", file)
    patch.mutate(
      {
        method: "patch",
        endpoint: ENDPOINT + "/" + requestId + "/",
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
        },
      },
    )
  }
  return { ...patch, execute }
}
