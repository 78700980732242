import {
  Button,
  FormControl,
  FormControlLabel,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material"
import { Formik } from "formik"
import { enqueueSnackbar } from "notistack"
import { usePatchCreditApplicationTemplatePage } from "src/queries/internal/usePatchCreditApplicationTemplatePage"
import { PAGE_LABEL_TRADE_REFERENCES } from "src/sections/@dashboard/intake_sections/constants"
import { ApplicationTemplate, Config, Page } from "src/types"
import { getConfigFromPage, getPageFromTemplate } from "src/utils/utils"
import SectionWhenComponent from "../SectionWhenComponent"

export default function CreditSectionTradeReferencesDetails({
  applicationTemplate,
  refetch,
}: {
  applicationTemplate: ApplicationTemplate
  refetch: () => void
}) {
  const page = getPageFromTemplate(
    applicationTemplate,
    PAGE_LABEL_TRADE_REFERENCES,
  )

  const { execute, isLoading } = usePatchCreditApplicationTemplatePage(
    applicationTemplate.id || "",
    () => {
      enqueueSnackbar("Application Template Updated", { variant: "success" })
    },
  )
  return (
    <>
      <Paper elevation={3} sx={{ marginY: 2, p: 1 }}>
        <Typography variant="h6" sx={{ marginBottom: 1 }}>
          {PAGE_LABEL_TRADE_REFERENCES} Section Configuration
        </Typography>
        <Formik
          initialValues={page ? page : ({} as Page)}
          onSubmit={(values) => {
            execute(values, () => {
              refetch()
            })
          }}
        >
          {(props) => (
            <FormControl component="fieldset" variant="standard">
              <Stack spacing={3}>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      name="enabled"
                      checked={props.values.enabled}
                      onChange={props.handleChange}
                    />
                  }
                  label="Enable Section"
                />
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      name="required"
                      checked={props.values.required as boolean}
                      onChange={props.handleChange}
                    />
                  }
                  label="Require Section"
                />
                <TextField
                  type="number"
                  name="minimumRequired"
                  label="Minimum Required"
                  value={
                    getConfigFromPage(props.values, "Minimum Required")
                      ?.value as number
                  }
                  onChange={(event) => {
                    const config = getConfigFromPage(
                      props.values,
                      "Minimum Required",
                    ) as Config
                    if (config) {
                      props.setFieldValue(
                        "config",
                        props.values.config.map((c) => {
                          if (c.label === "Minimum Required") {
                            c.value = Number(event.target.value)
                          }
                          return c
                        }),
                      )
                    } else {
                      props.values.config.push({
                        label: "Minimum Required",
                        description: "Minimum number of required references",
                        value: Number(event.target.value),
                      })
                    }
                  }}
                />
              </Stack>
              <Stack sx={{ marginY: 2 }}>
                <Button
                  variant="contained"
                  disabled={isLoading}
                  onClick={() => {
                    props.handleSubmit()
                  }}
                >
                  Save
                </Button>
              </Stack>
            </FormControl>
          )}
        </Formik>
        {page && (
          <SectionWhenComponent
            page={page}
            template={applicationTemplate}
            refetch={refetch}
          />
        )}
      </Paper>
    </>
  )
}
