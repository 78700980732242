import { Officers } from "src/types"
import { Box, Card, CardHeader, Stack, Typography } from "@mui/material"

interface OfficerRowProps {
  officer: Officers | undefined
}

const OfficerRow = ({ officer }: OfficerRowProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "200%",
        marginLeft: 2,
      }}
    >
      <Stack>
        <Typography variant="body2" color="text.secondary">
          Name
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Position
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Start Date
        </Typography>
        <Typography variant="body2" color="text.secondary">
          End Date
        </Typography>
      </Stack>
      <Stack sx={{ marginLeft: 2, width: "auto", flexGrow: 1 }}>
        <Typography variant="body2">{officer?.name ?? ""}</Typography>
        <Typography variant="body2">{officer?.position ?? ""}</Typography>
        <Typography variant="body2">{officer?.startDate ?? ""}</Typography>
        <Typography variant="body2">{officer?.endDate ?? ""}</Typography>
      </Stack>
    </Box>
  )
}
interface OfficersTableRowProps {
  officer: Officers
}

const OfficersTableRow = ({ officer }: OfficersTableRowProps) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: "8px",
      }}
    >
      <OfficerRow officer={officer} />
    </div>
  )
}

interface OfficersTableProps {
  officers: Officers[] | undefined
}

const OfficersTable = ({ officers }: OfficersTableProps) => {
  return (
    <>
      <Card sx={{ width: "100%" }}>
        <CardHeader
          title={
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "8px",
              }}
            >
              <Typography variant="h6">Officers</Typography>
            </Box>
          }
        />
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            p: 2,
            gap: 2,
          }}
        >
          {officers?.map((officer, index) => (
            <Box
              key={index}
              sx={{
                flex: "1 1 calc(50% - 16px)",
                marginBottom: "10px",
              }}
            >
              <OfficersTableRow officer={officer} />
            </Box>
          ))}
        </Box>
      </Card>
    </>
  )
}

export default OfficersTable
