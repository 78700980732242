import queryString from "query-string"
import { useQuery } from "react-query"
import { RequestedCustomDocument } from "src/types"

export function useRequestedCustomDocument(
  requestId?: string,
  applicationId?: string,
) {
  const params = queryString.stringify({
    application_id: applicationId,
    request_id: requestId,
  })
  const ENDPOINT =
    (requestId
      ? `/application/base/request_custom_document/${requestId}?`
      : "/application/base/request_custom_document/?") + params
  return useQuery<Array<RequestedCustomDocument>>(ENDPOINT, {
    enabled: !!requestId || !!applicationId,
    retry: (failureCount, error) => {
      if (failureCount < 1) {
        return true
      }
      // do not retry on 404
      return (error as any)?.response?.status !== 404
    },
  })
}
