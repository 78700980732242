import * as React from "react"

import { Box } from "@mui/material"
import { useLocation } from "react-router-dom"

import { Helmet } from "react-helmet-async"
import { useAnonymousBusiness } from "src/queries/credit/useAnonymousBusiness"

import Complete from "src/sections/@dashboard/intake_sections/Complete"

export default () => {
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const businessId = queryParams.get("business_id")

  const { data: businessData } = useAnonymousBusiness(businessId || "")

  React.useEffect(() => {
    setTimeout(() => {
      window.location.replace("https://www.netnow.io")
    }, 3000)
  }, [])

  return (
    <>
      <Helmet>
        <title>
          {businessData?.name
            ? `${businessData?.name} Credit Application`
            : "Credit Application"}
        </title>
      </Helmet>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          padding: "5%",
          maxHeight: "100vh",
          overflow: "auto",
        }}
      >
        <Box
          style={{ margin: "0 5% 32px 5%" }}
          display={{
            xs: "none",
            sm: "none",
            md: "block",
            large: "block",
            xlarge: "block",
          }}
        >
          {/* <QontoStepper
            steps={steps}
            // data={formik.values.data}
            activeStep={99}
            handleBack={handleBack}
          /> */}
        </Box>
        <Box
          style={{
            textAlign: "left",
            padding: "32px 10% 32px 10%",
            border: "solid",
            borderWidth: "1px",
            borderRadius: "16px",
            // borderColor: theme.palette.primary.main,
          }}
        >
          <Complete />
        </Box>
      </Box>
    </>
  )
}
