import { useState } from "react"
// @mui
// routes
import { RouterLink } from "src/routes/components"
// components
import Iconify from "src/components/iconify"
import {
  Check,
  Close,
  EditOutlined,
  PersonOutline,
  PictureAsPdf,
  SupportAgentOutlined,
} from "@mui/icons-material"
import { useDocumentPDF } from "src/queries/vendors/useDocumentPDF"
import { LoadingButton } from "@mui/lab"
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import { confirm } from "src/components/confirm"
import { usePatchCashApplication } from "src/queries/cod/usePatchCashApplication"
import { useSnackbar } from "notistack"
import { getStatusString } from "../utils"
import Label from "src/components/label"
import { fDateTime } from "src/utils/formatTime"
import { formatCurrency } from "src/utils/formatNumber"
import AssignmentSelectionDialog from "src/components/assignment/AssignmentSelectionDialog"
import { useUsers } from "src/queries/base/useUsers"
import { useAssignCashApplication } from "src/queries/cod/useAssignCashApplication"
import { stringAvatar } from "src/utils/avatar"
import CashApprovalDialog from "../cod/components/CashApprovalDialog"
import MenuItem from "@mui/material/MenuItem"
import DropdownMenuButton from "src/components/dropdown-menu-button"
import { error } from "src/utils/logger"
import { CashApplication, Group } from "src/types"
import { useCashApplication } from "src/queries/cod/useCashApplication"
import { startCase } from "lodash"
import { format } from "date-fns"

export default function CreditApplicationDetailsToolbar(
  { application, backLink }: { application: CashApplication; backLink: string }, // onChangeStatus,
) {
  const { refetch: fetchPdf, isLoading: isFetchingPdf } = useDocumentPDF(
    application ? application.data.zohoReqId : "",
    "cod",
  )

  const { execute: assignApplication } = useAssignCashApplication()

  const { enqueueSnackbar } = useSnackbar()

  const { data: users } = useUsers()

  const { refetch } = useCashApplication(application.id)

  const { execute: patchApplication } = usePatchCashApplication(() => {
    refetch()
    enqueueSnackbar("Application updated", { variant: "success" })
  })

  const [showApprovalDialog, setShowApprovalDialog] = useState(false)
  const [showAssignmentDialog, setShowAssignmentDialog] = useState<
    string | undefined
  >(undefined)

  return (
    <>
      <Stack
        spacing={3}
        direction={{ xs: "column", md: "row" }}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      >
        <Stack spacing={1} direction="row" alignItems="flex-start">
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <IconButton id="toolbar-back" component={RouterLink} href={backLink}>
            <Iconify icon="eva:arrow-ios-back-fill" />
          </IconButton>
          <Stack spacing={0.5}>
            <Stack spacing={1} direction="row" alignItems="center">
              <Typography variant="h4">
                {application.data.legalBusinessName ||
                  `${application.data.firstName} ${application.data.lastName}`}
              </Typography>
              <Label
                variant="soft"
                color={
                  (application.stage === 800 && "success") ||
                  (application.stage < 800 && "warning") ||
                  (application.stage === 900 && "error") ||
                  "default"
                }
              >
                {getStatusString(application.stage)}
              </Label>
              {application.data.customerId && (
                <Label variant="soft" color="default">
                  Customer ID {application.data.customerId}
                </Label>
              )}
            </Stack>
            {application.approvedAt && (
              <Stack spacing={1} direction="row" alignItems="center">
                <Typography variant="body2" sx={{ color: "text.disabled" }}>
                  Latest Approval
                </Typography>
                <Label variant="soft" color="default">
                  {fDateTime(application.approvedAt)}
                </Label>
                {application.data.creditLimit && (
                  <Label variant="soft" color="default">
                    {formatCurrency(
                      application.data.creditLimit,
                      application.data.currency,
                    )}
                  </Label>
                )}
                {application.data.netTerms && (
                  <Label variant="soft" color="default">
                    {application.data.netTerms}
                  </Label>
                )}
              </Stack>
            )}
            <Stack spacing={1} direction="row" alignItems="center">
              <Typography variant="body2" sx={{ color: "text.disabled" }}>
                Submitted
              </Typography>
              <Label variant="soft" color="default">
                {fDateTime(application.createdAt)}
              </Label>
            </Stack>
            {application.lastViewed && (
              <Stack spacing={1} direction="row" alignItems="center">
                <Typography variant="body2" sx={{ color: "text.disabled" }}>
                  Last Accessed
                </Typography>
                <Label variant="soft" color="default">
                  {fDateTime(application.lastViewed)}
                </Label>
              </Stack>
            )}
          </Stack>
        </Stack>
        <Box
          flexGrow={1}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            alignItems: "right",
          }}
        >
          <Stack
            spacing={1.5}
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            {application.stage == 800 && (
              <Button
                color="primary"
                variant="outlined"
                // endIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}
                onClick={() => {
                  setShowApprovalDialog(true)
                }}
                sx={{ textTransform: "capitalize" }}
              >
                Edit Approval
              </Button>
            )}
            {application.stage < 800 && (
              <>
                <DropdownMenuButton title="Approve/Reject">
                  <MenuItem
                    disableRipple
                    onClick={() => {
                      setShowApprovalDialog(true)
                    }}
                  >
                    <Check />
                    Approve
                  </MenuItem>
                  <MenuItem
                    disableRipple
                    onClick={() => {
                      confirm("You are about to reject this application.", {
                        okColor: "error",
                        okLabel: "Reject",
                      })
                        .then(() => {
                          return patchApplication(application.id || "", {
                            stage: 900,
                          })
                        })
                        .catch((err) => {
                          error("error in confirm dialog", err)
                        })
                    }}
                    sx={{ color: "error.main" }}
                  >
                    <Close style={{ fill: "red" }} />
                    Reject
                  </MenuItem>
                </DropdownMenuButton>
              </>
            )}
          </Stack>
          <Stack
            flexGrow={1}
            spacing={1.5}
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            {!application.assignee ? (
              <Button
                variant="outlined"
                startIcon={<PersonOutline />}
                onClick={() => setShowAssignmentDialog("CREDIT_MANAGER")}
              >
                Assign Credit Manager
              </Button>
            ) : (
              <Button
                variant="outlined"
                endIcon={<EditOutlined />}
                onClick={() => setShowAssignmentDialog("CREDIT_MANAGER")}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    {...stringAvatar(
                      `${
                        application.assignee.firstName
                          ? application.assignee.firstName
                          : application.assignee.email
                      }`,
                      {
                        width: 24,
                        height: 24,
                      },
                    )}
                  />
                  {application.assignee.firstName
                    ? `${application.assignee.firstName}${
                        application.assignee.lastName
                          ? " " + application.assignee.lastName[0]
                          : ""
                      }`
                    : application.assignee.email}
                </Box>
              </Button>
            )}
            {!application.salesRep ? (
              <Button
                variant="outlined"
                startIcon={<SupportAgentOutlined />}
                onClick={() => setShowAssignmentDialog("SALES")}
              >
                Assign Sales Rep
              </Button>
            ) : (
              <Button
                variant="outlined"
                endIcon={<EditOutlined />}
                onClick={() => setShowAssignmentDialog("SALES")}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px",
                    alignItems: "center",
                  }}
                >
                  <SupportAgentOutlined />
                  {application.salesRep.firstName
                    ? `${application.salesRep.firstName}${
                        application.salesRep.lastName
                          ? " " + application.salesRep.lastName[0]
                          : ""
                      }`
                    : application.salesRep.email}
                </Box>
              </Button>
            )}

            <LoadingButton
              color="primary"
              variant="outlined"
              startIcon={<PictureAsPdf />}
              loading={isFetchingPdf}
              disabled={application.stage < 200 || !application.data.zohoReqId}
              onClick={() => {
                fetchPdf()
                  .then((res) => {
                    function saveByteArray(fileName: string, b64: string) {
                      const link = document.createElement("a")
                      // link.href = window.URL.createObjectURL(blob)
                      link.href = "data:application/pdf;base64," + b64
                      link.download = fileName
                      link.click()
                    }
                    if (!res.data) throw new Error("No data")
                    return saveByteArray(
                      `${startCase(
                        application.data.legalBusinessName ||
                          `${application.data.firstName} ${application.data.lastName}`,
                      )} Cash Application ${format(
                        new Date(application.createdAt as string),
                        "yyyy/MM/dd",
                      )}.pdf`,
                      res.data,
                    )
                  })
                  .catch((err) => error(err))
              }}
            >
              Download Signed PDF
            </LoadingButton>
          </Stack>
        </Box>
      </Stack>

      {showApprovalDialog && (
        <CashApprovalDialog
          application={application}
          open
          onClose={() => {
            refetch()
            setShowApprovalDialog(false)
          }}
        />
      )}
      {users && showAssignmentDialog === "CREDIT_MANAGER" && (
        <AssignmentSelectionDialog
          open={!!showAssignmentDialog}
          onClose={() => {
            setShowAssignmentDialog(undefined)
          }}
          onSelect={(user) => {
            setShowAssignmentDialog(undefined)
            assignApplication(application.id || "", user.id, () => {
              refetch()
            })
          }}
          contacts={users
            .filter(
              (user) =>
                user.groups && user.groups.includes(Group.CreditManager),
            )
            ?.sort((a, b) => {
              if (!a.firstName) {
                return 1
              }
              if (!b.firstName) {
                return -1
              }
              return a.firstName < b.firstName ? -1 : 1
            })}
        />
      )}
      {users && showAssignmentDialog === "SALES" && (
        <AssignmentSelectionDialog
          open={!!showAssignmentDialog}
          onClose={() => {
            setShowAssignmentDialog(undefined)
          }}
          onSelect={(user) => {
            setShowAssignmentDialog(undefined)
            assignApplication(application.id || "", user.id, () => {
              refetch()
            })
          }}
          contacts={users
            .filter((user) => user.groups && user.groups.includes(Group.Sales))
            ?.sort((a, b) => {
              if (!a.firstName) {
                return 1
              }
              if (!b.firstName) {
                return -1
              }
              return a.firstName < b.firstName ? -1 : 1
            })}
        />
      )}
      {/* 
      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="top-right"
        sx={{ width: 140 }}
      >
        {statusOptions.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === status}
            onClick={() => {
              popover.onClose()
              onChangeStatus(option.value)
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </CustomPopover> */}
    </>
  )
}
