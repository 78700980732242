import { Application, ApplicationTemplate } from "src/types"
import * as yup from "yup"
import {
  addConditionalValidationstoCustomSchema,
  hasConditions,
} from "../../utils"
import { PAGE_LABEL_ADDITIONAL_QUESTIONS } from "../constants"

export const getAdditionalQuestionsSchema = (
  template: ApplicationTemplate,
  data: Application["data"] = {},
): yup.AnyObjectSchema => {
  let enabledSchema = yup.object({})
  let customSchema = yup.object({})
  template.customFields
    ?.filter(
      (field) => field.associatedPage === PAGE_LABEL_ADDITIONAL_QUESTIONS,
    )
    .filter((field) => field.required && !!field.id)
    .filter((field) => !hasConditions(field))
    .forEach((field) => {
      if (field.fieldType === "file") {
        customSchema = customSchema.concat(
          yup.object({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            [field.id!]: yup.mixed().required("Please upload a file"),
          }),
        )
      } else {
        customSchema = customSchema.concat(
          yup.object({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            [field.id!]: yup
              .object({
                response: yup.string().required("Field is required"),
              })
              .required("Field is required"),
          }),
        )
      }
    })
  if (data) {
    customSchema = addConditionalValidationstoCustomSchema(
      template,
      customSchema,
      data,
      PAGE_LABEL_ADDITIONAL_QUESTIONS,
    )
  }
  enabledSchema = enabledSchema.concat(
    yup.object({
      customFields: customSchema,
    }),
  )
  return enabledSchema
}
