import { TextField } from "@mui/material"
import { Box } from "@mui/system"
import { CreditApplication } from "src/types"
import ReactPhoneInput from "react-phone-input-material-ui"

export default ({
  suffix = "",
  data,
  onChange,
  errors,
}: {
  suffix?: string
  data: CreditApplication["data"]
  errors: CreditApplication["data"] | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (key: string, value: any) => void
}) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          gap: "5%",
        }}
      >
        <TextField
          id={"reference-name" + suffix}
          margin="normal"
          fullWidth
          label="Business Name"
          value={data[`referenceBusinessName${suffix}`]}
          onChange={(event) => {
            onChange("referenceBusinessName" + suffix, event.target.value)
          }}
          error={!!errors && Boolean(errors[`referenceBusinessName${suffix}`])}
        />
        <TextField
          id={"reference-account" + suffix}
          margin="normal"
          fullWidth
          label="Account # (Optional)"
          value={data[`referenceAccountNumber${suffix}`]}
          onChange={(event) => {
            onChange("referenceAccountNumber" + suffix, event.target.value)
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          gap: "5%",
        }}
      >
        <TextField
          id={"reference-email" + suffix}
          margin="normal"
          fullWidth
          label="Credit/AR Email"
          variant="outlined"
          value={data[`referenceEmail${suffix}`]}
          required
          onChange={(event) => {
            onChange("referenceEmail" + suffix, event.target.value)
          }}
          error={!!errors && Boolean(errors[`referenceEmail${suffix}`])}
          helperText={
            !!errors && `referenceEmail${suffix}` in errors
              ? errors[`referenceEmail${suffix}`]
              : ""
          }
        />
        <ReactPhoneInput
          value={data[`referencePhoneNumber${suffix}`]}
          country={"us"}
          onChange={(event) => {
            onChange("referencePhoneNumber" + suffix, "+" + event)
          }}
          label="Credit/AR Phone Number"
          component={TextField}
          countryCodeEditable={false}
          containerStyle={{ width: "100%", marginTop: "8px" }}
          inputProps={{
            id: "reference-phone-number" + suffix,
            style: { marginTop: "8px" },
            required: true,
            error: !!errors && Boolean(errors[`referencePhoneNumber${suffix}`]),
          }}
        />
        <ReactPhoneInput
          value={data[`referenceFaxNumber${suffix}`]}
          country={"us"}
          onChange={(event) => {
            onChange("referenceFaxNumber" + suffix, "+" + event)
          }}
          label="Credit/AR Fax Number"
          component={TextField}
          countryCodeEditable={false}
          containerStyle={{ width: "100%", marginTop: "8px" }}
          inputProps={{
            id: "reference-fax-number" + suffix,
            style: { marginTop: "8px" },
            required: true,
            error: !!errors && Boolean(errors[`referenceFaxNumber${suffix}`]),
          }}
        />
      </Box>
    </>
  )
}
