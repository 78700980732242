import * as yup from "yup"
import { ApplicationTemplate, CreditApplication } from "src/types"
import {
  PAGE_LABEL_TRADE_REFERENCES,
  TRADE_REFERENCE_CONFIGS,
} from "../constants"
import {
  isPageEnabled,
  isPageRequired,
  isSubSectionRequired,
} from "../template_helpers"

const getMinimumRequiredReferences = (
  minimum: number,
  template: ApplicationTemplate,
) => {
  const yupObj = {} as { [key: string]: yup.AnySchema }
  new Array(minimum).fill(0).forEach((_, i) => {
    yupObj["referenceBusinessName" + (i + 1)] = yup.string().required()
    yupObj["referenceEmail" + (i + 1)] = yup
      .string()
      .email("Please enter a valid email address")
      .required("Please enter a valid email address")
      .notOneOf(
        new Array(minimum)
          .fill(0)
          .map((_, j) =>
            i === j ? undefined : yup.ref("referenceEmail" + (j + 1)),
          ),
        "Reference emails must be unique",
      )
    yupObj["referencePhoneNumber" + (i + 1)] = yup
      .string()
      .required()
      .matches(/\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/, "Phone number is not valid")
  })
  if (
    isSubSectionRequired(
      template,
      PAGE_LABEL_TRADE_REFERENCES,
      TRADE_REFERENCE_CONFIGS.FAX_NUMBER,
    )
  ) {
    new Array(minimum).fill(0).forEach((_, i) => {
      yupObj["referenceFaxNumber" + (i + 1)] = yup
        .string()
        .required()
        .matches(/\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/, "Fax number is not valid")
    })
  }
  return yup.object(yupObj)
}

export const getTradeEnabledSchema = (
  template: ApplicationTemplate,
  data?: CreditApplication["data"],
): yup.AnyObjectSchema => {
  let res = yup.object({})
  const minimum =
    Number(
      template?.formTemplate?.pages
        .find((tstep) => tstep.label === PAGE_LABEL_TRADE_REFERENCES)
        ?.config?.find((c) => c.label === "Minimum Required")?.value,
    ) || 3
  if (
    isPageEnabled(PAGE_LABEL_TRADE_REFERENCES, template, data) &&
    isPageRequired(PAGE_LABEL_TRADE_REFERENCES, template, data)
  ) {
    res = res.concat(getMinimumRequiredReferences(minimum, template))
  }

  const isFieldPopulated = (field: string, isPhoneNumber = false) => {
    if (isPhoneNumber && field === "+") {
      return false
    }

    if (field && field.length > 0) {
      return true
    }
    return false
  }

  const numOptionals = isPageRequired(
    PAGE_LABEL_TRADE_REFERENCES,
    template,
    data,
  )
    ? 3 - minimum
    : 3

  new Array(numOptionals).fill(0).forEach((_, i) => {
    const obj = {} as { [key: string]: yup.AnySchema }
    const referenceNumber = isPageRequired(
      PAGE_LABEL_TRADE_REFERENCES,
      template,
      data,
    )
      ? i + minimum + 1
      : i + 1
    obj["referenceEmail" + referenceNumber] = yup
      .string()
      .when(
        [
          `referencePhoneNumber${referenceNumber}`,
          `referenceBusinessName${referenceNumber}`,
          `referenceFaxNumber${referenceNumber}`,
        ],
        {
          is: (fieldTwo: string, fieldThree: string, fieldFour: string) =>
            isFieldPopulated(fieldTwo, true) ||
            isFieldPopulated(fieldThree) ||
            isFieldPopulated(fieldFour, true),
          then: yup
            .string()
            .email("Please enter a valid email address")
            .required(
              "Please enter a valid email address or leave this reference unfilled",
            )
            .notOneOf(
              new Array(3)
                .fill(0)
                .map((_, j) =>
                  referenceNumber === j + 1
                    ? undefined
                    : yup.ref("referenceEmail" + (j + 1)),
                ),
              "Reference emails must be unique",
            ),
        },
      )
    res = res.concat(yup.object(obj))
  })
  return res
}
