import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material"
import { Formik } from "formik"
import { useState } from "react"
import AssignmentSelectionDialog from "src/components/assignment/AssignmentSelectionDialog"
import { useInternalAllUsers } from "src/queries/internal/useInternalAllUsers"
import { Group, OnboardingAssignment, User } from "src/types"
import * as yup from "yup"
import { enqueueSnackbar } from "notistack"
import { EditOutlined, SupportAgentOutlined } from "@mui/icons-material"
import { useCreateInternalOnboardingAssignment } from "src/queries/internal/useCreateInternalOnboardingAssignment"

export default function AssignOnboardingTaskDialog({
  open,
  onClose,
  businessId,
  refetch,
}: {
  open: boolean
  onClose: () => void
  businessId: string
  refetch: () => void
}) {
  const { data: usersData } = useInternalAllUsers()
  const [showAssignmentDialog, setShowAssignmentDialog] = useState(false)
  const { execute: executeCreateInternalOnboardingAssignment, isLoading } =
    useCreateInternalOnboardingAssignment()
  const [currentUser, setCurrentUser] = useState<User | undefined>(undefined)

  const schema = yup.object({
    user: yup.string().required("Required"),
    business: yup.string().required("Required"),
  })

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth={"md"} fullWidth>
        <DialogTitle id="alert-dialog-title">Add User To Group</DialogTitle>
        <DialogContent sx={{ p: 2 }}>
          <Formik
            initialValues={{ business: businessId } as OnboardingAssignment}
            onSubmit={(values: OnboardingAssignment) => {
              executeCreateInternalOnboardingAssignment(values, () => {
                refetch()
                onClose()
                enqueueSnackbar("Onboarding Assigned updated", {
                  variant: "success",
                })
              })
            }}
            validationSchema={schema}
          >
            {(props) => (
              <>
                <Stack direction={"row"} spacing={1} sx={{ m: 1 }}>
                  {!(props.values.user && currentUser) ? (
                    <Button
                      variant="outlined"
                      startIcon={<SupportAgentOutlined />}
                      onClick={() => setShowAssignmentDialog(true)}
                    >
                      Select User
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      endIcon={<EditOutlined />}
                      onClick={() => setShowAssignmentDialog(true)}
                    >
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "8px",
                          alignItems: "center",
                        }}
                      >
                        <SupportAgentOutlined />
                        {currentUser.firstName
                          ? `${currentUser.firstName} ${currentUser.lastName}`
                          : currentUser.email}
                      </Box>
                    </Button>
                  )}
                </Stack>
                {usersData && (
                  <AssignmentSelectionDialog
                    open={!!showAssignmentDialog}
                    onClose={() => {
                      setShowAssignmentDialog(false)
                    }}
                    onSelect={(user) => {
                      setShowAssignmentDialog(false)
                      props.setFieldValue("user", user.id)
                      setCurrentUser(user)
                    }}
                    contacts={usersData
                      .filter((user) => {
                        if (
                          user.groups?.includes(Group.NetNowAdmin) &&
                          user.email &&
                          user.email.endsWith("@netnow.io")
                        ) {
                          return true
                        }
                        return false
                      })
                      .sort((a, b) => {
                        if (!a.firstName) {
                          return 1
                        }
                        if (!b.firstName) {
                          return -1
                        }
                        return a.firstName < b.firstName ? -1 : 1
                      })}
                  />
                )}
                <Stack direction={"row"} spacing={1} sx={{ m: 1 }}>
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    name="notes"
                    value={props.values.notes}
                    onChange={props.handleChange}
                    label="Notes"
                  />
                </Stack>
                <Stack spacing={1} sx={{ m: 1 }}>
                  <Button
                    variant="contained"
                    size="small"
                    fullWidth
                    disabled={isLoading}
                    onClick={() => {
                      props.handleSubmit()
                    }}
                  >
                    Submit
                  </Button>
                </Stack>
              </>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
