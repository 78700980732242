import * as Sentry from "@sentry/react"
import { ApplicationTemplate, CreditApplication } from "src/types"
import { getDataKeyName } from "../../utils"

const testCondition = (
  dataValue: string,
  condition: {
    value: string | Array<string>
  },
) => {
  if (Array.isArray(condition.value)) {
    return condition.value.includes(dataValue)
  }
  return dataValue === condition.value
}

export const isPageRequired = (
  label: string,
  template:
    | ApplicationTemplate
    | { formTemplate: ApplicationTemplate["formTemplate"] }
    | undefined,
  data?: CreditApplication["data"],
) => {
  if (template === undefined) {
    return true
  }
  const config = template?.formTemplate?.pages.find(
    (tstep) => tstep.label === label,
  )

  if (!config) {
    return false
  }

  if (!data) {
    return Boolean(config.required)
  }

  if (config.requiredWhen) {
    for (const condition of config.requiredWhen) {
      if (condition.dataKey) {
        const keyName = getDataKeyName(condition.dataKey, label)
        if (!keyName) {
          Sentry.captureException(Error("Invalid dataKey " + condition.dataKey))
          return false
        }
        if (!testCondition(data[keyName], condition)) {
          return false
        }
      } else if (condition.customFieldId && data.customFields) {
        if (
          !testCondition(
            data.customFields[condition.customFieldId]
              ? data.customFields[condition.customFieldId].response
              : undefined,
            condition,
          )
        ) {
          return false
        }
      } else {
        Sentry.captureException(Error("Invalid requiredWhen condition"))
        return false
      }
    }
    return true
  } else if (config.optionalWhen) {
    let isOptional = false
    for (const condition of config.optionalWhen) {
      if (condition.dataKey) {
        const keyName = getDataKeyName(condition.dataKey, label)
        if (!keyName) {
          Sentry.captureException(Error("Invalid dataKey " + condition.dataKey))
          continue
        }
        if (testCondition(data[keyName], condition)) {
          isOptional = true
        } else {
          isOptional = false
        }
      } else if (condition.customFieldId && data.customFields) {
        if (
          !testCondition(
            data.customFields[condition.customFieldId]
              ? data.customFields[condition.customFieldId].response
              : undefined,
            condition,
          )
        ) {
          isOptional = false
        } else {
          isOptional = true
        }
      } else {
        Sentry.captureException(Error("Invalid requiredWhen condition"))
        continue
      }
    }
    return isOptional ? false : returnDefaultRequired(config)
  } else {
    return returnDefaultRequired(config)
  }
}

export const isSubSectionEnabled = (
  template:
    | ApplicationTemplate
    | { formTemplate: ApplicationTemplate["formTemplate"] }
    | undefined,
  page: string,
  configLabel: string,
) => {
  if (!template) return false

  const enabled = template?.formTemplate.pages
    .find((step) => step.label === page)
    ?.config?.find((config) => config.label === configLabel)?.value

  if (enabled === undefined || enabled === null) return true // default enabled

  return Boolean(enabled)
}

export const isSubSectionRequired = (
  template:
    | ApplicationTemplate
    | { formTemplate: ApplicationTemplate["formTemplate"] }
    | undefined,
  page: string,
  configLabel: string,
  data?: CreditApplication["data"],
) => {
  if (!template) return false

  if (!isPageEnabled(page, template, data)) {
    return false
  }

  if (!isPageRequired(page, template, data)) {
    return false
  }

  if (!isSubSectionEnabled(template, page, configLabel)) {
    return false
  }

  const req = template?.formTemplate.pages
    .find((step) => step.label === page)
    ?.config?.find((config) => config.label === configLabel)?.required

  if (req === undefined || req === null) return true // default required
  return Boolean(req)
}

const returnDefaultRequired = (
  config: ApplicationTemplate["formTemplate"]["pages"][0],
) => {
  if (config.required === undefined) {
    // by default required (user and business pages)
    return true
  }
  return Boolean(config.required)
}

const returnDefaultEnabled = (
  config: ApplicationTemplate["formTemplate"]["pages"][0],
) => {
  if (config.enabled === undefined) {
    // by default enabled (user and business pages)
    return true
  }
  return Boolean(config.enabled)
}

export const isPageEnabled = (
  label: string,
  template:
    | ApplicationTemplate
    | { formTemplate: ApplicationTemplate["formTemplate"] }
    | undefined,
  data?: CreditApplication["data"],
) => {
  const config = template?.formTemplate?.pages.find(
    (tstep) => tstep.label === label,
  )

  if (!config) {
    return false
  }

  if (!data) {
    return Boolean(config.enabled)
  }

  if (config.enabledWhen) {
    for (const condition of config.enabledWhen) {
      if (condition.dataKey) {
        const keyName = getDataKeyName(condition.dataKey, label)
        if (!keyName) {
          Sentry.captureException(Error("Invalid dataKey " + condition.dataKey))
          return false
        }
        if (!testCondition(data[keyName], condition)) {
          return false
        }
      } else if (condition.customFieldId && data.customFields) {
        if (
          !testCondition(
            data.customFields[condition.customFieldId]
              ? data.customFields[condition.customFieldId].response
              : undefined,
            condition,
          )
        ) {
          return false
        }
      } else {
        Sentry.captureException(Error("Invalid enabledWhen condition"))
        return false
      }
    }
    return true
  } else if (config.disabledWhen) {
    let isDisabled = false
    for (const condition of config.disabledWhen) {
      if (condition.dataKey) {
        const keyName = getDataKeyName(condition.dataKey, label)
        if (!keyName) {
          Sentry.captureException(Error("Invalid dataKey " + condition.dataKey))
          continue
        }
        if (testCondition(data[keyName], condition)) {
          isDisabled = true
        } else {
          isDisabled = false
        }
      } else if (condition.customFieldId && data.customFields) {
        if (
          !testCondition(
            data.customFields[condition.customFieldId]
              ? data.customFields[condition.customFieldId].response
              : undefined,
            condition,
          )
        ) {
          isDisabled = false
        } else {
          isDisabled = true
        }
      } else {
        Sentry.captureException(Error("Invalid requiredWhen condition"))
        continue
      }
    }
    return isDisabled ? false : returnDefaultEnabled(config)
  } else {
    return returnDefaultEnabled(config)
  }
}
