import { useQuery } from "react-query"
import { EmailPreference } from "../../types"

export function useListInternalEmailPreferences(
  business_id: string,
  user_id: string,
) {
  return useQuery<EmailPreference>(
    "/internal/email-preferences?business_id=" +
      business_id +
      "&user_id=" +
      user_id,
    {
      retry: (failureCount, error) => {
        if (failureCount < 1) {
          return true
        }
        // do not retry on 404
        return (error as any)?.response?.status !== 404
      },
    },
  )
}
