import { alpha } from "@mui/material/styles"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import { ExperianSearchResult } from "src/types/vendors.types"
import React from "react"
import { Box, Stack } from "@mui/system"

interface ResultItemProps {
  data: ExperianSearchResult[] | undefined
  onClick?: (item: ExperianSearchResult) => void
  purchasedIds: string[]
}

import usFlag from "src/resources/flags/us.png"
import caFlag from "src/resources/flags/ca.png"
import defaultFlag from "src/resources/flags/globe.png"
import { Typography } from "@mui/material"
import Label from "src/components/label"
import { BusinessOutlined } from "@mui/icons-material"

function getImage(country: string): string {
  if (country) {
    if (country.startsWith("US")) {
      return usFlag
    } else if (country.startsWith("CA")) {
      return caFlag
    }
  }

  return defaultFlag
}

export default ({ data, onClick, purchasedIds }: ResultItemProps) => {
  return (
    <div>
      {data?.map((item, index) => (
        <ListItemButton
          onClick={() => onClick?.(item)}
          sx={{
            borderWidth: 1,
            borderStyle: "dashed",
            borderColor: "transparent",
            borderBottomColor: (theme) => theme.palette.divider,
            "&:hover": {
              borderRadius: 1,
              borderColor: (theme) => theme.palette.primary.main,
              backgroundColor: (theme) =>
                alpha(
                  theme.palette.primary.main,
                  theme.palette.action.hoverOpacity,
                ),
            },
          }}
          key={index}
        >
          <BusinessOutlined style={{ margin: "0 16px 0 0" }} />
          <ListItemText
            primary={
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "8px",
                }}
              >
                <Typography>{item.businessName ?? ""}</Typography>
                {/* {purchasedIds.includes(item.id) && (
                  <Label color={"success"}>PURCHASED</Label>
                )} */}
                {/* <Label color={"info"}>{item.status}</Label>
                <Label color={"info"}>{item.officeType}</Label> */}
              </Box>
            }
            secondary={
              <Box
                component="span"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  display: "inline-block",
                  maxWidth: "80%",
                }}
              >
                {`${item.address.street} ${item.address.city} ${item.address.state} ${item.address.zip}`}
              </Box>
            }
            primaryTypographyProps={{
              style: { whiteSpace: "normal", wordWrap: "break-word" },
            }}
            secondaryTypographyProps={{
              style: {
                display: "block",
                whiteSpace: "normal",
                wordWrap: "break-word",
              },
            }}
          />
        </ListItemButton>
      ))}
    </div>
  )
}
