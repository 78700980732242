import { UseMutationResult } from "react-query"
import { useTypedMutation } from "../useTypedMutation"
import { MutationVariables, Business } from "../../types"

type ExecuteType = {
  execute: (id: string, body: Partial<Business>, onSuccess: () => void) => void
}
export type UsePatchBusinessType = UseMutationResult<
  unknown,
  unknown,
  MutationVariables<Business>,
  unknown
> &
  ExecuteType

export function usePatchInternalBusiness(onSuccess?: (data: string) => void) {
  const ENDPOINT = "/internal/business/"
  const patchBusiness = useTypedMutation<Business>(
    "patch/internal/business",
    onSuccess,
  )

  const execute = (
    id: string,
    body: Partial<Business>,
    onSuccess: () => void,
  ) => {
    patchBusiness.mutate(
      {
        method: "patch",
        endpoint: ENDPOINT + id + "/",
        body,
      },
      { onSuccess },
    )
  }
  return { ...patchBusiness, execute }
}
