import { useState, useEffect, useMemo } from "react"
import { useQuery } from "react-query"
import { ApplicationTemplate } from "../../types"
import queryString from "query-string"
import * as yup from "yup"
import { getTradeEnabledSchema } from "src/sections/@dashboard/intake_sections/schemas/TradeReferenceSectionSchema"
import { getBankEnabledSchema } from "src/sections/@dashboard/intake_sections/schemas/BankReferenceSectionSchema"
import {  getUserEnabledSchema } from "src/sections/@dashboard/intake_sections/schemas/UserRegistrationSectionSchema"
import { getOwnersEnabledSchema } from "src/sections/@dashboard/intake_sections/schemas/OwnersSectionSchema"
import {  getEnabledSchema as getCompanyDetailsEnabledSchema } from "src/sections/@dashboard/cod/intake_sections/CompanyDetailsSection"
import {  getPersonalGuarantyEnabledSchema } from "src/sections/@dashboard/intake_sections/schemas/PersonalGuarantySectionSchema"
import {  getAdditionalQuestionsSchema } from "src/sections/@dashboard/intake_sections/schemas/CustomQuestionsSectionsSchema"
import { useCashApplication } from "./useCashApplication"

export function useCashApplicationTemplate(
  businessId?: string,
  anonymous = false,
  enabled = true,
  applicationId?: string,
) {
  const { data: application, error: applicationError } =
    useCashApplication(applicationId)

  const [steps, setSteps] = useState<
    ApplicationTemplate["formTemplate"]["pages"]
  >([])

  const [dataSchema, setDataSchema] = useState(yup.object())

  const { data: baseTemplate, ...rest } = useQuery<ApplicationTemplate>(
    queryString.stringifyUrl({
      url: "/configure/cod_template/1",
      query: { business_id: businessId },
    }),
    {
      enabled: businessId !== undefined || enabled,
      retry: (failureCount, error) => {
        if (failureCount < 1) {
          return true
        }
        // do not retry on 404
        return (error as any)?.response?.status !== 404
      },
      meta: { anonymous },
    },
  )

  const template = useMemo(() => {
    if (application) {
      return {
        id: baseTemplate?.id,
        inviteOnly: baseTemplate?.inviteOnly,
        formTemplate: application?.template,
        zohoTemplateId: baseTemplate?.zohoTemplateId,
        seller: businessId,
        customFields: baseTemplate?.customFields,
        logo: baseTemplate?.logo,
        color: baseTemplate?.color,
        terms: baseTemplate?.terms,
      } as ApplicationTemplate
    } else {
      return baseTemplate
    }
  }, [application, businessId, baseTemplate])

  // does three things
  // 1. loads main steps
  // 2. adds additional questions step if custom fields are present
  // 3. patches application to stage 200 if consent is complete and changes active step to complete
  useEffect(() => {
    if (template) {
      let tempSteps = template?.formTemplate?.pages.filter((page) =>
        Boolean(page.enabled),
      )
      // if we have additional questions, add them
      // if (
      //   template?.customFields &&
      //   template?.customFields?.length > 0 &&
      //   template?.customFields?.some(
      //     (field) => field.associatedPage === "Additional Questions",
      //   ) &&
      //   tempSteps.find((tstep) => tstep.label === "Additional Questions") ===
      //     undefined
      // ) {
      //   tempSteps = tempSteps.concat([
      //     {
      //       label: "Additional Questions",
      //       description: "Please answer the following questions to continue.",
      //       enabled: true,
      //       required: false,
      //       config: [],
      //     },
      //   ])
      // }

      tempSteps = tempSteps.concat([
        {
          label: "Credit Card Information",
          description: "Credit Card Data.",
          enabled: true,
          required: true,
          config: [],
        },
      ])

      let tempSchema = yup.object({})
      tempSchema = tempSchema.concat(getUserEnabledSchema(template))
      // tempSchema = tempSchema.concat(getTradeEnabledSchema(template))
      // tempSchema = tempSchema.concat(getBankEnabledSchema(template))
      // tempSchema = tempSchema.concat(getPersonalGuarantyEnabledSchema(template))
      // tempSchema = tempSchema.concat(getEnabledSchema(template))
      tempSchema = tempSchema.concat(getCompanyDetailsEnabledSchema(template))
      // tempSchema = tempSchema.concat(getAdditionalQuestionsSchema(template))
      setDataSchema(tempSchema)
      setSteps(tempSteps)
      return
    }
  }, [template])

  return { data: template, dataSchema, steps, applicationError, ...rest }
}
