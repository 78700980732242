import is from "./is"

// eslint-disable-next-line @typescript-eslint/no-empty-function
const emptyFn = () => {}

const error = is.dev ? console.error.bind(console) : emptyFn
const log = is.dev ? console.log.bind(console) : emptyFn
const info = is.dev ? console.info.bind(console) : emptyFn
const warn = is.dev ? console.warn.bind(console) : emptyFn
const debug = is.dev ? console.debug.bind(console) : emptyFn
const table = is.dev ? console.table.bind(console) : emptyFn
const trace = is.dev ? console.trace.bind(console) : emptyFn

export { error, log, info, warn, debug, table, trace }
