import * as React from "react"

import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  Typography,
  useTheme,
} from "@mui/material"

import { useParams } from "react-router-dom"
import { BrowserView, MobileView } from "react-device-detect"
import { Helmet } from "react-helmet-async"
import { useSnackbar } from "notistack"
import { hotjar } from "react-hotjar"
import CustomDocumentItem from "src/sections/@dashboard/components/file/CustomDocumentItem"
import { useRequestedCustomDocument } from "src/queries/credit/useRequestedCustomDocument"
import { DropzoneDialog } from "mui-file-dropzone"
import { usePatchRequestedCustomDocument } from "src/queries/credit/usePatchRequestedCustomDocument"

export default () => {
  const params = useParams()

  const { applicationId } = params

  const { data, refetch, isLoading } = useRequestedCustomDocument(
    undefined,
    applicationId,
  )

  const { execute } = usePatchRequestedCustomDocument(() => {
    enqueueSnackbar("File successfully uploaded.", { variant: "success" })
    try {
      hotjar.event("document_submitted")
    } catch (e) {
      console.error(e)
    }
    refetch()
    setUploadRequestId("")
  })
  const { enqueueSnackbar } = useSnackbar()

  const [uploadRequestId, setUploadRequestId] = React.useState<string>("")

  const theme = useTheme()

  const uploadDocument = (requestId: string, f: File) => {
    execute(requestId, f)
  }

  if (!data || data.length === 0 || isLoading) return <CircularProgress />

  return (
    <>
      <Helmet>
        <title>Custom Document Request</title>
      </Helmet>
      <MobileView>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              gap: "1rem",
              padding: "10%",
            }}
          >
            <Typography variant="h1">Ah! Hello there!</Typography>
            <Typography variant="h3">
              We love your enthusiasm to use our product on your phone. We are
              working hard to bring you amazing features.
            </Typography>
            <Typography variant="h3">
              However, the application is only fillable using a laptop or a
              desktop computer at the moment.
            </Typography>
          </Box>
        </Backdrop>
      </MobileView>
      <BrowserView>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            padding: "5%",
            maxHeight: "100vh",
            overflow: "auto",
          }}
        >
          <Box
            style={{
              textAlign: "left",
              padding: "32px 10% 32px 10%",
              border: "solid",
              borderWidth: "1px",
              borderRadius: "16px",
              borderColor: theme.palette.primary.main,
            }}
          >
            <Typography
              component="h1"
              variant="h5"
              style={{ marginTop: "8px" }}
            >
              Custom Document Requests
            </Typography>
            <Typography variant="body2" style={{ margin: "8px 0 8px 0" }}>
              Please upload the following documents to proceed with your
              application with <b>{data[0].application?.seller?.name}</b>.
            </Typography>
            <Divider style={{ margin: "16px 0 16px 0" }} />
            {data?.map((item) => (
              <CustomDocumentItem
                key={item.id}
                item={item}
                onDownload={() => {
                  window.open(item.file)
                }}
                onUpload={() => {
                  setUploadRequestId(item.id)
                }}
              />
            ))}
          </Box>
        </Box>
        {uploadRequestId && (
          <DropzoneDialog
            open
            onSave={(newFiles) => {
              uploadDocument(uploadRequestId, newFiles[0])
            }}
            acceptedFiles={[
              "image/jpeg",
              "image/png",
              "image/bmp",
              "application/pdf",
              "application/vnd.ms-excel",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              "application/doc",
              "application/ms-doc",
              "application/msword",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              "application/rtf",
              "text/plain",
              "text/csv",
              "application/vnd.ms-outlook",
              "",
            ]}
            maxFileSize={5000000}
            onClose={() => {
              setUploadRequestId("")
            }}
            filesLimit={1}
            fileObjects={null}
            dropzoneText={
              "Upload the document here. Please make sure the document is clear and legible."
            }
          />
        )}
      </BrowserView>
    </>
  )
}
