import * as React from "react"
import { Formik } from "formik"

import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  Typography,
  useTheme,
  Autocomplete,
  Grid,
  TextField,
  InputAdornment,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Stack,
} from "@mui/material"

import { useParams } from "react-router-dom"
import { TradeReference } from "../types"
import { BrowserView, MobileView } from "react-device-detect"
import { Helmet } from "react-helmet-async"
import { usePatchTradeReference } from "src/queries/credit/usePatchTradeReference"
import { LoadingButton } from "@mui/lab"
import { useSnackbar } from "notistack"
import { useTradeReferences } from "src/queries/credit/useTradeReferences"
import { hotjar } from "react-hotjar"
import { NumericFormat } from "react-number-format"
import { convertCommaSeparatedToNumber } from "src/utils/formatNumber"
import { BUSINESS_PREFERENCES, usePreference } from "src/hooks/use-preference"
import { useAnonymousBusiness } from "src/queries/credit/useAnonymousBusiness"

export default () => {
  const params = useParams()

  const { id } = params

  const { data } = useTradeReferences(undefined, id)
  const { enqueueSnackbar } = useSnackbar()

  const { execute, isLoading } = usePatchTradeReference(() => {
    enqueueSnackbar(
      "Trade reference updated. You can leave this window if all the information is up to date.",
      { variant: "success" },
    )
    hotjar.event("reference_submitted")
  })

  const theme = useTheme()

  const { data: business } = useAnonymousBusiness(
    data && data?.length > 0 ? data[0].sellerId || "" : "",
  )

  const { preference } = usePreference(
    BUSINESS_PREFERENCES.ASK_NET_TERMS_IN_TRADE_REFERENCE,
    business,
  )

  if (!data || data.length === 0) return <CircularProgress />

  return (
    <>
      <Helmet>
        <title>{"Trade Reference"}</title>
      </Helmet>
      <MobileView>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              gap: "1rem",
              padding: "10%",
            }}
          >
            <Typography variant="h1">Ah! Hello there!</Typography>
            <Typography variant="h3">
              We love your enthusiasm to use our product on your phone. We are
              working hard to bring you amazing features.
            </Typography>
            <Typography variant="h3">
              However, the application is only fillable using a laptop or a
              desktop computer at the moment.
            </Typography>
          </Box>
        </Backdrop>
      </MobileView>
      <BrowserView>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            padding: "5%",
            maxHeight: "100vh",
            overflow: "auto",
          }}
        >
          <Box
            style={{
              textAlign: "left",
              padding: "32px 10% 32px 10%",
              border: "solid",
              borderWidth: "1px",
              borderRadius: "16px",
              borderColor: theme.palette.primary.main,
            }}
          >
            <Formik
              initialValues={data[0]}
              onSubmit={(values: Partial<TradeReference>) => {
                if (values.creditLimit != undefined) {
                  values.creditLimit = convertCommaSeparatedToNumber(
                    values.creditLimit,
                  )
                }
                if (values.balance != undefined) {
                  values.balance = convertCommaSeparatedToNumber(values.balance)
                }

                execute(values)
              }}
            >
              {(props) => (
                <Stack
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  <Typography variant="h6">
                    Requested Account Details
                  </Typography>
                  <Divider />
                  <Grid container>
                    <Grid item md={8}>
                      <Typography>Account number</Typography>
                    </Grid>
                    <Grid item md={4}>
                      {data[0].accountNumber}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={8}>
                      <Typography>Account Name</Typography>
                    </Grid>
                    <Grid item md={4}>
                      {data[0].legalBusinessName}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={8}>
                      <Typography>Address</Typography>
                    </Grid>
                    <Grid item md={4}>
                      <Stack
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div>{data[0].buyerAddress?.address}</div>
                        <div>{`${data[0].buyerAddress?.city} ${data[0].buyerAddress?.region}`}</div>
                        <div>{`${data[0].buyerAddress?.postCode} ${data[0].buyerAddress?.country}`}</div>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={8}>
                      <Typography>Contact Information</Typography>
                    </Grid>
                    <Grid item md={4}>
                      <Stack
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div>{data[0].buyerEmail}</div>
                        <div>{data[0].buyerPhoneNumber}</div>
                      </Stack>
                    </Grid>
                  </Grid>

                  <Typography
                    component="h1"
                    variant="h5"
                    style={{ marginTop: "8px" }}
                  >
                    Trade Reference
                  </Typography>
                  <Typography variant="body2" style={{ margin: "8px 0 8px 0" }}>
                    Please provide the following information regarding the
                    account requested
                  </Typography>
                  <Divider />
                  <Grid container>
                    <Grid item md={8}>
                      <Typography>Account open date</Typography>
                    </Grid>
                    <Grid item md={4}>
                      <TextField
                        type="date"
                        value={props.values.accountOpenDate}
                        onChange={(e) => {
                          props.setFieldValue("accountOpenDate", e.target.value)
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={8}>
                      <Typography>Last sold date</Typography>
                    </Grid>
                    <Grid item md={4}>
                      <TextField
                        type="date"
                        value={props.values.lastSoldDate}
                        onChange={(e) => {
                          props.setFieldValue("lastSoldDate", e.target.value)
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={8}>
                      <Typography>Currency</Typography>
                    </Grid>
                    <Grid item md={4}>
                      <FormControl fullWidth>
                        <InputLabel id="currency-repay">Currency</InputLabel>
                        <Select
                          labelId="currency-repay"
                          id="currency-select"
                          value={props.values.currency}
                          label="Currency"
                          onChange={(e) => {
                            props.setFieldValue("currency", e.target.value)
                          }}
                        >
                          <MenuItem value={"USD"}>USD</MenuItem>
                          <MenuItem value={"CAD"}>CAD</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={8}>
                      <Typography>Highest Credit limit</Typography>
                    </Grid>
                    <Grid item md={4}>
                      <NumericFormat
                        customInput={TextField}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        value={props.values.creditLimit}
                        onChange={(e) => {
                          props.setFieldValue("creditLimit", e.target.value)
                        }}
                        allowLeadingZeros
                        thousandSeparator=","
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={8}>
                      <Typography>Current Balance</Typography>
                    </Grid>
                    <Grid item md={4}>
                      <NumericFormat
                        customInput={TextField}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        value={props.values.balance}
                        onChange={(e) => {
                          props.setFieldValue("balance", e.target.value)
                        }}
                        allowLeadingZeros
                        thousandSeparator=","
                      />
                    </Grid>
                  </Grid>
                  {preference !== false && (
                    <Grid container>
                      <Grid item md={8}>
                        <Typography>Terms</Typography>
                      </Grid>
                      <Grid item md={4}>
                        <FormControl fullWidth>
                          <InputLabel id="net-terms">Net Terms</InputLabel>
                          <Select
                            labelId="net-terms"
                            id="net-terms-select"
                            value={props.values.terms}
                            label="Net Terms"
                            onChange={(e) => {
                              props.setFieldValue("terms", e.target.value)
                            }}
                          >
                            <MenuItem value={0}>Cash</MenuItem>
                            <MenuItem value={15}>Net 15</MenuItem>
                            <MenuItem value={30}>Net 30</MenuItem>
                            <MenuItem value={45}>Net 45</MenuItem>
                            <MenuItem value={60}>Net 60</MenuItem>
                            <MenuItem value={75}>Net 75</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}

                  <Grid container>
                    <Grid item md={8}>
                      <Typography>Current Payment Status</Typography>
                    </Grid>
                    <Grid item md={4}>
                      <FormControl fullWidth>
                        <InputLabel id="payment-status">
                          Payment Status
                        </InputLabel>
                        <Select
                          labelId="payment-status"
                          id="payment-status-select"
                          value={props.values.paymentStatus}
                          label="Payment Status"
                          onChange={(e) => {
                            props.setFieldValue("paymentStatus", e.target.value)
                          }}
                        >
                          <MenuItem value={"future"}>Future</MenuItem>
                          <MenuItem value={"current"}>Current</MenuItem>
                          <MenuItem value={"1-30"}>1-30 Days</MenuItem>
                          <MenuItem value={"31-60"}>31-60 Days</MenuItem>
                          <MenuItem value={"61-90"}>61-90 Days</MenuItem>
                          <MenuItem value={"91+"}>91+ Days</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={8}>
                      <Typography>Average Days to Repay</Typography>
                    </Grid>
                    <Grid item md={4}>
                      <FormControl fullWidth>
                        <InputLabel id="avg-days-to-repay">
                          Avg Days to Repay
                        </InputLabel>
                        <Select
                          labelId="avg-days-to-repay"
                          id="avg-days-to-repay-select"
                          value={props.values.avgDaysToRepay}
                          label="Avg Days to Repay"
                          onChange={(e) => {
                            props.setFieldValue(
                              "avgDaysToRepay",
                              e.target.value,
                            )
                          }}
                        >
                          <MenuItem value={"1-15"}>1-15 Days</MenuItem>
                          <MenuItem value={"16-30"}>16-30 Days</MenuItem>
                          <MenuItem value={"31-45"}>31-45 Days</MenuItem>
                          <MenuItem value={"46-60"}>46-60 Days</MenuItem>
                          <MenuItem value={"61+"}>60+ Days</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={4}>
                      <Typography>Select all that apply</Typography>
                    </Grid>
                    <Grid item md={8}>
                      <FormControl fullWidth>
                        <Autocomplete
                          fullWidth
                          multiple
                          sx={{
                            width: "100%",
                          }}
                          options={[
                            "Prompt & Satisfactory",
                            "Satisfactory",
                            "Slow but collectible / Asks for more time",
                            "Slow and unsatisfactory",
                            "Account secured",
                            "Payment in advance",
                            "NSF Checks",
                          ]}
                          defaultValue={
                            (props.values.reviewItems as
                              | string[]
                              | undefined) || []
                          }
                          renderInput={(params) => (
                            <TextField {...params} label="Response" />
                          )}
                          value={
                            (props.values.reviewItems as
                              | string[]
                              | undefined) || []
                          }
                          onChange={(e, value) => {
                            props.setFieldValue("reviewItems", value)
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={12}>
                      <Typography>Comments</Typography>
                      <TextField
                        type="text"
                        fullWidth
                        value={props.values.comments}
                        onChange={(e) => {
                          props.setFieldValue("comments", e.target.value)
                        }}
                      />
                    </Grid>
                  </Grid>
                  <LoadingButton
                    onClick={() => {
                      props.submitForm()
                    }}
                    size="large"
                    loading={isLoading}
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Submit
                  </LoadingButton>
                </Stack>
              )}
            </Formik>
          </Box>
        </Box>
      </BrowserView>
    </>
  )
}
