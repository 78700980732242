import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material"
import { Formik } from "formik"
import { useState } from "react"
import AssignmentSelectionDialog from "src/components/assignment/AssignmentSelectionDialog"
import { useInternalAllUsers } from "src/queries/internal/useInternalAllUsers"
import { Business, User, UserToBusinessRequest } from "src/types"
import * as yup from "yup"
import { enqueueSnackbar } from "notistack"
import { useCreateInternalUserToBusiness } from "src/queries/internal/useCreateInternalUserToBusiness"
import { EditOutlined, SupportAgentOutlined } from "@mui/icons-material"

export default function AddUserToBusiness({
  open,
  onClose,
  business,
  refetch,
}: {
  open: boolean
  onClose: () => void
  business: Business
  refetch: () => void
}) {
  const { data: usersData } = useInternalAllUsers()
  const [showAssignmentDialog, setShowAssignmentDialog] = useState(false)
  const { execute: executeCreateInternalUserToBusiness, isLoading } =
    useCreateInternalUserToBusiness()
  const [currentUser, setCurrentUser] = useState<User | undefined>(undefined)

  const schema = yup.object({
    user: yup.string().required("Required"),
    business: yup.string().required("Required"),
  })

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth={"md"} fullWidth>
        <DialogTitle id="alert-dialog-title">Add User To Business</DialogTitle>
        <DialogContent sx={{ p: 2 }}>
          <Formik
            initialValues={{ business: business.id } as UserToBusinessRequest}
            onSubmit={(values: UserToBusinessRequest) => {
              executeCreateInternalUserToBusiness(values, () => {
                refetch()
                onClose()
                enqueueSnackbar("User updated", {
                  variant: "success",
                })
              })
            }}
            validationSchema={schema}
          >
            {(props) => (
              <>
                <Stack direction={"row"} spacing={1} sx={{ m: 1 }}>
                  {!(props.values.user && currentUser) ? (
                    <Button
                      variant="outlined"
                      startIcon={<SupportAgentOutlined />}
                      onClick={() => setShowAssignmentDialog(true)}
                    >
                      Select User
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      endIcon={<EditOutlined />}
                      onClick={() => setShowAssignmentDialog(true)}
                    >
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "8px",
                          alignItems: "center",
                        }}
                      >
                        <SupportAgentOutlined />
                        {currentUser.firstName
                          ? `${currentUser.firstName} ${currentUser.lastName}`
                          : currentUser.email}
                      </Box>
                    </Button>
                  )}
                </Stack>
                {usersData && (
                  <AssignmentSelectionDialog
                    open={!!showAssignmentDialog}
                    onClose={() => {
                      setShowAssignmentDialog(false)
                    }}
                    onSelect={(user) => {
                      setShowAssignmentDialog(false)
                      props.setFieldValue("user", user.id)
                      setCurrentUser(user)
                    }}
                    contacts={usersData?.sort((a, b) => {
                      if (!a.firstName) {
                        return 1
                      }
                      if (!b.firstName) {
                        return -1
                      }
                      return a.firstName < b.firstName ? -1 : 1
                    })}
                  />
                )}
                <Stack spacing={1} sx={{ m: 1 }}>
                  <Button
                    variant="contained"
                    size="small"
                    fullWidth
                    disabled={isLoading}
                    onClick={() => {
                      props.handleSubmit()
                    }}
                  >
                    Submit
                  </Button>
                </Stack>
              </>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
