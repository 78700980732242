import { useState, useCallback, ChangeEvent } from "react"
// @mui
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Avatar from "@mui/material/Avatar"
import Dialog from "@mui/material/Dialog"
import ListItem from "@mui/material/ListItem"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import DialogTitle from "@mui/material/DialogTitle"
import ListItemText from "@mui/material/ListItemText"
import DialogContent from "@mui/material/DialogContent"
import InputAdornment from "@mui/material/InputAdornment"
import ListItemAvatar from "@mui/material/ListItemAvatar"
// components
import Scrollbar from "../scrollbar"
import SearchNotFound from "../search-not-found"
import { User } from "../../types"
import { Icon } from "@iconify/react"

import { stringAvatar } from "../../utils/avatar"
import { DialogActions } from "@mui/material"

// ----------------------------------------------------------------------

const ITEM_HEIGHT = 64

export default function AssignmentSelectionDialog({
  contacts,
  open,
  onClose,
  onSelect,
}: {
  contacts: Array<User>
  open: boolean
  onClose: () => void
  onSelect: (contact: User) => void
}) {
  const [searchContact, setSearchContact] = useState("")

  const handleSearchContacts = useCallback((event: ChangeEvent) => {
    setSearchContact((event.target as HTMLInputElement).value)
  }, [])

  const dataFiltered = applyFilter(contacts, searchContact)

  const notFound = !dataFiltered.length && !!searchContact

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle sx={{ pb: 0 }}>
        Users <Typography component="span">({contacts.length})</Typography>
      </DialogTitle>

      <Box sx={{ px: 3, py: 2.5 }}>
        <TextField
          fullWidth
          value={searchContact}
          onChange={handleSearchContacts}
          placeholder="Search..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon icon="eva:search-fill" />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <DialogContent sx={{ p: 0 }}>
        {notFound ? (
          <SearchNotFound query={searchContact} sx={{ mt: 3, mb: 10 }} />
        ) : (
          <Scrollbar
            sx={{
              px: 2.5,
              height: ITEM_HEIGHT * 6,
            }}
          >
            {dataFiltered.map((contact) => {
              return (
                <ListItem
                  key={contact.id}
                  disableGutters
                  secondaryAction={
                    <Button
                      size="small"
                      color={"inherit"}
                      startIcon={<Icon width={16} icon={"mingcute:add-line"} />}
                      onClick={() => {
                        onSelect(contact)
                      }}
                    >
                      Assign
                    </Button>
                  }
                  sx={{ height: ITEM_HEIGHT }}
                >
                  <ListItemAvatar>
                    <Avatar
                      {...stringAvatar(contact.firstName || contact.email)}
                    />
                  </ListItemAvatar>

                  <ListItemText
                    primaryTypographyProps={{
                      typography: "subtitle2",
                      sx: { mb: 0.25 },
                    }}
                    secondaryTypographyProps={{ typography: "caption" }}
                    primary={`${contact.firstName} ${contact.lastName}`}
                    secondary={contact.email}
                  />
                </ListItem>
              )
            })}
          </Scrollbar>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

// ----------------------------------------------------------------------

function applyFilter(inputData: Array<User>, query: string) {
  if (query) {
    inputData = inputData.filter(
      (contact: User) =>
        contact.firstName.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        contact.lastName.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        contact.email.toLowerCase().indexOf(query.toLowerCase()) !== -1,
    )
  }

  return inputData
}
