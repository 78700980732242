import { PageviewOutlined, EmailOutlined } from "@mui/icons-material"
import {
  Typography,
  Divider,
  List,
  ListItem,
  Box,
  ListItemText,
} from "@mui/material"
import { useAnonymousBusiness } from "src/queries/credit/useAnonymousBusiness"
import { useLocation } from "react-router-dom"

export default () => {
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const businessId = queryParams.get("business_id") || ""
  const { data: sellerData } = useAnonymousBusiness(businessId)

  return (
    <>
      <Typography component="h1" variant="h5" style={{ marginTop: "8px" }}>
        🎉 Success! 🎉
      </Typography>
      <Typography variant="body2" style={{ margin: "8px 0 8px 0" }}>
        You have successfully completed your application.
      </Typography>
      <Divider style={{ margin: "16px 0 16px 0" }} />

      <Typography>Here is what to expect:</Typography>
      <List>
        <ListItem>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              gap: "1rem",
            }}
          >
            <PageviewOutlined />
            <ListItemText
              style={{ flex: "11 11 auto" }}
              primary={`${
                sellerData?.name || "We"
              } will review your application and contact you.`}
            />
          </Box>
        </ListItem>
        <ListItem>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              gap: "1rem",
            }}
          >
            <EmailOutlined />
            <ListItemText
              style={{ flex: "11 11 auto" }}
              primary={
                "You will receive an email from us if the status of your application changes."
              }
            />
          </Box>
        </ListItem>
      </List>
      <div id="hotjar-survey-1" />
    </>
  )
}
