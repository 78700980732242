import { useTypedMutation } from "../useTypedMutation"

export function usePostUserToBusiness(onSuccess?: (data: string) => void) {
  const ENDPOINT = "/associations/"
  const post = useTypedMutation("post/associations", onSuccess)

  const execute = (businessId: string) => {
    post.mutate({
      method: "post",
      endpoint: ENDPOINT,
      body: {
        businessId,
      },
    })
  }
  return { ...post, execute }
}
