import { QueryClient } from "react-query"
import { useTypedMutation } from "../useTypedMutation"
import { ListInternalApplication } from "src/types"

export function useDeleteInternalCashApplication(onSuccess?: () => void) {
  const ENDPOINT = "/internal/cashapplications/"
  const d = useTypedMutation<ListInternalApplication>(
    "/internal/cashapplications/",
    onSuccess,
  )

  const execute = (body: ListInternalApplication) => {
    d.mutate(
      {
        method: "delete",
        endpoint: ENDPOINT + body.id + "/",
        body: body,
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
        },
      },
    )
  }
  return { ...d, execute }
}
