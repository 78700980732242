// @mui
import { Button, IconButton, Stack, Typography } from "@mui/material"
// components
import { ArrowBack, Save } from "@mui/icons-material"
import { useNavigate } from "react-router-dom"
import { LoadingButton } from "@mui/lab"

// ----------------------------------------------------------------------

export default ({
  backLink,
  enableSave,
  onTogglePreview,
  inPreviewMode,
  onSave,
  isLoading,
}: {
  backLink: any
  enableSave: boolean
  isLoading: boolean
  inPreviewMode?: boolean
  onTogglePreview: (enabled: boolean) => void
  onSave: () => void
}) => {
  const navigate = useNavigate()

  return (
    <>
      <Stack
        spacing={3}
        direction={{ xs: "column", md: "row" }}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      >
        <Stack spacing={1} direction="row" alignItems="flex-start">
          {!inPreviewMode && (
            <>
              <IconButton
                onClick={() => {
                  navigate(backLink)
                }}
              >
                <ArrowBack />
              </IconButton>

              <Stack spacing={0.5}>
                <Stack spacing={1} direction="row" alignItems="center">
                  <Typography variant="h4">
                    Credit Application Settings
                  </Typography>
                  {/* <Label
                variant="soft"
                color={
                  // (status === "completed" && "success") ||
                  // (status === "pending" && "warning") ||
                  // (status === "cancelled" && "error") ||
                  "default"
                }
              >
                Status
              </Label> */}
                </Stack>

                <Typography variant="body2" sx={{ color: "text.disabled" }}>
                  {/* {fDateTime(createdAt)} */}
                </Typography>
              </Stack>
            </>
          )}
        </Stack>

        <Stack
          flexGrow={1}
          spacing={1.5}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          {inPreviewMode && (
            <LoadingButton
              color="primary"
              variant="outlined"
              startIcon={<ArrowBack />}
              loading={isLoading}
              // onClick={popover.onOpen}
              sx={{ textTransform: "capitalize" }}
              onClick={() => onTogglePreview(false)}
            >
              Back to Configuration
            </LoadingButton>
          )}
          {/* {!inPreviewMode && (
            <Button
              color="primary"
              variant="outlined"
              endIcon={<Preview />}
              // onClick={popover.onOpen}
              sx={{ textTransform: "capitalize" }}
              onClick={() => onTogglePreview(true)}
            >
              Preview
            </Button>
          )} */}

          {!inPreviewMode && (
            <Button
              color="primary"
              variant="contained"
              endIcon={<Save />}
              disabled={!enableSave}
              sx={{ textTransform: "capitalize" }}
              onClick={onSave}
            >
              Save Changes
            </Button>
          )}

          {/* <Button
            color="inherit"
            variant="outlined"
            startIcon={<Iconify icon="solar:printer-minimalistic-bold" />}
          >
            Print
          </Button>

          <Button
            color="inherit"
            variant="contained"
            startIcon={<Iconify icon="solar:pen-bold" />}
          >
            Edit
          </Button> */}
        </Stack>
      </Stack>
      {/* 
      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="top-right"
        sx={{ width: 140 }}
      >
        {statusOptions.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === status}
            onClick={() => {
              popover.onClose()
              onChangeStatus(option.value)
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </CustomPopover> */}
    </>
  )
}
