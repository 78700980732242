import { DangerousOutlined, TravelExploreOutlined } from "@mui/icons-material"
import { Box, Tooltip, Typography } from "@mui/material"
import { useValidateDomain } from "src/queries/vendors/useValidateDomain"

export const EmailWithValidation = ({ email }: { email: string }) => {
  const { data: domainData, isFree } = useValidateDomain(
    email?.includes("@") && email?.split("@").length > 1
      ? email?.split("@")[1]
      : "",
  )

  return (
    <Box style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
      <Typography variant="body2" align="left">
        {email}
      </Typography>
      {isFree && (
        <Tooltip
          title={
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <Typography>This is not a business email.</Typography>
            </Box>
          }
          placement="bottom"
          arrow
        >
          <DangerousOutlined color="error" />
        </Tooltip>
      )}
      {domainData && (
        <Tooltip
          title={
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <Typography>Domain Verification</Typography>
              <Typography variant="caption">
                Domain Is Registered: {domainData?.domainRegistered}
              </Typography>
              <Typography variant="caption">
                Domain Creation Date: {domainData?.domainCreationDate}
              </Typography>
              <Typography variant="caption">
                Domain Owner: {domainData?.ownerOrganization}
              </Typography>
              <Typography variant="caption">
                Owner Country: {domainData?.ownerCountry}
              </Typography>
              <Typography variant="caption">
                Owner State/Province: {domainData?.ownerStateProvince}
              </Typography>
            </Box>
          }
          placement="bottom"
          arrow
        >
          <TravelExploreOutlined color="primary" />
        </Tooltip>
      )}
    </Box>
  )
}
