import { useState, useRef } from "react"
// @mui
import Stack from "@mui/material/Stack"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Unstable_Grid2"

// components
import { useParams } from "../../../../routes/hook"
//
import CreditApplicationDetailsInfo from "../credit-application-details-info"
import CreditApplicationTabs from "../credit-application-tabs"
import CreditApplicationDetailsToolbar from "../CreditApplicationDetailsToolbar"
import { useCreditApplication } from "../../../../queries/credit/useCreditApplication"
import { useApplicationTimeline } from "src/queries/credit/useApplicationTimeline"

import CreditApplicationDetailsExtra from "../credit-application-details-extra"
import CreditApplicationBaseInfo from "../credit-application-base-info"
import CreditApplicationTimeline from "../credit-application-timeline"
import ApplicationComments from "../components/ApplicationComments"
import OpenCorporateComapniesData from "../components/OpenCorporateCompaniesData"
// ----------------------------------------------------------------------

export default function CreditApplicationDetailsView() {
  const params = useParams()

  const { id } = params

  const { data, refetch } = useCreditApplication(id)

  const { data: timelineData, refetch: refetchApplicationTimeline } =
    useApplicationTimeline(data?.id)

  const [tab, setTab] = useState(0)
  const [openCompanyDataDialogs, setOpenCompanyDataDialogs] = useState(false)

  let refreshCommentTrigger = useRef(0)

  const setRefreshCommentTrigger = (value) => {
    refreshCommentTrigger.current = value
  }

  if (!data) {
    return <></>
  }

  return (
    <Container maxWidth={false}>
      <CreditApplicationDetailsToolbar
        backLink={"/applications"}
        application={data}
        refreshCommentTrigger={refreshCommentTrigger}
        setRefreshCommentTrigger={setRefreshCommentTrigger}
        refetchTimeline={() => {
          refetch()
          refetchApplicationTimeline()
        }}
      />
      {!data.seller.name.includes("Quecan") && (
        <>
          <CreditApplicationTabs application={data} tab={tab} setTab={setTab} />

          <OpenCorporateComapniesData
            openCompanyDataDialogs={openCompanyDataDialogs}
          />
        </>
      )}
      <Grid container spacing={3}>
        <Grid xs={12} md={8}>
          {!data.seller.name.includes("Quecan") && (
            <CreditApplicationBaseInfo
              applicationId={data.id}
              setOpenCompanyDataDialogs={setOpenCompanyDataDialogs}
              tab={tab}
            />
          )}

          {data.seller.name.includes("Quecan") && (
            <CreditApplicationDetailsInfo application={data} />
          )}
        </Grid>
        <Grid xs={12} md={4}>
          <Stack spacing={3} direction={{ xs: "column-reverse", md: "column" }}>
            {!data.seller.name.includes("Quecan") && (
              <CreditApplicationTimeline timelineData={timelineData} />
            )}

            {["Quecan"].includes(data.seller.name) && (
              <CreditApplicationDetailsExtra />
            )}

            <ApplicationComments
              application={data}
              refreshCommentTrigger={refreshCommentTrigger}
            />
          </Stack>
        </Grid>
      </Grid>
    </Container>
  )
}
