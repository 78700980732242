import { QueryClient } from "react-query"
import { useTypedMutation } from "../useTypedMutation"
import { PersonalGuaranty } from "src/types"

export function useDeletePersonalGuarantor(onSuccess?: () => void) {
  const ENDPOINT = "/application/"
  const d = useTypedMutation<Pick<PersonalGuaranty, "id">>(
    "/application",
    onSuccess,
  )

  const execute = (applicationId: string, id: string) => {
    d.mutate(
      {
        method: "delete",
        endpoint: ENDPOINT + applicationId + "/personal_guaranty/" + id,
        body: {},
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
        },
      },
    )
  }
  return { ...d, execute }
}
