import * as yup from "yup"
import {
  addConditionalValidationstoCustomSchema,
  getAdditionalQuestionSchemaForPage,
} from "../../utils"
import { Application, ApplicationTemplate, CreditApplication } from "src/types"
import { OWNER_CONFIGS, PAGE_LABEL_OWNERS } from "../constants"
import { PROVINCES, STATES } from "../../components/Address"
import {
  isPageEnabled,
  isPageRequired,
  isSubSectionRequired,
} from "../template_helpers"

export const isOwnersEnabled = (template: ApplicationTemplate | undefined) => {
  return template?.formTemplate.pages
    .find((step) => step.label === PAGE_LABEL_OWNERS)
    ?.config?.find((config) => config.label === "Owners")?.value
}

export const isControllerEnabled = (
  template: ApplicationTemplate | undefined,
) => {
  return template?.formTemplate.pages
    ?.find((step) => step.label === PAGE_LABEL_OWNERS)
    ?.config?.find((config) =>
      config.label.includes(OWNER_CONFIGS.CONTROLLER_BASE),
    )?.value
}

export const isContactsEnabled = (
  template: ApplicationTemplate | undefined,
) => {
  return template?.formTemplate.pages
    .find((step) => step.label === PAGE_LABEL_OWNERS)
    ?.config?.find((config) => config.label === "AP Contacts")?.value
}

export const isAddressEnabled = (template: ApplicationTemplate | undefined) => {
  return template?.formTemplate.pages
    .find((step) => step.label === PAGE_LABEL_OWNERS)
    ?.config?.find((config) => config.label === "Address")?.value
}

export const isSsnEnabled = (template: ApplicationTemplate | undefined) => {
  return template?.formTemplate.pages
    .find((step) => step.label === PAGE_LABEL_OWNERS)
    ?.config?.find((config) => config.label === "SSN/SIN")?.value
}

export const getNumberOfContacts = (
  template: ApplicationTemplate | undefined,
) => {
  const res = template?.formTemplate.pages
    .find((step) => step.label === PAGE_LABEL_OWNERS)
    ?.config?.find((config) => config.label === "Number of AP Contacts")?.value

  return res ? Number(res) : 1
}

export const isSsnRequired = (
  template: ApplicationTemplate | undefined,
  data: Application["data"],
) => {
  if (!template) return false
  const req = template?.formTemplate.pages
    .filter(
      (page) =>
        isPageEnabled(page.label, template, data) &&
        isPageRequired(page.label, template, data),
    )
    ?.find((step) => step.label === PAGE_LABEL_OWNERS)
    ?.config?.find((config) => config.label === "SSN/SIN")?.required

  if (req === undefined || req === null) return true // default required
  return req
}

export const isLicenseRequired = (
  template: ApplicationTemplate | undefined,
  data: Application["data"],
) => {
  if (!template) return false
  const req = template?.formTemplate.pages
    .filter(
      (page) =>
        isPageEnabled(page.label, template, data) &&
        isPageRequired(page.label, template, data),
    )
    ?.find((step) => step.label === PAGE_LABEL_OWNERS)
    ?.config?.find((config) => config.label === "Driver License")?.required

  if (req === undefined || req === null) return true // default required
  return req
}

export const isDobRequired = (
  template: ApplicationTemplate | undefined,
  data: Application["data"],
) => {
  if (!template) return false
  const req = template?.formTemplate.pages
    .filter(
      (page) =>
        isPageEnabled(page.label, template, data) &&
        isPageRequired(page.label, template, data),
    )
    ?.find((step) => step.label === PAGE_LABEL_OWNERS)
    ?.config?.find((config) => config.label === "Date of Birth")?.required

  if (req === undefined || req === null) return true // default required
  return req
}

export const isLicenseEnabled = (template: ApplicationTemplate | undefined) => {
  return template?.formTemplate.pages
    .find((step) => step.label === PAGE_LABEL_OWNERS)
    ?.config?.find((config) => config.label === "Driver License")?.value
}

export const isDobEnabled = (template: ApplicationTemplate | undefined) => {
  return template?.formTemplate.pages
    .find((step) => step.label === PAGE_LABEL_OWNERS)
    ?.config?.find((config) => config.label === "Date of Birth")?.value
}

export const isPhoneNumberEnabled = (
  template: ApplicationTemplate | undefined,
) => {
  return template?.formTemplate.pages
    .find((step) => step.label === PAGE_LABEL_OWNERS)
    ?.config?.find((config) => config.label === "Owner Phone Number")?.value
}

export const isOwnersTitleEnabled = (
  template: ApplicationTemplate | undefined,
) => {
  return template?.formTemplate.pages
    .find((step) => step.label === PAGE_LABEL_OWNERS)
    ?.config?.find((config) => config.label === "Owners Title")?.value
}

export const isOwnersTitleRequired = (
  template: ApplicationTemplate | undefined,
  data: Application["data"],
) => {
  if (!template) return false
  const req = template?.formTemplate.pages
    .filter(
      (page) =>
        isPageEnabled(page.label, template, data) &&
        isPageRequired(page.label, template, data),
    )
    ?.find((step) => step.label === PAGE_LABEL_OWNERS)
    ?.config?.find((config) => config.label === "Owners Title")?.required

  if (req === undefined || req === null) return true // default required
  return req
}

export const isOwnersPercentageRequired = (
  template: ApplicationTemplate | undefined,
  data: Application["data"],
) => {
  if (!template) return false
  const req = template?.formTemplate.pages
    .filter(
      (page) =>
        isPageEnabled(page.label, template, data) &&
        isPageRequired(page.label, template, data),
    )
    ?.find((step) => step.label === PAGE_LABEL_OWNERS)
    ?.config?.find((config) => config.label === "Owners Percentage")?.required

  if (req === undefined || req === null) return true // default required
  return req
}

export const isOwnersPercentageEnabled = (
  template: ApplicationTemplate | undefined,
) => {
  return template?.formTemplate.pages
    .find((step) => step.label === PAGE_LABEL_OWNERS)
    ?.config?.find((config) => config.label === "Owners Percentage")?.value
}

export const getOwnersEnabledSchema = (
  template: ApplicationTemplate,
  data: Application["data"] = {},
): yup.AnyObjectSchema => {
  let enabledSchema = yup.object({})
  if (
    isPageEnabled(PAGE_LABEL_OWNERS, template, data) &&
    isPageRequired(PAGE_LABEL_OWNERS, template, data)
  ) {
    template.formTemplate.pages
      .find((step) => step.label === PAGE_LABEL_OWNERS)
      ?.config?.filter((config) => !!config.value)
      ?.filter((config) => config.required !== false)
      .forEach(
        (config) =>
          (enabledSchema = enabledSchema.concat(
            getAdditionalSchema(config.label),
          )),
      )

    if (isControllerEnabled(template)) {
      Array.from([
        OWNER_CONFIGS.CONTROLLER_NAME,
        OWNER_CONFIGS.CONTROLLER_EMAIL,
        OWNER_CONFIGS.CONTROLLER_PHONE,
        OWNER_CONFIGS.CONTROLLER_ROLE,
      ]).forEach((c) => {
        if (isSubSectionRequired(template, PAGE_LABEL_OWNERS, c, data)) {
          enabledSchema = enabledSchema.concat(getAdditionalSchema(c))
        }
      })
    }

    if (getNumberOfContacts(template) > 1) {
      Array.from([
        OWNER_CONFIGS.AP_2_NAME,
        OWNER_CONFIGS.AP_2_EMAIL,
        OWNER_CONFIGS.AP_2_ROLE,
        OWNER_CONFIGS.AP_2_PHONE,
      ]).forEach((c) => {
        if (isSubSectionRequired(template, PAGE_LABEL_OWNERS, c, data)) {
          enabledSchema = enabledSchema.concat(getAdditionalSchema(c))
        }
      })
    }

    let customSchema = getAdditionalQuestionSchemaForPage(
      template,
      PAGE_LABEL_OWNERS,
    )

    if (data) {
      customSchema = addConditionalValidationstoCustomSchema(
        template,
        customSchema,
        data,
        PAGE_LABEL_OWNERS,
      )
    }

    enabledSchema = enabledSchema.concat(
      yup.object({
        customFields: customSchema,
      }),
    )
  }

  return enabledSchema
}

const getAdditionalSchema = (label: string) => {
  switch (label) {
    case "Owners":
      return yup.object({
        ownerName1: yup.string().required(),
        ownerEmail1: yup.string().email().required(),
      })
    case "Driver License":
      return yup.object({
        ownerDriverLicense1: yup.string().required(),
      })
    case "SSN/SIN":
      return yup.object({ ownerSsn1: yup.string().required() })
    case "Date of Birth":
      return yup.object({ ownerDob1: yup.string().required() })
    case "Owners Title":
      return yup.object({ ownerTitle1: yup.string().required() })
    case "Owners Percentage":
      return yup.object({
        ownerPercentage1: yup
          .number()
          .required("Field is required")
          .min(0, "Percentage must be between 0 and 100")
          .max(100, "Percentage must be between 0 and 100"),
      })
    case "Address":
      return yup.object({
        ownerAddress1: yup.string().required(),
        ownerCity1: yup.string().required("City is required"),
        ownerCountry1: yup.string().required("Country is required"),
        ownerRegion1: yup
          .string()
          .required()
          .oneOf([...STATES, ...PROVINCES]),
        ownerPostCode1: yup.string().required("Post code is required"),
      })
    case "AP Contacts":
      return yup.object({
        apName: yup.string().required(),
        apPosition: yup.string().required(),
        apEmail: yup.string().email().required(),
        apPhoneNumber: yup
          .string()
          .required()
          .matches(
            /\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/,
            "Phone number is not valid",
          ),
      })
    case OWNER_CONFIGS.CONTROLLER_NAME:
      return yup.object({
        controllerName: yup.string().required(),
      })
    case OWNER_CONFIGS.CONTROLLER_ROLE:
      return yup.object({
        controllerPosition: yup.string().required(),
      })
    case OWNER_CONFIGS.CONTROLLER_EMAIL:
      return yup.object({
        controllerEmail: yup.string().email().required(),
      })
    case OWNER_CONFIGS.CONTROLLER_PHONE:
      return yup.object({
        controllerPhoneNumber: yup
          .string()
          .required()
          .matches(
            /\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/,
            "Phone number is not valid",
          ),
      })
    case OWNER_CONFIGS.AP_2_NAME:
      return yup.object({
        apName2: yup.string().required(),
      })
    case OWNER_CONFIGS.AP_2_ROLE:
      return yup.object({
        apPosition2: yup.string().required(),
      })
    case OWNER_CONFIGS.AP_2_EMAIL:
      return yup.object({
        apEmail2: yup.string().email().required(),
      })
    case OWNER_CONFIGS.AP_2_PHONE:
      return yup.object({
        apPhoneNumber2: yup
          .string()
          .required()
          .matches(
            /\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/,
            "Phone number is not valid",
          ),
      })
    default:
      return yup.object({})
  }
}
