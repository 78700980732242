import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { CreditApplication, Group, User } from "src/types"
import { PersonOutline } from "@mui/icons-material"
import { NumericFormat } from "react-number-format"
import { confirm } from "src/components/confirm"
import { convertCommaSeparatedToNumber } from "src/utils/formatNumber"
import { useFormik } from "formik"
import { useApplicationTemplate } from "src/queries/credit/useApplicationTemplate"
import { NET_TERMS } from "src/statics"
import { LoadingButton } from "@mui/lab"
import { useUsers } from "src/queries/base/useUsers"
import { useState } from "react"
import AssignmentSelectionDialog from "src/components/assignment/AssignmentSelectionDialog"
import * as Yup from "yup"
import { usePostCreditApplicationApproval } from "src/queries/credit/usePostRequestCreditApplicationApproval"
import { enqueueSnackbar } from "notistack"
import { stringAvatar } from "src/utils/avatar"

export default ({
  application,
  onClose,
  open,
  refetch,
}: {
  application: CreditApplication
  onClose: () => void
  open: boolean
  refetch: () => void
}) => {
  const { data: template } = useApplicationTemplate(
    application.seller?.id || "",
    false,
    true,
  )
  const applicationId = application.id || ""
  const { data: users } = useUsers()
  const { execute, isLoading } = usePostCreditApplicationApproval(() => {
    refetch()
    enqueueSnackbar("Approval Request sent", { variant: "success" })
    onClose()
  })
  const [showAssignmentDialog, setShowAssignmentDialog] = useState(false)
  const [approver, setApprover] = useState<User | undefined>(undefined)
  const terms = template?.approvalTerms || NET_TERMS
  const schema = Yup.object().shape({
    approver: Yup.string().required("Recipient is required"),
  })
  const formik = useFormik({
    initialValues: {
      data: {
        customerId: application.data.customerId,
        creditLimit: application.data.creditLimit,
        netTerms: terms.map((t) => t.value).includes(application.data.netTerms)
          ? application.data.netTerms
          : "other",
        otherTerms: !terms
          .map((t) => t.value)
          .includes(application.data.netTerms)
          ? application.data.netTerms
          : undefined,
        currency:
          application.data.currency || application.seller?.currency || "USD",
      },
      notes: "",
      approver: "",
    },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: (values) => {
      confirm(
        "Are you sure you want to request for an approval of this credit application?",
        {
          okLabel: "Submit",
        },
      )
        .then((result) => {
          if (result) {
            const valuesCopy = { ...values }
            if (valuesCopy.data) {
              valuesCopy.data.creditLimit = convertCommaSeparatedToNumber(
                valuesCopy.data.creditLimit,
              )
            }

            if (
              valuesCopy.data &&
              valuesCopy.data.netTerms === "other" &&
              valuesCopy.data.otherTerms
            ) {
              valuesCopy.data.netTerms = valuesCopy.data.otherTerms
            }
            execute(applicationId, valuesCopy, () => {
              refetch()
              onClose()
            })
          }
          return
        })
        .catch(() => {
          // Do nothing
        })
    },
  })
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={"md"}>
      <DialogTitle>Request Application Approval</DialogTitle>
      <Divider style={{}} />
      <DialogContent>
        <Stack
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
          sx={{ p: 2 }}
        >
          <Grid container>
            <Grid item md={4}>
              <Typography>Approved Credit Limit</Typography>
            </Grid>
            <Grid item md={8}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                }}
              >
                <NumericFormat
                  customInput={TextField}
                  fullWidth
                  value={formik.values.data?.creditLimit || ""}
                  onChange={(e) => {
                    formik.setFieldValue("data.creditLimit", e.target.value)
                  }}
                  allowLeadingZeros
                  thousandSeparator=","
                />
                <Select
                  labelId="currency-select"
                  id="currency-select"
                  value={formik.values.data?.currency}
                  onChange={(e) => {
                    formik.setFieldValue("data.currency", e.target.value)
                  }}
                >
                  <MenuItem value={"USD"}>USD</MenuItem>
                  <MenuItem value={"CAD"}>CAD</MenuItem>
                </Select>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={4}>
              <Typography>Net Terms</Typography>
            </Grid>
            <Grid item md={8}>
              <FormControl fullWidth>
                <InputLabel id="net-terms">Net Terms</InputLabel>
                <Select
                  labelId="net-terms"
                  id="net-terms-select"
                  value={formik.values.data?.netTerms}
                  label="Net Terms"
                  onChange={(e) => {
                    formik.setFieldValue("data.netTerms", e.target.value)
                    if (e.target.value !== "other") {
                      formik.setFieldValue("data.otherTerms", undefined)
                    }
                  }}
                >
                  <MenuItem value={undefined} disabled>
                    Select
                  </MenuItem>
                  {terms.map((term) => {
                    return (
                      <MenuItem key={term.value} value={term.value}>
                        {term.label}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {formik.values.data?.netTerms === "other" && (
            <Grid container>
              <Grid item md={4}>
                <Typography>Please specify the terms</Typography>
              </Grid>
              <Grid item md={8}>
                <TextField
                  fullWidth
                  value={formik.values.data?.otherTerms || ""}
                  onChange={(e) => {
                    formik.setFieldValue("data.otherTerms", e.target.value)
                  }}
                />
              </Grid>
            </Grid>
          )}
          <Grid container>
            <Grid item md={4}>
              <Typography>Customer ID</Typography>
            </Grid>
            <Grid item md={8}>
              <TextField
                fullWidth
                value={formik.values.data?.customerId || ""}
                onChange={(e) => {
                  formik.setFieldValue("data.customerId", e.target.value)
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={4}>
              Request approval from
            </Grid>
            <Grid item md={8}>
              <Button
                variant="outlined"
                startIcon={<PersonOutline />}
                onClick={() => setShowAssignmentDialog(true)}
              >
                {!approver && "Select Approver"}
                {approver && (
                  <>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "8px",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        {...stringAvatar(
                          `${
                            approver.firstName
                              ? approver.firstName
                              : approver.email
                          }`,
                          {
                            width: 24,
                            height: 24,
                          },
                        )}
                      />
                      {approver.firstName
                        ? `${approver.firstName}${
                            approver.lastName ? " " + approver.lastName[0] : ""
                          }`
                        : approver.email}
                    </Box>
                  </>
                )}
              </Button>
              {Boolean(formik.errors.approver && formik.touched.approver) && (
                <>
                  <Typography variant="body2" sx={{ color: "#FF0000" }}>
                    Approver selection is required
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={4}>
              Notes to share with Approver
            </Grid>
            <Grid item md={8}>
              <TextField
                fullWidth
                variant="outlined"
                label="Notes"
                multiline={true}
                maxRows={8}
                value={formik.values.notes}
                onChange={(e) => {
                  formik.setFieldValue("notes", e.target.value)
                }}
              />
            </Grid>
          </Grid>
        </Stack>
        {users && showAssignmentDialog && (
          <AssignmentSelectionDialog
            open={showAssignmentDialog}
            onClose={() => {
              setShowAssignmentDialog(false)
            }}
            onSelect={(user) => {
              setShowAssignmentDialog(false)
              formik.setFieldValue("approver", user.id)
              setApprover(user)
            }}
            contacts={users.filter((user) => {
              if (user.groups && user.groups.includes(Group.CreditManager)) {
                return true
              } else {
                return false
              }
            })}
          />
        )}
      </DialogContent>
      <Divider style={{}} />
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <LoadingButton
          type="submit"
          variant="contained"
          onClick={() => {
            formik.submitForm()
          }}
          loading={isLoading}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
