import { useQuery } from "react-query"

/**
 * Is not enabled by default. use `refetch` to trigger on demand
 */
export function useContractorSearch(name: string) {
  return useQuery<any>("/search_contractor?license_number=" + name, {
    enabled: false,
    retry: (failureCount, error) => {
      if (failureCount < 1) {
        return true
      }
      // do not retry on 404
      return (error as any)?.response?.status !== 404
    },
  })
}
