import { Box, Card, CardHeader, Stack, Typography } from "@mui/material"
import { OpenCorporateGetCompaniesEventsInterface } from "src/types"

interface EventsRowProps {
  event: OpenCorporateGetCompaniesEventsInterface | undefined
}

const EventsRow = ({ event }: EventsRowProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "200%",
      }}
    >
      <Stack sx={{ marginRight: 2 }}>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ marginRight: 1, marginLeft: 2 }}
        >
          Created At
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ marginRight: 1, marginLeft: 2 }}
        >
          Type
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ marginRight: 1, marginLeft: 2 }}
        >
          Date
        </Typography>
      </Stack>
      <Stack sx={{ marginLeft: 2, width: "auto", flexGrow: 1 }}>
        <Typography variant="body2">{event?.createdAt ?? ""}</Typography>
        <Typography variant="body2">{event?.type ?? ""}</Typography>
        <Typography variant="body2">{event?.date ?? ""}</Typography>
      </Stack>
    </Box>
  )
}

interface EventsTableRowProps {
  event: OpenCorporateGetCompaniesEventsInterface
}

const EventsTableRow = ({ event }: EventsTableRowProps) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: "8px",
      }}
    >
      <EventsRow event={event} />
    </div>
  )
}

interface EventsTableProps {
  events: OpenCorporateGetCompaniesEventsInterface[] | undefined
}

const EventsTable = ({ events }: EventsTableProps) => {
  return (
    <>
      <Card sx={{ width: "100%" }}>
        <CardHeader
          title={
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "8px",
              }}
            >
              <Typography variant="h6">Company Events</Typography>
            </Box>
          }
        />
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            p: 2,
            gap: 2,
          }}
        >
          {events?.map((event, index) => (
            <Box
              key={index}
              sx={{
                flex: "1 1 calc(50% - 16px)",
                marginBottom: "10px",
              }}
            >
              <EventsTableRow event={event} />
            </Box>
          ))}
        </Box>
      </Card>
    </>
  )
}

export default EventsTable
