import { LoadingButton } from "@mui/lab"
import {
  Autocomplete,
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  InputLabel,
  DialogActions,
  FormControl,
  Select,
  MenuItem,
  Button,
  Stack,
} from "@mui/material"
import { useFormik } from "formik"
import { usePatchTradeReference } from "src/queries/credit/usePatchTradeReference"
import { TradeReference } from "src/types"
import SingleTradeReference from "./SingleTradeReference"
import { useState } from "react"
import { confirm } from "src/components/confirm"
import { NumericFormat } from "react-number-format"
import { convertCommaSeparatedToNumber } from "src/utils/formatNumber"

export default ({
  reference,
  onClose,
  open,
}: {
  reference: TradeReference
  onClose: () => void
  open: boolean
}) => {
  const { execute, isLoading } = usePatchTradeReference(() => {
    onClose()
  }, false)

  const [emailChanged, setEmailChanged] = useState(false)

  const formik = useFormik({
    initialValues: reference,
    onSubmit: (values: TradeReference) => {
      values.creditLimit = convertCommaSeparatedToNumber(values.creditLimit)
      values.balance = convertCommaSeparatedToNumber(values.balance)
      if (emailChanged) {
        confirm(
          "You have changed the contact email for this reference. A new request for a reference will be sent to this address. Would you like to proceed?",
        )
          .then(() => {
            execute(values)
            return
          })
          .catch((err) => console.log(err))
      } else {
        execute(values)
      }
    },
  })

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={"md"}>
      <DialogTitle>Enter Trade Reference</DialogTitle>
      <Divider style={{}} />
      <DialogContent>
        <Stack
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <SingleTradeReference
            data={formik.values}
            onChange={(key, value) => {
              formik.setFieldValue(key, value, false)
              if (key === "email") {
                setEmailChanged(true)
              }
            }}
            errors={formik.errors}
          />
          <Grid container>
            <Grid item md={8}>
              <Typography>Account open date</Typography>
            </Grid>
            <Grid item md={4}>
              <TextField
                type="date"
                value={formik.values.accountOpenDate}
                onChange={(e) => {
                  formik.setFieldValue("accountOpenDate", e.target.value)
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={8}>
              <Typography>Last sold date</Typography>
            </Grid>
            <Grid item md={4}>
              <TextField
                type="date"
                value={formik.values.lastSoldDate}
                onChange={(e) => {
                  formik.setFieldValue("lastSoldDate", e.target.value)
                }}
              />
              {/* <DesktopDatePicker
              label="Last sold date"
              inputFormat="MM/dd/yyyy"
              value={formik.values.lastSoldDate}
              onChange={(e) => {
                console.log(e)
                formik.setFieldValue("lastSoldDate", e)
              }}
              renderInput={(
                params: JSX.IntrinsicAttributes & TextFieldProps
              ) => (
                <TextField
                  {...params}
                  // error={
                  //   formik.touched.invoiceDate &&
                  //   Boolean(formik.errors.invoiceDate)
                  // }
                />
              )}
            /> */}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={8}>
              <Typography>Currency</Typography>
            </Grid>
            <Grid item md={4}>
              <FormControl fullWidth>
                <InputLabel id="currency-repay">Currency</InputLabel>
                <Select
                  labelId="currency-repay"
                  id="currency-select"
                  value={formik.values.currency}
                  label="Currency"
                  onChange={(e) => {
                    formik.setFieldValue("currency", e.target.value)
                  }}
                >
                  <MenuItem value={"USD"}>USD</MenuItem>
                  <MenuItem value={"CAD"}>CAD</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={8}>
              <Typography>Highest Credit limit</Typography>
            </Grid>
            <Grid item md={4}>
              <NumericFormat
                customInput={TextField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                value={formik.values.creditLimit}
                onChange={(e) => {
                  formik.setFieldValue("creditLimit", e.target.value)
                }}
                allowLeadingZeros
                thousandSeparator=","
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={8}>
              <Typography>Latest Balance</Typography>
            </Grid>
            <Grid item md={4}>
              <NumericFormat
                customInput={TextField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                value={formik.values.balance}
                onChange={(e) => {
                  formik.setFieldValue("balance", e.target.value)
                }}
                allowLeadingZeros
                thousandSeparator=","
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={8}>
              <Typography>Terms</Typography>
            </Grid>
            <Grid item md={4}>
              <FormControl fullWidth>
                <InputLabel id="net-terms">Net Terms</InputLabel>
                <Select
                  labelId="net-terms"
                  id="net-terms-select"
                  value={formik.values.terms}
                  label="Net Terms"
                  onChange={(e) => {
                    formik.setFieldValue("terms", e.target.value)
                  }}
                >
                  <MenuItem value={0}>Cash</MenuItem>
                  <MenuItem value={15}>Net 15</MenuItem>
                  <MenuItem value={30}>Net 30</MenuItem>
                  <MenuItem value={45}>Net 45</MenuItem>
                  <MenuItem value={60}>Net 60</MenuItem>
                  <MenuItem value={75}>Net 75</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item md={8}>
              <Typography>Current Payment Status</Typography>
            </Grid>
            <Grid item md={4}>
              <FormControl fullWidth>
                <InputLabel id="payment-status">Payment Status</InputLabel>
                <Select
                  labelId="payment-status"
                  id="payment-status-select"
                  value={formik.values.paymentStatus}
                  label="Payment Status"
                  onChange={(e) => {
                    formik.setFieldValue("paymentStatus", e.target.value)
                  }}
                >
                  <MenuItem value={"future"}>Future</MenuItem>
                  <MenuItem value={"current"}>Current</MenuItem>
                  <MenuItem value={"1-30"}>1-30 Days</MenuItem>
                  <MenuItem value={"31-60"}>31-60 Days</MenuItem>
                  <MenuItem value={"61-90"}>61-90 Days</MenuItem>
                  <MenuItem value={"91+"}>91+ Days</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={8}>
              <Typography>Average Days to Repay</Typography>
            </Grid>
            <Grid item md={4}>
              <FormControl fullWidth>
                <InputLabel id="avg-days-to-repay">
                  Avg Days to Repay
                </InputLabel>
                <Select
                  labelId="avg-days-to-repay"
                  id="avg-days-to-repay-select"
                  value={formik.values.avgDaysToRepay}
                  label="Avg Days to Repay"
                  onChange={(e) => {
                    formik.setFieldValue("avgDaysToRepay", e.target.value)
                  }}
                >
                  <MenuItem value={"1-15"}>1-15 Days</MenuItem>
                  <MenuItem value={"16-30"}>16-30 Days</MenuItem>
                  <MenuItem value={"31-45"}>31-45 Days</MenuItem>
                  <MenuItem value={"46-60"}>46-60 Days</MenuItem>
                  <MenuItem value={"61+"}>60+ Days</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={4}>
              <Typography>Select all that apply</Typography>
            </Grid>
            <Grid item md={8}>
              <FormControl fullWidth>
                <Autocomplete
                  fullWidth
                  multiple
                  sx={{
                    width: "100%",
                  }}
                  options={[
                    "Prompt & satisfactory",
                    "Satisfactory",
                    "Slow but collectible / Asks for more time",
                    "Slow and unsatisfactory",
                    "Account secured",
                    "Payment in advance",
                    "NSF Checks",
                  ]}
                  defaultValue={
                    (formik.values.reviewItems as string[] | undefined) || []
                  }
                  value={
                    (formik.values.reviewItems as string[] | undefined) || []
                  }
                  onChange={(e, value) => {
                    formik.setFieldValue("reviewItems", value)
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Response" />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={12}>
              <Typography>Comments</Typography>
              <TextField
                type="text"
                fullWidth
                value={formik.values.comments}
                onChange={(e) => {
                  formik.setFieldValue("comments", e.target.value)
                }}
              />
            </Grid>
          </Grid>
        </Stack>
      </DialogContent>
      <Divider style={{}} />
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <LoadingButton
          type="submit"
          variant="contained"
          onClick={() => formik.submitForm()}
          loading={isLoading}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
