import { QueryClient } from "react-query"
import { useTypedMutation } from "../useTypedMutation"
import { CreditApplication } from "../../types"

export function useRequestSecureDocument(onSuccess?: () => void) {
  const ENDPOINT = "/application/signature"
  const postRequestSecureDocument = useTypedMutation<
    Partial<CreditApplication>
  >("post/application/signature", onSuccess)

  const execute = (app_id: string, onSuccess?: () => void) => {
    postRequestSecureDocument.mutate(
      {
        endpoint: ENDPOINT,
        body: { id: app_id },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
      },
    )
  }
  return { ...postRequestSecureDocument, execute }
}
