import { QueryClient } from "react-query"
import { TemplateCustomField } from "../../types"
import { useTypedMutation } from "../useTypedMutation"

export function usePatchTemplateCustomFields(
  onSuccess?: (data: TemplateCustomField) => void,
) {
  const ENDPOINT = "/configure/custom_fields"
  const patchTemplate = useTypedMutation<FormData>(
    "patch/configure/custom_fields",
    onSuccess,
  )

  const execute = (
    body: Partial<TemplateCustomField>,
    onSuccess?: () => void,
  ) => {
    const formData = new FormData()
    for (const item of Object.keys(body)) {
      if (item === "file" && body["file"]) {
        if (body["file"] instanceof File) {
          formData.append("file", body["file"] as File)
        } else {
          formData.append("file", JSON.stringify(body["file"]))
        }
      } else if (item === "conditions" && body["conditions"]) {
        formData.append("conditions", JSON.stringify(body["conditions"]))
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formData.append(item, body[item as keyof TemplateCustomField])
      }
    }
    patchTemplate.mutate(
      {
        method: "patch",
        endpoint: ENDPOINT + `/${body.id}/`,
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
      },
    )
  }
  const executeAsync = (body: TemplateCustomField, onSuccess?: () => void) => {
    const formData = new FormData()
    for (const item of Object.keys(body)) {
      if (item === "file" && body["file"]) {
        if (body["file"] instanceof File) {
          formData.append("file", body["file"] as File)
        } else {
          formData.append("file", JSON.stringify(body["file"]))
        }
      } else if (item === "conditions" && body["conditions"]) {
        formData.append("conditions", JSON.stringify(body["conditions"]))
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formData.append(item, body[item as keyof TemplateCustomField])
      }
    }
    return patchTemplate.mutateAsync(
      {
        method: "patch",
        endpoint: ENDPOINT + `/${body.id}/`,
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
      },
    )
  }
  return { ...patchTemplate, execute, executeAsync }
}
