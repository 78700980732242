import { QueryClient } from "react-query"
import { useTypedMutation } from "../useTypedMutation"
import { Business } from "src/types"

export function usePatchInternalBusiness(onSuccess?: () => void) {
  const patch = useTypedMutation<Business>("patch/internal/business", onSuccess)
  const execute = (body: Business, onSuccess: () => void) => {
    const id = body.id
    delete body["id"]
    delete body["logo"]
    delete body["terms"]

    patch.mutate(
      {
        method: "patch",
        endpoint: "/internal/business/" + id + "/",
        body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: ["/internal/business".replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
      },
    )
  }
  return { ...patch, execute }
}
