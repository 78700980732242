import { alpha } from "@mui/material/styles"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import { OpenCorporateSearchInterface } from "src/types/vendors.types"
import React from "react"
import { Box, Stack } from "@mui/system"

interface ResultItemProps {
  data: OpenCorporateSearchInterface[] | undefined
  onClick?: (item: OpenCorporateSearchInterface) => void
}

import usFlag from "src/resources/flags/us.png"
import caFlag from "src/resources/flags/ca.png"
import defaultFlag from "src/resources/flags/globe.png"

function getImage(jurisdictionCode: string): string {
  if (jurisdictionCode) {
    if (jurisdictionCode.startsWith("us")) {
      return usFlag
    } else if (jurisdictionCode.startsWith("ca")) {
      return caFlag
    }
  }

  return defaultFlag
}

export default ({ data, onClick }: ResultItemProps) => {
  return (
    <>
      {data?.map((item, index) => (
        <ListItemButton
          onClick={() => onClick?.(item)}
          sx={{
            borderWidth: 1,
            borderStyle: "dashed",
            borderColor: "transparent",
            borderBottomColor: (theme) => theme.palette.divider,
            "&:hover": {
              borderRadius: 1,
              borderColor: (theme) => theme.palette.primary.main,
              backgroundColor: (theme) =>
                alpha(
                  theme.palette.primary.main,
                  theme.palette.action.hoverOpacity,
                ),
            },
          }}
          key={index}
        >
          <ListItemText
            primary={item.name ?? ""}
            secondary={
              <Stack direction="row" spacing={2} alignItems="center">
                {" "}
                <Box
                  sx={{
                    width: "60px",
                    height: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={getImage(item.jurisdictionCode)}
                    alt="Flag"
                    style={{ width: "20px", height: "20px" }}
                  />
                </Box>
                <Box
                  component="span"
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                    display: "inline-block",
                    maxWidth: "80%",
                  }}
                >
                  {item.registeredAddressInFull ?? ""}
                </Box>
              </Stack>
            }
            primaryTypographyProps={{
              style: { whiteSpace: "normal", wordWrap: "break-word" },
            }}
            secondaryTypographyProps={{
              style: {
                display: "block",
                whiteSpace: "normal",
                wordWrap: "break-word",
              },
            }}
          />
        </ListItemButton>
      ))}
    </>
  )
}
