import { QueryClient } from "react-query"
import { useTypedMutation } from "../useTypedMutation"
import { TradeReference } from "../../types"

export function usePatchTradeReference(
  onSuccess?: (data: string) => void,
  anonymous = true,
) {
  const ENDPOINT = "/application/base/trade_references/"
  const patchTradeReference = useTypedMutation<TradeReference>(
    "post/application/base/trade_references",
    onSuccess,
  )

  const execute = (body: Partial<TradeReference>, onSuccess?: () => void) => {
    patchTradeReference.mutate(
      {
        endpoint: ENDPOINT + body.id + "/",
        body,
        method: "patch",
        anonymous,
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
      },
    )
  }
  return { ...patchTradeReference, execute }
}
