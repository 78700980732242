import React, { useState } from "react"
// @mui
import { useTheme } from "@mui/material/styles"
import Box from "@mui/material/Box"
import InputBase from "@mui/material/InputBase"
import InputAdornment from "@mui/material/InputAdornment"
// hooks
import useDebounced from "src/hooks/useDebounced"
// components
import Label from "src/components/label"
import Iconify from "src/components/iconify"
import Scrollbar from "src/components/scrollbar"
import SearchNotFound from "src/components/search-not-found"
//
import ExperianResultItem from "../../components/result-item/ExperianResultItem"
import { CreditApplication, ExperianSearchResult } from "src/types"
import { CircularProgress, Dialog } from "@mui/material"
import { useExperianSearch } from "src/queries/vendors/useExperianSearch"
import { confirm } from "src/components/confirm"
import stateNameToAbbreviation from "src/utils/utils"

// ----------------------------------------------------------------------

type Props = {
  application: CreditApplication
  isSearchDialogOpen: boolean
  handleCloseSearchDialog: (bin: string | undefined) => void
}

export default ({
  application,
  isSearchDialogOpen,
  handleCloseSearchDialog,
}: Props) => {
  const theme = useTheme()
  const [searchQuery, setSearchQuery] = useState(
    application.data?.legalBusinessName || "",
  )

  const debouncedSearchTerm = useDebounced(searchQuery)

  const { data, isLoading } = useExperianSearch(
    application?.seller?.id || "",
    debouncedSearchTerm,
    100,
    1,
    {
      name: application.data?.legalBusinessName,
      city: application.data?.businessCity,
      state: stateNameToAbbreviation(application.data?.businessRegion),
      // street: application.data?.businessAddress,
      // zip: application.data?.businessPostCode,
      // country: application.data?.businessCountry,
      // comments: "Credit Report",
    },
  )

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const handleOnItemClick = (item: ExperianSearchResult) => {
    confirm("Continue to purchase report?")
      .then(
        () => {
          return handleCloseSearchDialog(item.bin)
        },
        () => {
          console.log("cancelled")
        },
      )
      .catch((err) => {
        console.log(err)
      })
  }

  const notFound = data?.results?.length == 0

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isSearchDialogOpen}
      onClose={() => {
        handleCloseSearchDialog(undefined)
      }}
    >
      <Box sx={{ p: 3, borderBottom: `solid 1px ${theme.palette.divider}` }}>
        <InputBase
          fullWidth
          autoFocus
          placeholder="Search by company name..."
          value={searchQuery}
          onChange={handleSearch}
          startAdornment={
            <InputAdornment position="start">
              <Iconify
                icon="eva:search-fill"
                width={24}
                sx={{ color: "text.disabled" }}
              />
            </InputAdornment>
          }
          endAdornment={
            <Label sx={{ letterSpacing: 1, color: "text.secondary" }}>
              esc
            </Label>
          }
          inputProps={{
            sx: { typography: "h6" },
          }}
        />
      </Box>
      <Scrollbar sx={{ p: 3, pt: 2, height: 400 }}>
        {!isLoading && notFound && (
          <SearchNotFound query={debouncedSearchTerm} sx={{ py: 10 }} />
        )}
        {!isLoading && !notFound && (
          <ExperianResultItem
            data={data?.results}
            onClick={handleOnItemClick}
            purchasedIds={data?.purchasedIds || []}
          />
        )}
        {isLoading && <CircularProgress />}
      </Scrollbar>
    </Dialog>
  )
}
