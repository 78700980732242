import { LoadingButton } from "@mui/lab"
import { CreditApplication } from "../../../types"
import {
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"
import { useDocumentPDF } from "src/queries/vendors/useDocumentPDF"
import { useDocumentDetails } from "src/queries/vendors/useDocumentDetails"
import Label from "src/components/label"
import { useRequestPersonalGuaranty } from "src/queries/credit/useRequestPersonalGuaranty"
import { enqueueSnackbar } from "notistack"
import { info, error } from "src/utils/logger"
import { TableHeadCustom } from "src/components/table"
import * as Sentry from "@sentry/react"
import { startCase } from "lodash"
import { useState } from "react"
import { useDeletePersonalGuarantor } from "src/queries/credit/useDeletePersonalGuarantor"
import AddNewPersonalGuarantorDialog from "./components/AddNewPersonalGuarantorDialog"
import { confirm } from "src/components/confirm"
import {
  PAGE_LABEL_PERSONAL_GUARANTY,
  PERSONAL_GUARANTY_CONFIG_NUMBER_OF_GUARANTORS,
} from "../intake_sections/constants"

export default function ({
  application,
  refreshApplication,
}: {
  application: CreditApplication
  refreshApplication: () => void
}) {
  const [showAddPersonalGuarantorDialog, setAddPersonalGuarantor] =
    useState(false)
  const { refetch: fetchPdf, isLoading } = useDocumentPDF(
    application.personalGuaranty
      ? application.personalGuaranty[0]?.documentRequestId
      : "",
    "guaranty",
  )

  const [notes, setNotes] = useState("")
  const [reminderDialogOpen, setReminderDialogOpen] = useState(false)

  const { data: documentDetails, isLoading: isFetchingDetails } =
    useDocumentDetails(
      application.personalGuaranty
        ? application.personalGuaranty[0]?.documentRequestId
        : "",
      "guaranty",
    )

  const { execute: requestSecureDocument, isLoading: isRequesting } =
    useRequestPersonalGuaranty(() => {
      enqueueSnackbar("Request sent successfully.", { variant: "success" })
      setReminderDialogOpen(false)
    })

  const { execute: deletePersonalGuarantor } = useDeletePersonalGuarantor(
    () => {
      refreshApplication()
      enqueueSnackbar("Personal Guarantor deleted successfully.", {
        variant: "success",
      })
    },
  )
  const applicationId = application.id || ""

  const getLabel = (signed: boolean) => {
    return (
      <Label color={signed ? "success" : "warning"}>
        {signed ? "SIGNED" : "NOT SIGNED"}
      </Label>
    )
  }

  const canAddPersonalGuaranty = (application: CreditApplication) => {
    if (application && application.template && application.template.pages) {
      const page = application.template.pages.find(
        (p) => p.label === PAGE_LABEL_PERSONAL_GUARANTY,
      )
      if (page && page.enabled) {
        let numberPossibleOfGuarantors = 1
        let numberExpectedOfGuarantors = 0
        page.config.forEach((config) => {
          if (config.label === PERSONAL_GUARANTY_CONFIG_NUMBER_OF_GUARANTORS) {
            numberPossibleOfGuarantors = Number(config.value)
          }
        })
        if (application.personalGuaranty) {
          numberExpectedOfGuarantors = application.personalGuaranty.length
        }
        if (numberExpectedOfGuarantors < numberPossibleOfGuarantors) {
          return true
        }
      }
    }
    return false
  }

  const findAction = (actionId: string) => {
    if (!documentDetails?.requests?.actions) return undefined

    if (documentDetails?.requests?.actions.length === 0) {
      Sentry.captureMessage(
        "No actions found in document details " + actionId,
        "error",
      )
      return undefined
    }

    if (documentDetails?.requests?.actions.length === 1) {
      return documentDetails?.requests?.actions[0]
    }

    return documentDetails?.requests?.actions?.find(
      (a: any) => a.actionId === actionId,
    )
  }

  return (
    <Card>
      <CardHeader
        title={
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                alignItems: "center",
              }}
            >
              Personal Guarantor
              {isFetchingDetails && <CircularProgress size={24} />}
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "8px",
              }}
            >
              <LoadingButton
                variant="outlined"
                onClick={() => {
                  setReminderDialogOpen(true)
                }}
                // disabled if there are no personal guarantors or if all personal guarantors have signed
                disabled={
                  !application.personalGuaranty ||
                  application.personalGuaranty.find((p) => {
                    return findAction(p.actionId)?.actionStatus !== "SIGNED"
                  }) === undefined
                }
                loading={isRequesting}
              >
                Send Reminder
              </LoadingButton>
              <LoadingButton
                loading={isLoading}
                disabled={
                  !application.personalGuaranty ||
                  application.personalGuaranty.length === 0 ||
                  !application.personalGuaranty[0]?.documentRequestId
                }
                variant="contained"
                onClick={() => {
                  fetchPdf()
                    .then((res) => {
                      function saveByteArray(fileName: string, b64: string) {
                        const link = document.createElement("a")
                        link.href = "data:application/pdf;base64," + b64
                        link.download = fileName
                        link.click()
                      }
                      if (!res.data) throw new Error("No data")
                      return saveByteArray(
                        `${startCase(
                          application.data.legalBusinessName ||
                            `${application.data.firstName} ${application.data.lastName}`,
                        )} Personal Guarantor Agreement.pdf`,
                        res.data,
                      )
                    })
                    .catch((err) => error(err))
                }}
              >
                Download PDF
              </LoadingButton>
              <Button
                variant="outlined"
                onClick={() => setAddPersonalGuarantor(true)}
                disabled={!canAddPersonalGuaranty(application)}
              >
                Add Guarantor
              </Button>
            </Box>
          </Box>
        }
      />

      <Dialog
        open={reminderDialogOpen}
        onClose={() => setReminderDialogOpen(false)}
        maxWidth={"sm"}
      >
        <DialogTitle>Send Reminder</DialogTitle>
        <Divider style={{}} />
        <DialogContent>
          <Stack
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Typography align="justify">
              You are about to send a reminder to pending signers.
            </Typography>

            <Grid container>
              <Grid item md={12}>
                <Typography>Notes</Typography>
                <TextField
                  type="text"
                  fullWidth
                  multiline
                  placeholder="Enter any additional instructions here. This will be included in the email."
                  value={notes}
                  onChange={(e) => {
                    setNotes(e.target.value)
                  }}
                />
              </Grid>
            </Grid>
          </Stack>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={() => setReminderDialogOpen(false)}>Close</Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isRequesting}
            onClick={() => {
              requestSecureDocument(application.id || "", notes)
            }}
          >
            Send
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <TableContainer sx={{ overflow: "unset", marginTop: "24px" }}>
        <Table sx={{ minWidth: 680 }}>
          <TableHeadCustom
            headLabel={[
              { id: "name", label: "Name" },
              { id: "email", label: "Email" },
              { id: "phone", label: "Phone Number" },
              { id: "status", label: "Status" },
              { id: "action", label: "Action" },
            ]}
          />

          <TableBody>
            {application.personalGuaranty &&
              application.personalGuaranty.map((row) => (
                <>
                  <TableRow>
                    <TableCell>{row.name}</TableCell>

                    <TableCell>{row.email}</TableCell>

                    <TableCell>{row.phoneNumber}</TableCell>

                    <TableCell>
                      {!isFetchingDetails &&
                        getLabel(
                          findAction(row.actionId)?.actionStatus === "SIGNED",
                        )}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        disabled={
                          findAction(row.actionId)?.actionStatus === "SIGNED"
                        }
                        onClick={() => {
                          confirm(
                            "Are You Sure You Want To Delete The Personal Guarantor Request To " +
                              row.name +
                              "?",
                          )
                            .then(
                              () => {
                                return deletePersonalGuarantor(
                                  applicationId,
                                  row.id,
                                )
                              },
                              () => {
                                info("cancelled")
                              },
                            )
                            .catch(() => {
                              error("cancelled")
                            })
                        }}
                      >
                        delete
                      </Button>
                    </TableCell>
                  </TableRow>
                </>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AddNewPersonalGuarantorDialog
        application={application}
        onClose={() => setAddPersonalGuarantor(false)}
        open={showAddPersonalGuarantorDialog}
        refreshApplication={refreshApplication}
      />
    </Card>
  )
}
