import {
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"
import { Stack } from "@mui/system"
import { enqueueSnackbar } from "notistack"
import { useState } from "react"
import { usePatchCreditApplicationTemplatePage } from "src/queries/internal/usePatchCreditApplicationTemplatePage"
import {
  ADDITIONAL_QUESTIONS_DATA_KEY,
  BANK_REFERENCE_DATA_KEY,
  BUSINESS_DETAILS_DATA_KEY,
  FORM_INPUT_NAMES,
  OWNERS_DATA_KEY,
  USER_DETAILS_DATA_KEY,
} from "src/sections/@dashboard/intake_sections/constants"
import { ApplicationTemplate, Page, WhenCondition } from "src/types"
import { confirm } from "src/components/confirm/"

export default function SectionWhenComponent({
  page,
  template,
  refetch,
}: {
  page: Page
  template: ApplicationTemplate
  refetch: () => void
}) {
  const [newConditionType, setNewConditionType] = useState("dataKey")
  const [newWhenCondition, setNewWhenCondition] = useState({} as WhenCondition)
  const [newConstraint, setNewConstraint] = useState("requiredWhen")

  const { execute, isLoading } = usePatchCreditApplicationTemplatePage(
    template.id || "",
    () => {
      enqueueSnackbar("Application Template Updated", { variant: "success" })
    },
  )

  const handleAdd = () => {
    if (
      newWhenCondition &&
      newWhenCondition.value &&
      typeof newWhenCondition.value == "string" &&
      newWhenCondition.value.split(",").length > 1
    ) {
      newWhenCondition.value = newWhenCondition.value.split(",")
    }

    if (newConstraint == "requiredWhen") {
      if (page.requiredWhen) {
        page.requiredWhen.push(newWhenCondition)
      } else {
        page.requiredWhen = [newWhenCondition]
      }
    }
    if (newConstraint == "optionalWhen") {
      if (page.optionalWhen) {
        page.optionalWhen.push(newWhenCondition)
      } else {
        page.optionalWhen = [newWhenCondition]
      }
    }
    if (newConstraint == "enabledWhen") {
      if (page.enabledWhen) {
        page.enabledWhen.push(newWhenCondition)
      } else {
        page.enabledWhen = [newWhenCondition]
      }
    }
    if (newConstraint == "disabledWhen") {
      if (page.disabledWhen) {
        page.disabledWhen.push(newWhenCondition)
      } else {
        page.disabledWhen = [newWhenCondition]
      }
    }

    execute(page, () => {
      refetch()
    })
  }
  const ALL_DATA_KEYS = {
    ...USER_DETAILS_DATA_KEY,
    ...BUSINESS_DETAILS_DATA_KEY,
    ...OWNERS_DATA_KEY,
    ...BANK_REFERENCE_DATA_KEY,
    ...ADDITIONAL_QUESTIONS_DATA_KEY,
  }
  ALL_DATA_KEYS[0] = FORM_INPUT_NAMES.APPLICANT_TYPE

  const getDataKeys = () => {
    return Object.entries(ALL_DATA_KEYS).map(([key, value]) => (
      <MenuItem key={key} value={key}>
        {value}
      </MenuItem>
    ))
  }

  const additionalQuestionMenu = () => {
    const result = []
    if (template && template?.customFields) {
      for (const customField of template.customFields) {
        result.push(
          <MenuItem key={customField.id} value={customField.id}>
            {customField.text}
          </MenuItem>,
        )
      }
    }
    return result
  }

  const getCustomField = (id: string) => {
    if (template && template.customFields) {
      for (const customField of template.customFields) {
        if (customField.id === id) {
          return customField
        }
      }
    }
    return undefined
  }

  const handleDelete = (index: number, constraint: string) => {
    if (constraint == "requiredWhen") {
      page.requiredWhen?.splice(index, 1)
      if (page.requiredWhen?.length == 0) {
        delete page.requiredWhen
      }
    }
    if (constraint == "optionalWhen") {
      page.optionalWhen?.splice(index, 1)
      if (page.optionalWhen?.length == 0) {
        delete page.optionalWhen
      }
    }
    if (constraint == "enabledWhen") {
      page.enabledWhen?.splice(index, 1)
      if (page.enabledWhen?.length == 0) {
        delete page.enabledWhen
      }
    }
    if (constraint == "disabledWhen") {
      page.disabledWhen?.splice(index, 1)
      if (page.disabledWhen?.length == 0) {
        delete page.disabledWhen
      }
    }
    execute(page, () => {
      refetch()
    })
  }

  const row = (constraint: string, condition: WhenCondition, index: number) => {
    return (
      <TableRow key={index + constraint}>
        <TableCell>{constraint}</TableCell>
        <TableCell>
          {condition.customFieldId
            ? getCustomField(condition.customFieldId)?.text
            : ""}
        </TableCell>
        <TableCell>
          {condition.dataKey && ALL_DATA_KEYS[Number(condition.dataKey)]
            ? ALL_DATA_KEYS[Number(condition.dataKey)]
            : ""}
        </TableCell>
        <TableCell>{condition.value}</TableCell>
        <TableCell>
          <Button
            variant="outlined"
            onClick={() => {
              confirm("Are you Sure you want to Delete this Condition?")
                .then(
                  () => {
                    handleDelete(index, constraint)
                    return
                  },
                  () => {
                    console.log("error")
                  },
                )
                .catch(() => {
                  console.log("error")
                })
            }}
          >
            Remove
          </Button>
        </TableCell>
      </TableRow>
    )
  }
  return (
    <>
      <Divider />
      <TableContainer component={Paper} sx={{ m: 2 }}>
        <TableHead>
          <TableRow>
            <TableCell>Constraint</TableCell>
            <TableCell>CustomField</TableCell>
            <TableCell>Data Key</TableCell>
            <TableCell>Value</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {page.requiredWhen &&
            page.requiredWhen.map((whenCondition, index) => {
              return row("requiredWhen", whenCondition, index)
            })}
          {page.optionalWhen &&
            page.optionalWhen.map((whenCondition, index) => {
              return row("optionalWhen", whenCondition, index)
            })}
          {page.enabledWhen &&
            page.enabledWhen.map((whenCondition, index) => {
              return row("enabledWhen", whenCondition, index)
            })}
          {page.disabledWhen &&
            page.disabledWhen.map((whenCondition, index) => {
              return row("disabledWhen", whenCondition, index)
            })}
        </TableBody>
      </TableContainer>
      <Divider />
      <Stack sx={{ marginBottom: 2 }}>
        <Typography variant="h6">Add New Condition</Typography>
      </Stack>
      <Stack spacing={2} direction={"row"} sx={{ m: 2 }}>
        <FormControl sx={{ width: "300px" }}>
          <InputLabel
            id="condition=type-label"
            sx={{
              zIndex: 100,
              backgroundColor: "#FFF",
              paddingRight: "0.3rem",
            }}
          >
            Constraint
          </InputLabel>
          <Select
            labelId="constraint-label"
            label="Constraint"
            id="constraint"
            value={newConstraint}
            onChange={(event) => {
              setNewConstraint(event.target.value as string)
            }}
          >
            <MenuItem value={"requiredWhen"}>Required</MenuItem>
            <MenuItem value={"optionalWhen"}>Optional</MenuItem>
            <MenuItem value={"enabledWhen"}>Enabled</MenuItem>
            <MenuItem value={"disabledWhen"}>Disabled</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ width: "300px" }}>
          <InputLabel
            id="condition=type-label"
            sx={{
              zIndex: 100,
              backgroundColor: "#FFF",
              paddingRight: "0.3rem",
            }}
          >
            Condition Type
          </InputLabel>
          <Select
            labelId="condition-type-label"
            label="Condition Type"
            id="condition-type"
            value={newConditionType}
            onChange={(event) => {
              setNewConditionType(event.target.value as string)
            }}
          >
            <MenuItem value={"dataKey"}>Data Key</MenuItem>
            <MenuItem value={"customFieldId"}>Additional Questions</MenuItem>
          </Select>
        </FormControl>
        {newConditionType === "dataKey" && (
          <FormControl sx={{ width: "300px" }}>
            <InputLabel
              id="data-key-label"
              sx={{
                zIndex: 100,
                backgroundColor: "#FFF",
                paddingRight: "0.3rem",
              }}
            >
              Data Key
            </InputLabel>
            <Select
              labelId="data-key-label"
              label="datakey"
              id="datakey"
              value={newWhenCondition.dataKey}
              onChange={(event) => {
                newWhenCondition.dataKey = event.target.value as string
                delete newWhenCondition.customFieldId
                setNewWhenCondition(newWhenCondition)
              }}
            >
              {getDataKeys()}
            </Select>
          </FormControl>
        )}
        {newConditionType === "customFieldId" && (
          <FormControl sx={{ width: "300px" }}>
            <InputLabel
              id="additional-questions-label"
              sx={{
                zIndex: 100,
                backgroundColor: "#FFF",
                paddingRight: "0.3rem",
              }}
            >
              Additional Questions
            </InputLabel>
            <Select
              labelId="additional-questions-label"
              label="Additional Questions"
              id="additional-questions"
              value={newWhenCondition.customFieldId}
              onChange={(event) => {
                newWhenCondition.customFieldId = event.target.value as string
                delete newWhenCondition.dataKey
                setNewWhenCondition(newWhenCondition)
              }}
            >
              {additionalQuestionMenu()}
            </Select>
          </FormControl>
        )}
        <TextField
          id="value"
          margin="normal"
          label="Value"
          type="text"
          value={newWhenCondition.value}
          onChange={(event) => {
            newWhenCondition.value = event.target.value as string
            setNewWhenCondition(newWhenCondition)
          }}
        />
        <Button
          variant="outlined"
          disabled={isLoading}
          onClick={() => {
            if (
              (newWhenCondition.dataKey || newWhenCondition.customFieldId) &&
              newWhenCondition.value
            ) {
              handleAdd()
            } else {
              enqueueSnackbar("Please fill out all field for new condition", {
                variant: "error",
              })
            }
          }}
        >
          Add
        </Button>
      </Stack>
    </>
  )
}
