import { Formik } from "formik"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  TextField,
} from "@mui/material"
import ReactPhoneInput from "react-phone-input-material-ui"
import { CreditApplication } from "src/types"
import * as Yup from "yup"
import { useSnackbar } from "notistack"
import { useAddPersonalGuarantor } from "src/queries/credit/useAddPersonalGuarantor"

export default function ({
  application,
  onClose,
  refreshApplication,
  open,
}: {
  application: CreditApplication
  onClose: () => void
  refreshApplication: () => void
  open: boolean
}) {
  const schema = Yup.object().shape({
    guarantorName: Yup.string().required("Required"),
    guarantorEmail: Yup.string().email("Invalid email").required("Required"),
    guarantorPhoneNumber: Yup.string().required("Required"),
  })
  const applicationId = application.id || ""
  const { enqueueSnackbar } = useSnackbar()
  const { execute: AddPersonalGuarantor, isLoading: isAddPersonalGuarantor } =
    useAddPersonalGuarantor(() => {
      refreshApplication()
      enqueueSnackbar("Application updated", { variant: "success" })
      onClose()
    })

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"sm"} fullWidth>
      <Formik
        initialValues={{
          application: applicationId,
          guarantorName: "",
          guarantorPhoneNumber: "",
          guarantorEmail: "",
        }}
        validationSchema={schema}
        onSubmit={(values) => {
          // same shape as initial values
          if (
            application.personalGuaranty &&
            application.personalGuaranty.length > 0
          ) {
            const guarantorCount = application.personalGuaranty.length + 1
            const existingEmails = application.personalGuaranty.map(
              (guarantor) => guarantor.email,
            )
            const existingName = application.personalGuaranty.map(
              (guarantor) => guarantor.name,
            )
            if (existingEmails.includes(values.guarantorEmail)) {
              enqueueSnackbar("Email already exists", { variant: "error" })
              return
            }
            if (existingName.includes(values.guarantorName)) {
              enqueueSnackbar("Name already exists", { variant: "error" })
              return
            }
            const newValues = {}
            Object.assign(newValues, {
              ["guarantorName" + guarantorCount]: values.guarantorName,
            })
            Object.assign(newValues, {
              ["guarantorPhoneNumber" + guarantorCount]:
                values.guarantorPhoneNumber,
            })
            Object.assign(newValues, {
              ["guarantorEmail" + guarantorCount]: values.guarantorEmail,
            })
            //   patchApplication(applicationId, { data: newValues })
            AddPersonalGuarantor(applicationId, values)
          } else {
            AddPersonalGuarantor(applicationId, values)
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle>Add new personal guarantor</DialogTitle>
            <Divider style={{}} />
            <DialogContent>
              <Stack
                sx={{
                  paddingTop: 0,
                  paddingBottom: 2,
                  paddingLeft: 5,
                  paddingRight: 5,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    gap: "32px",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    name="guarantorName"
                    margin="normal"
                    required={true}
                    label="Full Name"
                    onChange={handleChange}
                    fullWidth
                    value={values.guarantorName}
                    error={Boolean(
                      errors.guarantorName && touched.guarantorName,
                    )}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    gap: "32px",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    name="guarantorEmail"
                    margin="normal"
                    required={true}
                    label="Email Address"
                    onChange={handleChange}
                    fullWidth
                    value={values.guarantorEmail}
                    error={Boolean(
                      errors.guarantorEmail && touched.guarantorEmail,
                    )}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    gap: "32px",
                    alignItems: "center",
                  }}
                >
                  <ReactPhoneInput
                    value={values.guarantorPhoneNumber}
                    country={"ca"}
                    onChange={(event) => {
                      setFieldValue("guarantorPhoneNumber", "+" + event, false)
                    }}
                    component={TextField}
                    containerStyle={{ width: "100%", marginTop: "8px" }}
                    inputProps={{
                      id: "guarantorPhoneNumber",
                      name: "guarantorPhoneNumber",
                      required: true,
                      error: Boolean(
                        errors?.guarantorPhoneNumber &&
                          touched.guarantorPhoneNumber,
                      ),
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    gap: "32px",
                    alignItems: "center",
                    paddingTop: 2,
                  }}
                ></Box>
              </Stack>
            </DialogContent>
            <Divider style={{}} />
            <DialogActions>
              <Button
                variant="contained"
                type="submit"
                disabled={isAddPersonalGuarantor}
              >
                Add
              </Button>
              <Button onClick={onClose}>Close</Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}
