import { Button, Paper, Stack, TextField } from "@mui/material"
import Typography from "@mui/material/Typography"
import { Formik } from "formik"
import { enqueueSnackbar } from "notistack"
import { useCreateInternalCreditSafeCredentials } from "src/queries/internal/useCreateInternalCreditSafeCredentials"
import { useListInternalCreditSafeCredentials } from "src/queries/internal/useListInternalCreditSafeCredentials"
import { usePatchInternalCreditSafeCredentials } from "src/queries/internal/usePatchInternalCreditSafeCredentials"
import { ClientCreditSafeCredentials } from "src/types"
import { confirm } from "src/components/confirm/"
import { useDeleteInternalCreditSafeCredentials } from "src/queries/internal/useDeleteInternalCreditSafeCredentials"

export default function ClientExternalCredentialsDetails({
  id,
}: {
  id: string
}) {
  const { data, isFetching, refetch } = useListInternalCreditSafeCredentials(id)
  const { execute: executeCreate, isLoading: isLoadingCreateRequest } =
    useCreateInternalCreditSafeCredentials(() => {
      enqueueSnackbar("Credit Safe Credentials Added", { variant: "success" })
    })
  const { execute: executePatch, isLoading: isLoadingPatchRequest } =
    usePatchInternalCreditSafeCredentials(() => {
      enqueueSnackbar("Credit Safe Credentials Edit", { variant: "success" })
    })

  const { execute: executeDelete, isLoading: isLoadingDeleteRequest } =
    useDeleteInternalCreditSafeCredentials(() => {
      enqueueSnackbar("Credit Safe Credentials Deleted", { variant: "success" })
      refetch()
    })
  return (
    <>
      <Paper variant="outlined" sx={{ marginY: 4, p: 1 }}>
        <Typography variant="h6">CreditSafe Credentials</Typography>
        {isFetching && <Typography>Loading...</Typography>}
        {!isFetching && (
          <Formik
            initialValues={
              data ? data : ({ seller: id } as ClientCreditSafeCredentials)
            }
            onSubmit={(values) => {
              if (data) {
                confirm(
                  "Are you Sure you want to Update the credentials for this user?",
                )
                  .then(
                    () => {
                      executePatch(values, () => {
                        refetch()
                      })
                      return
                    },
                    () => {
                      console.log("error")
                    },
                  )
                  .catch(() => {
                    console.log("error")
                  })
              } else {
                executeCreate(values, () => {
                  refetch()
                })
              }
            }}
          >
            {(props) => (
              <>
                <TextField
                  fullWidth
                  label="Username"
                  id="username"
                  name="username"
                  margin="normal"
                  type="text"
                  value={props.values.username}
                  onChange={props.handleChange}
                  error={Boolean(props.errors?.username)}
                />
                <TextField
                  fullWidth
                  label="Password"
                  id="password"
                  name="password"
                  margin="normal"
                  type="text"
                  value={props.values.password}
                  onChange={props.handleChange}
                  error={Boolean(props.errors?.password)}
                />
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      props.handleSubmit()
                    }}
                    disabled={isLoadingCreateRequest || isLoadingPatchRequest}
                  >
                    {data ? "Update" : "Create"}
                  </Button>
                  {data && data.id && (
                    <Button
                      variant="outlined"
                      onClick={() => {
                        confirm(
                          "Are you Sure you want to Delete the credentials for this user?",
                        )
                          .then(
                            () => {
                              executeDelete(data)
                              return
                            },
                            () => {
                              console.log("error")
                            },
                          )
                          .catch(() => {
                            console.log("error")
                          })
                      }}
                      disabled={isLoadingDeleteRequest}
                    >
                      Delete
                    </Button>
                  )}
                </Stack>
              </>
            )}
          </Formik>
        )}
      </Paper>
    </>
  )
}
