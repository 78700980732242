import { useState } from "react"
import {
  Box,
  Card,
  CardHeader,
  Avatar,
  IconButton,
  InputBase,
  InputAdornment,
  Paper,
  Stack,
} from "@mui/material"
import { alpha } from "@mui/material/styles"
import { stringAvatar } from "src/utils/avatar"
import { fDate } from "src/utils/formatTime"
import { SendOutlined } from "@mui/icons-material"
import { useCashApplicationComments } from "src/queries/cod/useCashApplicationComments"
import { ApplicationComment, CashApplication } from "src/types"
import { usePostCashApplicationComment } from "src/queries/cod/usePostCashApplicationComment"
import { useUser } from "src/queries"

export default ({ application }: { application: CashApplication }) => {
  const { data: userData } = useUser()

  const [draft, setDraft] = useState<string | undefined>(undefined)

  const { data, refetch, isLoading } = useCashApplicationComments(
    application.id,
  )

  const { execute, isLoading: isPosting } = usePostCashApplicationComment(
    () => {
      refetch()
    },
  )

  if (!userData || !data) return <></>

  return (
    <Card>
      <CardHeader title="Comments" />
      <Stack spacing={1.5} sx={{ px: 3, pb: 2 }}>
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          style={{ marginTop: "1rem" }}
        >
          <Avatar
            {...stringAvatar(
              userData.firstName
                ? `${userData.firstName} ${userData.lastName}`
                : userData.email,
            )}
          />

          <InputBase
            fullWidth
            value={draft}
            // inputRef={commentRef}
            placeholder="Write a comment…"
            onChange={(event) => setDraft(event.target.value)}
            endAdornment={
              <InputAdornment position="end" sx={{ mr: 1 }}>
                <IconButton
                  disabled={isLoading || isPosting}
                  onClick={() => {
                    if (draft) {
                      execute({ application: application.id, comment: draft })
                      setDraft("")
                    }
                  }}
                >
                  <SendOutlined />
                </IconButton>
              </InputAdornment>
            }
            sx={{
              pl: 1.5,
              height: 40,
              borderRadius: 1,
              border: (theme) =>
                `solid 1px ${alpha(theme.palette.grey[500], 0.32)}`,
            }}
          />

          <input type="text" style={{ display: "none" }} />
        </Stack>
        {data?.map((comment: ApplicationComment) => {
          return (
            <Stack direction="row" spacing={2} key={comment.id}>
              <Avatar
                {...stringAvatar(
                  comment.user.firstName
                    ? `${comment.user.firstName} ${comment.user.lastName}`
                    : comment.user.email,
                )}
              />

              <Paper
                sx={{
                  p: 1.5,
                  flexGrow: 1,
                  bgcolor: "background.neutral",
                }}
              >
                <Stack
                  sx={{ mb: 0.5 }}
                  alignItems={{ sm: "center" }}
                  justifyContent="space-between"
                  direction={{ xs: "column", sm: "row" }}
                >
                  <Box
                    sx={{ typography: "subtitle2" }}
                    style={{ wordBreak: "break-word" }}
                  >
                    {comment.user.firstName
                      ? `${comment.user.firstName} ${
                          comment.user.lastName ? comment.user.lastName[0] : ""
                        }`
                      : comment.user.email}
                  </Box>

                  <Box sx={{ typography: "caption", color: "text.disabled" }}>
                    {fDate(comment.createdAt)}
                  </Box>
                </Stack>

                <Box
                  sx={{
                    typography: "body2",
                    color: "text.secondary",
                    wordBreak: "break-word",
                  }}
                >
                  {comment.comment}
                </Box>
              </Paper>
            </Stack>
          )
        })}
      </Stack>
    </Card>
  )
}
