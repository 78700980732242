import { useQuery } from "react-query"

export function useDocumentDetails(
  req_id: string,
  category = "application",
  enabled = true,
  refetchInterval = false,
) {
  return useQuery<any>(
    `/document/details?req_id=${req_id}&category=${category}`,
    {
      enabled: enabled && !!req_id,
      retry: (failureCount, error) => {
        if (failureCount < 1) {
          return true
        }
        // do not retry on 404
        return (error as any)?.response?.status !== 404
      },
      refetchInterval: refetchInterval ? 3000 : false,
    },
  )
}
