import { useState } from "react"
import { LoadingButton } from "@mui/lab"
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
  Stack,
  Grid,
  TextField,
  MenuItem,
  Select,
} from "@mui/material"
import { useApplicationTemplate } from "src/queries/credit/useApplicationTemplate"
import { ApplicationTemplate, CreditApplication } from "src/types"
import { usePostAdditionalDataRequest } from "src/queries/credit/additional/usePostAdditionalDataRequest"
import { useSnackbar } from "notistack"
import useGenerateApplicationPdf from "src/hooks/useGenerateApplicationPdf"

export default ({
  application,
  onClose,
  open,
}: {
  application: CreditApplication
  onClose: () => void
  open: boolean
}) => {
  const { data: template } = useApplicationTemplate(application.seller?.id)

  const { execute: generatePdf } = useGenerateApplicationPdf(application)

  const [recipient, setRecipient] = useState(application.data.email || "")
  const [recipientFirst, setRecipientFirst] = useState("")
  const [recipientLast, setRecipientLast] = useState("")

  const [selectedPages, setSelectedPages] = useState<
    ApplicationTemplate["formTemplate"]["pages"]
  >([])

  const [requestSignature, setRequestSignature] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const { execute, isLoading } = usePostAdditionalDataRequest(() => {
    enqueueSnackbar("Request sent successfully.", { variant: "success" })
  })

  const [instructions, setInstructions] = useState("")

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"sm"}>
      <DialogTitle>Request Information</DialogTitle>
      <Divider style={{}} />
      <DialogContent>
        <Stack
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Grid container>
            <Grid item md={4}>
              <Typography>Recipient Email *</Typography>
            </Grid>
            <Grid item md={8}>
              <Select
                labelId="recipient-select"
                id="recipient-select"
                value={recipient}
                fullWidth
                onChange={(e) => {
                  setRecipient(e.target.value)
                }}
              >
                {typeof application.collaborators === "string"
                  ? application.collaborators?.split(",")?.map((email) => {
                      return (
                        <MenuItem value={email} key={email}>
                          {email}
                        </MenuItem>
                      )
                    })
                  : application.collaborators?.map((email) => {
                      return (
                        <MenuItem value={email} key={email}>
                          {email}
                        </MenuItem>
                      )
                    })}
              </Select>
            </Grid>
          </Grid>
          <Typography>
            We will send an email to <b>{recipient}</b> with a link to upload
            the requested information. You will receive a notification when
            completed.
          </Typography>

          <Typography>
            Select the information you would like to request.
          </Typography>
          {template?.formTemplate?.pages
            .filter((p) => p.label !== "User Details")
            .map((page) => (
              <FormControlLabel
                key={page.label}
                control={
                  <Checkbox
                    value="remember"
                    color="primary"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedPages([...selectedPages, page])
                      } else {
                        setSelectedPages(
                          selectedPages.filter(
                            (
                              p: ApplicationTemplate["formTemplate"]["pages"][0],
                            ) => p.label !== page.label,
                          ),
                        )
                      }
                    }}
                  />
                }
                label={page.label}
              />
            ))}
          {template?.customFields?.some(
            (field) => field.associatedPage === "Additional Questions",
          ) && (
            <FormControlLabel
              key={"Additional Questions"}
              control={
                <Checkbox
                  value="remember"
                  color="primary"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedPages([
                        ...selectedPages,
                        {
                          label: "Additional Questions",
                          description: "",
                          enabled: true,
                          required: true,
                          config: [],
                        },
                      ])
                    } else {
                      setSelectedPages(
                        selectedPages.filter(
                          (
                            p: ApplicationTemplate["formTemplate"]["pages"][0],
                          ) => p.label !== "Additional Questions",
                        ),
                      )
                    }
                  }}
                />
              }
              label={"Additional Questions"}
            />
          )}
          <Divider />
          <FormControlLabel
            control={
              <Checkbox
                id="request-signature-checkbox"
                onChange={(event) => {
                  setRequestSignature(event.target.checked)
                }}
                checked={requestSignature}
              />
            }
            label="Request a new digital signature from the applicant."
          />

          {requestSignature && (
            <Typography>
              Note: Please ensure <b>{recipient}</b> is authorized to sign the
              application. If not, close this dialog, update the email in the
              Submitted By section and re-open this dialog.
            </Typography>
          )}
          {selectedPages.length === 0 && requestSignature && (
            <>
              <Typography>
                We require the first and last name of the recipient to request a
                standalone digital signature.
              </Typography>
              <Grid container>
                <Grid item md={4}>
                  <Typography>Recipient First Name *</Typography>
                </Grid>
                <Grid item md={8}>
                  <TextField
                    fullWidth
                    onChange={(e) => {
                      setRecipientFirst(e.target.value)
                    }}
                    value={recipientFirst}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={4}>
                  <Typography>Recipient Last Name *</Typography>
                </Grid>
                <Grid item md={8}>
                  <TextField
                    fullWidth
                    value={recipientLast}
                    onChange={(e) => {
                      setRecipientLast(e.target.value)
                    }}
                  />
                </Grid>
              </Grid>
            </>
          )}

          <Grid container>
            <Grid item md={12}>
              <Typography>Applicant Instructions</Typography>
              <TextField
                type="text"
                fullWidth
                multiline
                placeholder="Enter any additional instructions for the applicant here. This will be included in the email."
                value={instructions}
                onChange={(e) => {
                  setInstructions(e.target.value)
                }}
              />
            </Grid>
          </Grid>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <LoadingButton
          type="submit"
          disabled={
            (selectedPages.length === 0 && !requestSignature) ||
            (selectedPages.length === 0 &&
              requestSignature &&
              (recipientFirst === "" || recipientLast === ""))
          }
          variant="contained"
          loading={isLoading}
          onClick={() => {
            generatePdf()
              .then((res) => {
                return execute(
                  application.id || "",
                  selectedPages,
                  instructions,
                  requestSignature,
                  recipient,
                  recipientFirst,
                  recipientLast,
                  new File([res], "application_pdf.pdf"),
                  () => {
                    enqueueSnackbar("Request sent.", {
                      variant: "success",
                    })
                    onClose()
                  },
                )
              })
              .catch((err) => console.log(err))
          }}
        >
          Send
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
