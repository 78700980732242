import { useQuery } from "react-query"
import { User } from "src/types"

export function useCreditManagers(businessId: string) {
  const djangoResponse = useQuery<User[]>(
    "/application/ar_team?team=CreditManager&business_id=" + businessId,
    {
      enabled: !!businessId,
      retry: (failureCount, error) => {
        if (failureCount < 1) {
          return true
        }
        // do not retry on 404
        return (error as any)?.response?.status !== 404
      },
    },
  )

  return { ...djangoResponse }
}
