import { ArrowForward } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Typography,
  TextField,
  Divider,
  Button,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material"
import ReactPhoneInput from "react-phone-input-material-ui"
import { ApplicationTemplate, CreditApplication } from "src/types"
import { PAGE_LABEL_PERSONAL_GUARANTY } from "./constants"
import { getNumberOfGuanators } from "./schemas/PersonalGuarantySectionSchema"
import { isPageRequired } from "./template_helpers"
import { customWordMapping } from "src/statics"
import { useEffect, useState } from "react"
import { useValidateEmail } from "src/queries/vendors/useValidateEmail"

const PAGE_LABEL = PAGE_LABEL_PERSONAL_GUARANTY

type Props = {
  data: CreditApplication["data"]
  errors: CreditApplication["data"] | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (key: string, value: any) => void
  onContinue: () => void
  onSkip?: () => void
  template: ApplicationTemplate
}

export default ({
  data,
  errors,
  onChange,
  onSkip,
  onContinue,
  template,
}: Props) => {
  const numberOfGuarantors = getNumberOfGuanators(template)

  const [showError, setShowError] = useState(false)
  const [emailToValidate, setEmailToValidate] = useState("")
  const { data: emailValidationResult } = useValidateEmail(
    emailToValidate ? data[emailToValidate] : "",
    "PERSONALGUARANTOR",
  )

  useEffect(() => {
    if (emailValidationResult?.verdict === "Invalid") {
      setShowError(true)
    }
  }, [emailValidationResult, emailToValidate])

  return (
    <>
      <Typography component="h1" variant="h5" style={{ marginTop: "8px" }}>
        Personal Guarantor
        {isPageRequired(PAGE_LABEL, template, data)
          ? ""
          : ` (${customWordMapping("Optional", template)})`}
      </Typography>
      <Typography variant="body2" style={{ margin: "8px 0 8px 0" }}>
        A personal guarantor (PG) helps us assess your application. Please fill
        out the form below. The PG document will be sent to the email(s) you
        specify in this form to be digitally signed. Therefore, as a minimum, we
        require that the email address of the guarantor(s) is included.
      </Typography>
      <Divider style={{ margin: "16px 0 16px 0" }} />
      <form>
        {numberOfGuarantors > 1 && (
          <Typography variant="subtitle1" style={{ marginTop: "8px" }}>
            Guarantor 1
          </Typography>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "32px",
            alignItems: "center",
          }}
        >
          <TextField
            margin="normal"
            fullWidth
            label="Full Name"
            type="text"
            required={isPageRequired(PAGE_LABEL, template, data)}
            value={data.guarantorName}
            onChange={(event) => {
              onChange("guarantorName", event.target.value)
            }}
            error={Boolean(errors?.guarantorName)}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "32px",
            alignItems: "center",
          }}
        >
          <TextField
            margin="normal"
            fullWidth
            label="Email"
            type="email"
            required={isPageRequired(PAGE_LABEL, template, data)}
            value={data.guarantorEmail}
            onChange={(event) => {
              setEmailToValidate("")
              setShowError(false)
              onChange("guarantorEmail", event.target.value)
            }}
            onBlur={(event) => {
              setEmailToValidate("guarantorEmail")
            }}
            error={Boolean(errors?.guarantorEmail)}
          />
          <ReactPhoneInput
            value={data.guarantorPhoneNumber}
            country={"ca"}
            onChange={(event) => {
              onChange("guarantorPhoneNumber", "+" + event)
            }}
            component={TextField}
            containerStyle={{ width: "100%", marginTop: "8px" }}
            inputProps={{
              id: "guarantorPhoneNumber",
              required: isPageRequired(PAGE_LABEL, template, data),
              error: Boolean(errors?.guarantorPhoneNumber),
            }}
          />
        </Box>

        {numberOfGuarantors > 1 &&
          new Array(numberOfGuarantors - 1).fill(0).map((_, index) => (
            <Stack key={index}>
              <Typography variant="subtitle1" style={{ marginTop: "8px" }}>
                Guarantor {index + 2}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  gap: "32px",
                  alignItems: "center",
                }}
              >
                <TextField
                  margin="normal"
                  fullWidth
                  label="Full Name"
                  type="text"
                  value={data["guarantorName" + (index + 2)]}
                  onChange={(event) => {
                    onChange("guarantorName" + (index + 2), event.target.value)
                  }}
                  error={Boolean(errors?.["guarantorName" + (index + 2)])}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  gap: "32px",
                  alignItems: "center",
                }}
              >
                <TextField
                  margin="normal"
                  fullWidth
                  label="Email"
                  type="email"
                  value={data["guarantorEmail" + (index + 2)]}
                  onChange={(event) => {
                    setEmailToValidate("")
                    setShowError(false)
                    onChange("guarantorEmail" + (index + 2), event.target.value)
                  }}
                  error={Boolean(errors?.["guarantorEmail" + (index + 2)])}
                  onBlur={(event) => {
                    setEmailToValidate("guarantorEmail" + (index + 2))
                  }}
                />
                <ReactPhoneInput
                  value={data["guarantorPhoneNumber" + (index + 2)]}
                  country={"ca"}
                  onChange={(event) => {
                    onChange("guarantorPhoneNumber" + (index + 2), "+" + event)
                  }}
                  component={TextField}
                  containerStyle={{ width: "100%", marginTop: "8px" }}
                  inputProps={{
                    id: "guarantorPhoneNumber" + (index + 2),
                    error: Boolean(
                      errors?.["guarantorPhoneNumber" + (index + 2)],
                    ),
                  }}
                />
              </Box>
            </Stack>
          ))}

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "2rem",
          }}
        >
          <LoadingButton
            onClick={() => {
              onContinue()
            }}
            size="large"
            fullWidth
            endIcon={<ArrowForward />}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Save & Continue
          </LoadingButton>
          {template?.formTemplate.pages.find(
            (step) => step.label === PAGE_LABEL,
          )?.skippable &&
            onSkip && (
              <Button
                id="continue-owner"
                onClick={() => {
                  onSkip()
                }}
                size="large"
                variant="outlined"
                sx={{ mt: 3, mb: 2 }}
              >
                Skip
              </Button>
            )}
        </Box>
      </form>
      {showError && (
        <Dialog open={showError}>
          <DialogTitle>Did you enter the right email?</DialogTitle>
          <DialogContent>
            <Typography>
              The email <b>{emailValidationResult?.email}</b> seems to not be
              correct. Please double check and make sure the address is valid to
              avoid delays in processing your application.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setShowError(false)
              }}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}
