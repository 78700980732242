import { UseMutationResult } from "react-query"
import { useTypedMutation } from "../useTypedMutation"
import { MutationVariables, User } from "../../types"

type ExecuteType = {
  execute: (username: string, body: User, onSuccess: () => void) => void
}
export type UsePatchUserType = UseMutationResult<
  unknown,
  unknown,
  MutationVariables<User>,
  unknown
> &
  ExecuteType

export function usePatchUser(onSuccess?: (data: string) => void) {
  const ENDPOINT = "/users/"
  const patchUser = useTypedMutation<User>("post/user", onSuccess)

  const execute = (username: string, body: User, onSuccess: () => void) => {
    patchUser.mutate(
      {
        method: "patch",
        endpoint: ENDPOINT + username + "/",
        body,
      },
      { onSuccess },
    )
  }
  return { ...patchUser, execute }
}
