import { Button, TableRow, TableCell, ListItemText } from "@mui/material"
import { Box } from "@mui/system"
import { CreditApplication } from "src/types"
import { fDate } from "src/utils/formatTime"
import { getStatusLabel } from "../../utils"
import { useNavigate } from "react-router-dom"
import queryString from "query-string"

export default ({ row, index }: { row: CreditApplication; index: number }) => {
  const navigate = useNavigate()

  return (
    <TableRow
      hover
      key={row.id}
      tabIndex={-1}
      role="checkbox"
      style={{ width: "100%" }}
    >
      <TableCell align="left" width="25%">
        <ListItemText
          style={{
            cursor: "default",
          }}
          primaryTypographyProps={{
            typography: "subtitle2",
            sx: { textTransform: "capitalize" },
          }}
          secondaryTypographyProps={{
            typography: "caption",
          }}
          primary={
            <Box
              key={index}
              component="span"
              sx={{
                color: "text.primary",
              }}
            >
              {row.seller?.name}
            </Box>
          }
          // secondary={
          //   <Box
          //     key={index}
          //     component="span"
          //     sx={{
          //       color: "text.secondary",
          //     }}
          //   >
          //     {row.data.email}
          //   </Box>
          // }
        />
        {/* <Typography variant="subtitle2" noWrap></Typography> */}
      </TableCell>
      <TableCell align="left" width="25%">
        {fDate(row.createdAt)}
      </TableCell>
      <TableCell align="left" width="50%">
        {getStatusLabel(row.stage)}
      </TableCell>
      {/* <TableCell align="left">
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          {row.assignee ? (
            <>
              <Avatar
                {...stringAvatar(
                  `${
                    row.assignee.firstName
                      ? row.assignee.firstName
                      : row.assignee.email
                  }`,
                  {
                    width: 28,
                    height: 28,
                  },
                )}
              />
              {row.assignee.firstName
                ? `${row.assignee.firstName} ${
                    row.assignee.lastName ? row.assignee.lastName[0] : ""
                  }`
                : row.assignee.email}
            </>
          ) : (
            <>Unassigned</>
          )}
        </Box>
      </TableCell>
      <TableCell align="left">
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          {row.salesRep ? (
            <>
              <SupportAgentOutlined />
              {row.salesRep.firstName
                ? `${row.salesRep.firstName} ${
                    row.salesRep.lastName ? row.salesRep.lastName[0] : ""
                  }`
                : row.salesRep.email}
            </>
          ) : (
            <>
              
              Unassigned
            </>
          )}
        </Box>
      </TableCell> */}
      {/* <TableCell align="right" width="25%">
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>
            {row.data.netTerms ? termsFormatter(row.data.netTerms) : ""}
          </div>
          <div>
            {row.data.creditLimit
              ? formatCurrency(Number(row.data.creditLimit), row.data.currency)
              : ""}
          </div>
        </Box>
      </TableCell> */}

      <TableCell align="right">
        {row.stage < 200 && (
          <Button
            variant="contained"
            onClick={() => {
              navigate({
                pathname: "/trade-credit/" + row.id + "/",
                search: queryString.stringify({
                  business_id: row.seller?.id,
                }),
              })
            }}
          >
            Continue
          </Button>
        )}
      </TableCell>
    </TableRow>
  )
}
