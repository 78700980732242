import { Business, EmailPreference, Preferences } from "src/types"
import * as yup from "yup"
import { User } from "../../../types/base.types"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Stack,
  Switch,
} from "@mui/material"
import { useListInternalEmailPreferences } from "src/queries/internal/useListInternalEmailPreferences"
import { useCreateInternalEmailPreferences } from "src/queries/internal/useCreateInternalEmailPreferences"
import { enqueueSnackbar } from "notistack"
import { usePatchInternalEmailPreferences } from "src/queries/internal/usePatchInternalEmailPreferences"
import { Formik } from "formik"

export default function EmailPreferencesDialog({
  open,
  onClose,
  business,
  user,
}: {
  open: boolean
  onClose: () => void
  user: User
  business: Business
}) {
  const { data, isFetching, refetch } = useListInternalEmailPreferences(
    business.id as string,
    user.id,
  )
  const {
    execute: executeCreateEmailPreferences,
    isLoading: isLoadingEmailPreferences,
  } = useCreateInternalEmailPreferences(() => {
    refetch()
  })
  const {
    execute: executePatchEmailPreferences,
    isLoading: isLoadingPatchEmailPreferences,
  } = usePatchInternalEmailPreferences(() => {
    refetch()
  })

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth={"md"} fullWidth>
        <DialogTitle id="alert-dialog-title">User Email Preference</DialogTitle>
        <DialogContent sx={{ p: 2 }}>
          {isFetching && <p>Loading...</p>}
          {!data && !isFetching && (
            <>
              <Button
                variant="contained"
                onClick={() => {
                  executeCreateEmailPreferences(
                    {
                      business: business.id as string,
                      user: user.id,
                    } as EmailPreference,
                    () => {
                      enqueueSnackbar("Email Preferences Created", {
                        variant: "success",
                      })
                    },
                  )
                }}
                disabled={isLoadingEmailPreferences}
              >
                Activate Email Preference
              </Button>
            </>
          )}

          {data && !isFetching && (
            <>
              <Formik
                initialValues={data.configuration as Preferences}
                onSubmit={(values) => {
                  executePatchEmailPreferences(
                    {
                      id: data.id,
                      user: data.user,
                      business: data.business,
                      configuration: values,
                    } as EmailPreference,
                    () => {
                      enqueueSnackbar("Email Preferences Updated", {
                        variant: "success",
                      })
                    },
                  )
                }}
              >
                {(props) => (
                  <FormControl component="fieldset" variant="standard">
                    <Stack spacing={3}>
                      <FormControlLabel
                        control={
                          <Switch
                            size="small"
                            name="applicationApproved"
                            checked={props.values.applicationApproved}
                            onChange={props.handleChange}
                          />
                        }
                        label="Enable Application Approved Emails"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            size="small"
                            name="applicationDeclined"
                            checked={props.values.applicationDeclined}
                            onChange={props.handleChange}
                          />
                        }
                        label="Enable Application Declined Emails"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            size="small"
                            name="applicationUpdated"
                            checked={props.values.applicationUpdated}
                            onChange={props.handleChange}
                          />
                        }
                        label="Enable Application Updated Emails"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            size="small"
                            name="tradeReferenceEmails"
                            checked={props.values.tradeReferenceEmails}
                            onChange={props.handleChange}
                          />
                        }
                        label="Enable Trade Reference Emails"
                      />
                    </Stack>
                    <Stack sx={{ marginY: 2 }}>
                      <Button
                        variant="contained"
                        disabled={isLoadingPatchEmailPreferences}
                        onClick={() => {
                          props.handleSubmit()
                        }}
                      >
                        Edit
                      </Button>
                    </Stack>
                  </FormControl>
                )}
              </Formik>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
