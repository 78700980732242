import { Button, Paper, Typography } from "@mui/material"
import ViewCashApplicationDialog from "../ViewCashApplicationDialog"
import { useState } from "react"

export default function CashApplicationsCard({ id }: { id: string }) {
  const [openCashDialog, setOpenCashDialog] = useState(false)
  return (
    <>
      <Paper elevation={3} sx={{ marginY: 2, p: 2 }}>
        <Typography variant="h6" sx={{ marginBottom: 1 }}>
          Cash Applications
        </Typography>
        <Button
          variant="contained"
          onClick={() => {
            setOpenCashDialog(true)
          }}
        >
          View Cash Applications
        </Button>
      </Paper>
      {openCashDialog && (
        <ViewCashApplicationDialog
          open={openCashDialog}
          onClose={() => {
            setOpenCashDialog(false)
          }}
          businessId={id}
        />
      )}
    </>
  )
}
