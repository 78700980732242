import { useState } from "react"
import { LoadingButton } from "@mui/lab"
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Stack,
  Grid,
  TextField,
} from "@mui/material"
import { TradeReference } from "src/types"
import { useSnackbar } from "notistack"
import { useSendTradeReferenceRequest } from "src/queries/credit/useSendTradeReferenceRequest"

export default ({
  reference,
  onClose,
  open,
}: {
  reference: TradeReference
  onClose: () => void
  open: boolean
}) => {
  const { enqueueSnackbar } = useSnackbar()

  const { execute: sendEmail, isLoading } = useSendTradeReferenceRequest(
    reference.id,
  )

  const [notes, setNotes] = useState("")

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"sm"}>
      <DialogTitle>Request for Reference</DialogTitle>
      <Divider style={{}} />
      <DialogContent>
        <Stack
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Typography align="justify">
            This feature allows you to send a request for reference to{" "}
            <b>{reference.email}</b>. They will receive an email with a link to
            upload the requested information. Please note that the recepient
            might have already received an automated request for reference
            during the submission of this application, depending on your NetNow
            configuration.
          </Typography>

          <Grid container>
            <Grid item md={12}>
              <Typography>Notes</Typography>
              <TextField
                type="text"
                fullWidth
                multiline
                placeholder="Enter any additional instructions here. This will be included in the email."
                value={notes}
                onChange={(e) => {
                  setNotes(e.target.value)
                }}
              />
            </Grid>
          </Grid>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <LoadingButton
          type="submit"
          variant="contained"
          loading={isLoading}
          onClick={() => {
            sendEmail({ notes }, () => {
              enqueueSnackbar("Email sent successfully", { variant: "success" })
              onClose()
            })
          }}
        >
          Send
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
