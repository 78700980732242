import { TextField } from "@mui/material"
import { Box } from "@mui/system"
import { CreditApplication, TradeReference } from "src/types"
import ReactPhoneInput from "react-phone-input-material-ui"
import { FormikErrors } from "formik"

export default ({
  data,
  onChange,
  errors,
}: {
  data: TradeReference
  errors: FormikErrors<TradeReference>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (key: string, value: any) => void
}) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          gap: "5%",
        }}
      >
        <TextField
          id={"reference-name"}
          margin="normal"
          fullWidth
          label="Business Name"
          value={data.name}
          onChange={(event) => {
            onChange("name", event.target.value)
          }}
          error={!!errors && Boolean(errors["name"])}
        />
        <TextField
          id={"reference-account"}
          margin="normal"
          fullWidth
          label="Account # (Optional)"
          value={data.accountNumber}
          onChange={(event) => {
            onChange("accountNumber", event.target.value)
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          gap: "5%",
        }}
      >
        <TextField
          id={"reference-email"}
          margin="normal"
          fullWidth
          label="Credit/AR Email"
          variant="outlined"
          value={data.email}
          required
          onChange={(event) => {
            onChange("email", event.target.value)
          }}
          error={!!errors && Boolean(errors.email)}
          helperText={!!errors && "email" in errors ? errors.email : ""}
        />
        <ReactPhoneInput
          value={data.phoneNumber}
          country={"us"}
          onChange={(event) => {
            onChange("phoneNumber", "+" + event)
          }}
          countryCodeEditable={false}
          label="Credit/AR Phone Number"
          component={TextField}
          containerStyle={{ width: "100%", marginTop: "8px" }}
          inputProps={{
            id: "reference-phone-number",
            style: { marginTop: "8px" },
            required: true,
            error: !!errors && Boolean(errors.phoneNumber),
          }}
        />
        <ReactPhoneInput
          value={data.faxNumber}
          country={"us"}
          onChange={(event) => {
            onChange("faxNumber", "+" + event)
          }}
          countryCodeEditable={false}
          label="Credit/AR Fax Number"
          component={TextField}
          containerStyle={{ width: "100%", marginTop: "8px" }}
          inputProps={{
            id: "reference-fax-number",
            style: { marginTop: "8px" },
            required: true,
            error: !!errors && Boolean(errors.faxNumber),
          }}
        />
      </Box>
    </>
  )
}
