import { QueryClient } from "react-query"
import { useTypedMutation } from "../useTypedMutation"
import { Business } from "src/types"

export function usePostInternalBusiness(onSuccess?: () => void) {
  const post = useTypedMutation<Business>("post/internal/business", onSuccess)
  const execute = (body: Business, onSuccess: () => void) => {
    post.mutate(
      {
        method: "post",
        endpoint: "/internal/business/",
        body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: ["/internal/business".replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
      },
    )
  }
  return { ...post, execute }
}
