import { Box, Button, IconButton, MenuItem, Stack } from "@mui/material"
import Paper from "@mui/material/Paper"
import ListItemText from "@mui/material/ListItemText"
import { fileThumb } from "src/utils/files"
import CustomPopover, { usePopover } from "src/components/custom-popover"
import Iconify from "src/components/iconify"
import useResponsive from "src/hooks/useResponsive"
import { RequestedCustomDocument } from "src/types"
import Label from "src/components/label"
import { DownloadOutlined } from "@mui/icons-material"

export default ({
  item,
  onUpload,
  onDownload,
  disableUpload = false,
}: {
  onUpload: () => void
  onDownload: () => void
  item: RequestedCustomDocument
  disableUpload?: boolean
}) => {
  const { open, onOpen, onClose } = usePopover()
  const smUp = useResponsive("up", "sm")
  const renderAction = (
    <Box
      sx={{
        top: 0,
        right: 8,
        position: "absolute",
        ...(smUp && {
          flexShrink: 0,
          position: "unset",
        }),
      }}
    >
      <IconButton color={open ? "inherit" : "default"} onClick={onOpen}>
        <Iconify icon="eva:more-vertical-fill" />
      </IconButton>
    </Box>
  )

  const getLabel = (item: RequestedCustomDocument) => {
    return (
      <Label color={item.completed ? "success" : "warning"}>
        {item.completed ? "Completed" : "Pending"}
      </Label>
    )
  }

  const renderText = (item: RequestedCustomDocument) => {
    return (
      <ListItemText
        primary={item ? item.document.name : ""}
        secondary={getLabel(item)}
        primaryTypographyProps={{
          noWrap: true,
          typography: "subtitle2",
        }}
      />
    )
  }

  return (
    <>
      <Stack
        component={Paper}
        variant="outlined"
        spacing={1}
        direction={{ xs: "column", sm: "row" }}
        alignItems={{ xs: "unset", sm: "center" }}
        sx={{
          borderRadius: 2,
          bgcolor: "unset",
          cursor: "pointer",
          position: "relative",
          p: { xs: 2.5, sm: 2 },
          marginBottom: "1rem",
          "&:hover": {
            bgcolor: "background.paper",
            boxShadow: (theme) => theme.shadows[20],
          },
        }}
      >
        <Box
          component="img"
          src={fileThumb(item.file)}
          sx={{
            flexShrink: 0,
            width: 36,
            height: 36,
            mr: 1,
          }}
        />
        {renderText(item)}
        {item.document?.file && (
          <Button
            onClick={() => {
              window.open(item.document.file)
            }}
          >
            Template
          </Button>
        )}
        {!disableUpload && (
          <Button
            variant="contained"
            onClick={() => {
              onUpload()
            }}
          >
            {item.completed ? "Upload And Replace" : "Upload"}
          </Button>
        )}

        {item.completed && renderAction}
      </Stack>
      <CustomPopover open={open} onClose={onClose} arrow="right-bottom">
        <MenuItem
          onClick={() => {
            onDownload()
            onClose()
          }}
        >
          <DownloadOutlined />
          Download
        </MenuItem>
      </CustomPopover>
    </>
  )
}
