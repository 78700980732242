import { QueryClient, UseMutationResult } from "react-query"
import { useTypedMutation } from "../useTypedMutation"
import { MutationVariables, Business } from "../../types"

type ExecuteType = {
  execute: (
    id: string,
    body: Pick<Business, "logo">,
    onSuccess: () => void,
  ) => void
}
export type UsePatchBusinessType = UseMutationResult<
  unknown,
  unknown,
  MutationVariables<Pick<Business, "logo"> | Pick<Business, "terms">>,
  unknown
> &
  ExecuteType

export function usePatchBusinessBranding(onSuccess?: (data: string) => void) {
  const ENDPOINT = "/business/"
  const patchBusiness = useTypedMutation<FormData>("patch/business", onSuccess)

  const execute = (
    id: string,
    logo?: File,
    terms?: File,
    onSuccess?: () => void,
  ) => {
    let formData = null

    if (logo) {
      formData = new FormData()
      formData.append("logo", logo)
    } else if (terms) {
      formData = new FormData()
      formData.append("terms", terms)
    }

    if (formData) {
      patchBusiness.mutate(
        {
          method: "patch",
          endpoint: ENDPOINT + id + "/",
          body: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
        {
          onSuccess: async () => {
            await new QueryClient().invalidateQueries({
              queryKey: [ENDPOINT.replaceAll("/", "")],
            })
            if (onSuccess) onSuccess()
          },
        },
      )
    }
  }
  return { ...patchBusiness, execute }
}
