import { Form } from "formik"

import QontoStepper from "src/sections/@dashboard/components/QontoStepper"

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
  useTheme,
} from "@mui/material"

import TradeReferencesSection from "src/sections/@dashboard/intake_sections/TradeReferencesSection"
import { getTradeEnabledSchema } from "src/sections/@dashboard/intake_sections/schemas/TradeReferenceSectionSchema"
import BankReferenceSection from "src/sections/@dashboard/intake_sections/BankReferenceSection"
import { getBankEnabledSchema } from "src/sections/@dashboard/intake_sections/schemas/BankReferenceSectionSchema"

import UserRegistrationSection from "src/sections/@dashboard/intake_sections/UserRegistrationSection"
import { getUserEnabledSchema } from "src/sections/@dashboard/intake_sections/schemas/UserRegistrationSectionSchema"
import OwnersSection from "src/sections/@dashboard/intake_sections/OwnersSection"
import { getOwnersEnabledSchema } from "src/sections/@dashboard/intake_sections/schemas/OwnersSectionSchema"
import CompanyDetailsSection from "src/sections/@dashboard/intake_sections/CompanyDetailsSection"
import { getCompanyDetailsEnabledSchema } from "src/sections/@dashboard/intake_sections/schemas/CompanyDetailsSectionSchema"

import CustomQuestionsSection from "src/sections/@dashboard/intake_sections/CustomQuestionsSection"
import PersonalGuarantySection from "src/sections/@dashboard/intake_sections/PersonalGuarantySection"
import { getPersonalGuarantyEnabledSchema } from "src/sections/@dashboard/intake_sections/schemas/PersonalGuarantySectionSchema"

import { useParams } from "react-router-dom"
import Complete from "../../intake_sections/Complete"
import { FormComponent } from "src/types/credit/FormComponent"
import {
  PAGE_LABEL_BANK_REFERENCE,
  PAGE_LABEL_BUSINESS_DETAILS,
  PAGE_LABEL_OWNERS,
  PAGE_LABEL_PERSONAL_GUARANTY,
  PAGE_LABEL_TRADE_REFERENCES,
  PAGE_LABEL_USER_DETAILS,
  PAGE_LABEL_ADDITIONAL_QUESTIONS,
} from "../../intake_sections/constants"

import { LoadingButton } from "@mui/lab"
import { useState } from "react"
import * as yup from "yup"
import { getAdditionalQuestionsSchema } from "../../intake_sections/schemas/CustomQuestionsSectionsSchema"

export default ({
  steps,
  activeStep,
  handleBack,
  props,
  onContinue,
  onSkip,
  onDataFieldUpdated,
  onCustomFieldUpdated,
  onApplicationFieldUpdated,
  application,
  template,
  completionError,
}: FormComponent) => {
  const theme = useTheme()
  const params = useParams()
  const { id } = params

  const [skipPage, setSkipPage] = useState<
    | {
        label: string
        schema: yup.AnyObjectSchema
      }
    | undefined
  >(undefined)
  const [skipReason, setSkipReason] = useState("")

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        padding: "5%",
        maxHeight: "100vh",
        overflow: "auto",
      }}
    >
      <Box
        style={{ margin: "0 5% 32px 5%" }}
        display={{
          xs: "none",
          sm: "none",
          md: "block",
          large: "block",
          xlarge: "block",
        }}
      >
        <QontoStepper
          steps={steps}
          data={props.values.data}
          activeStep={activeStep}
          handleBack={handleBack}
          template={template}
        />
      </Box>
      <Box
        style={{
          textAlign: "left",
          padding: "32px 10% 32px 10%",
          border: "solid",
          borderWidth: "1px",
          borderRadius: "16px",
          borderColor: theme.palette.primary.main,
        }}
      >
        <Form>
          {completionError && (
            <>
              <Typography
                component="h1"
                variant="h5"
                style={{ marginTop: "8px" }}
              >
                Error!
              </Typography>
              <Typography variant="body2" style={{ margin: "8px 0 8px 0" }}>
                There was an error while completing your application. Please
                refresh this page. If the error persists, please contact us at
                info@netnow.io with the following information.
              </Typography>
              <Divider style={{ margin: "16px 0 16px 0" }} />
              <Typography color="error">{completionError.message}</Typography>
              <Typography color="error">Application ID: {id}</Typography>
              <Typography color="error">
                {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  completionError.response?.data?.error
                }
              </Typography>
            </>
          )}
          {!completionError && activeStep == 99 && <Complete />}
          {!completionError &&
            steps[activeStep] &&
            steps[activeStep].label.toLowerCase().includes("user") && (
              <UserRegistrationSection
                application={application}
                data={props.values.data}
                errors={props.errors.data}
                onChange={(key, val) => {
                  onDataFieldUpdated(key, val, PAGE_LABEL_USER_DETAILS)
                }}
                onCustomChange={(
                  key: string,
                  value: string | File | undefined,
                ) => {
                  onCustomFieldUpdated(key, value)
                }}
                onContinue={(onFormValidationError) => {
                  onContinue(
                    getUserEnabledSchema(template, props.values.data),
                    () => {
                      onFormValidationError()
                      return undefined
                    },
                  )
                }}
                template={template}
              />
            )}
          {!completionError &&
            steps[activeStep] &&
            steps[activeStep].label.toLowerCase().includes("business") && (
              <CompanyDetailsSection
                application={application}
                topLevelValues={props.values}
                data={props.values.data}
                errors={{
                  ...props.errors.data,
                  salesRep: props.errors.salesRep,
                }}
                onChange={(key, val) => {
                  onDataFieldUpdated(key, val, PAGE_LABEL_BUSINESS_DETAILS)
                }}
                onCustomChange={function (
                  key: string,
                  value: string | File | undefined,
                ): void {
                  onCustomFieldUpdated(key, value)
                }}
                onApplicationFieldUpdated={onApplicationFieldUpdated}
                onContinue={() => {
                  onContinue(
                    getCompanyDetailsEnabledSchema(template, props.values.data),
                  )
                }}
                template={template}
              />
            )}
          {!completionError &&
            steps[activeStep] &&
            steps[activeStep].label.toLowerCase().includes("owners") && (
              <OwnersSection
                data={props.values.data}
                errors={props.errors.data}
                onChange={(key, val) => {
                  onDataFieldUpdated(key, val, PAGE_LABEL_OWNERS)
                }}
                onSkip={() => {
                  setSkipPage({
                    label: PAGE_LABEL_OWNERS,
                    schema: getOwnersEnabledSchema(template, props.values.data),
                  })
                  setSkipReason("")
                }}
                onCustomChange={(
                  key: string,
                  value: string | File | undefined,
                ) => {
                  onCustomFieldUpdated(key, value, PAGE_LABEL_BANK_REFERENCE)
                }}
                onContinue={() => {
                  onContinue(
                    getOwnersEnabledSchema(template, props.values.data),
                    undefined,
                    undefined,
                    PAGE_LABEL_OWNERS,
                  )
                }}
              />
            )}
          {!completionError &&
            steps[activeStep] &&
            steps[activeStep].label.toLowerCase().includes("trade") && (
              <TradeReferencesSection
                size={3}
                data={props.values.data}
                errors={props.errors.data}
                onChange={(key, val) => {
                  onDataFieldUpdated(key, val, PAGE_LABEL_TRADE_REFERENCES)
                }}
                onSkip={() => {
                  setSkipPage({
                    label: PAGE_LABEL_TRADE_REFERENCES,
                    schema: getTradeEnabledSchema(template, props.values.data),
                  })
                  setSkipReason("")
                }}
                onContinue={() => {
                  onContinue(
                    getTradeEnabledSchema(template, props.values.data),
                    undefined,
                    undefined,
                    PAGE_LABEL_TRADE_REFERENCES,
                  )
                }}
                template={template}
              />
            )}
          {!completionError &&
            steps[activeStep] &&
            steps[activeStep].label.toLowerCase().includes("bank") && (
              <BankReferenceSection
                application={application}
                data={props.values.data}
                errors={props.errors.data}
                onChange={(key, val) => {
                  onDataFieldUpdated(key, val, PAGE_LABEL_BANK_REFERENCE)
                }}
                onSkip={() => {
                  setSkipPage({
                    label: PAGE_LABEL_BANK_REFERENCE,
                    schema: getBankEnabledSchema(template, props.values.data),
                  })
                  setSkipReason("")
                }}
                onCustomChange={(
                  key: string,
                  value: string | File | undefined,
                ) => {
                  onCustomFieldUpdated(key, value, PAGE_LABEL_BANK_REFERENCE)
                }}
                onContinue={() => {
                  onContinue(
                    getBankEnabledSchema(template, props.values.data),
                    undefined,
                    undefined,
                    PAGE_LABEL_BANK_REFERENCE,
                  )
                }}
                template={template}
              />
            )}

          {!completionError &&
            steps[activeStep] &&
            steps[activeStep].label.toLowerCase().includes("guaranty") && (
              <PersonalGuarantySection
                data={props.values.data}
                errors={props.errors.data}
                onChange={(key, val) => {
                  onDataFieldUpdated(key, val, PAGE_LABEL_PERSONAL_GUARANTY)
                }}
                onSkip={() => {
                  setSkipPage({
                    label: PAGE_LABEL_PERSONAL_GUARANTY,
                    schema: getPersonalGuarantyEnabledSchema(
                      template,
                      props.values.data,
                    ),
                  })
                  setSkipReason("")
                }}
                onContinue={() => {
                  onContinue(
                    getPersonalGuarantyEnabledSchema(
                      template,
                      props.values.data,
                    ),
                    undefined,
                    undefined,
                    PAGE_LABEL_PERSONAL_GUARANTY,
                  )
                }}
                template={template}
              />
            )}

          {!completionError &&
            steps[activeStep] &&
            steps[activeStep].label.toLowerCase().includes("additional") && (
              <CustomQuestionsSection
                application={application}
                errors={props.errors.data}
                data={props.values.data}
                onChange={function (
                  key: string,
                  value: string | File | undefined,
                ): void {
                  onCustomFieldUpdated(key, value)
                }}
                onContinue={function (): void {
                  onContinue(
                    getAdditionalQuestionsSchema(template, props.values.data),
                    undefined,
                    undefined,
                    PAGE_LABEL_ADDITIONAL_QUESTIONS,
                  )
                }}
              />
            )}
          {skipPage && (
            <Dialog
              open={!!skipPage}
              onClose={() => {
                setSkipPage(undefined)
              }}
            >
              <DialogTitle>
                Tell us why you would like to skip this page
              </DialogTitle>
              <DialogContent>
                <Typography>
                  Please note that skipping this page may result in delays or
                  affect the amount of credit you are approved for.
                </Typography>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Reason for skipping"
                  multiline
                  type="text"
                  fullWidth
                  variant="standard"
                  value={skipReason}
                  onChange={(e) => {
                    setSkipReason(e.target.value)
                  }}
                />
              </DialogContent>

              <DialogActions>
                <Button
                  onClick={() => {
                    setSkipPage(undefined)
                    setSkipReason("")
                  }}
                >
                  Close
                </Button>
                <LoadingButton
                  onClick={() => {
                    onSkip(skipPage.label, skipReason, skipPage.schema)
                    setSkipPage(undefined)
                    setSkipReason("")
                  }}
                >
                  Skip
                </LoadingButton>
              </DialogActions>
            </Dialog>
          )}
        </Form>
      </Box>
    </Box>
  )
}
