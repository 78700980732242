import * as yup from "yup"
import {
  addConditionalValidationstoCustomSchema,
  getAdditionalQuestionSchemaForPage,
  hasConditions,
  hasPassedConditions,
} from "../../utils"
import { Application, ApplicationTemplate, CreditApplication } from "src/types"
import {
  BANK_REFERENCE_CONFIG_ROUTING_NUMBER,
  PAGE_LABEL_BANK_REFERENCE,
} from "../constants"
import { isPageEnabled, isPageRequired } from "../template_helpers"

export const BANK_REFERENCES_YUP_SCHEMA = yup.object({
  plaidPublicToken: yup.string(),
  bankName: yup.string().when(["plaidPublicToken", "plaidData"], {
    is: (fieldTwo: string, fieldThree: string) =>
      (!fieldTwo || fieldTwo.length === 0) &&
      (!fieldThree || fieldThree.length === 0),
    then: yup.string().required("Bank name is required"),
  }),
  bankAccountNumber: yup.string().when(["plaidPublicToken", "plaidData"], {
    is: (fieldTwo: string, fieldThree: string) =>
      (!fieldTwo || fieldTwo.length === 0) &&
      (!fieldThree || fieldThree.length === 0),
    then: yup
      .string()
      .required("Account number is required")
      .matches(/^[0-9]+$/, "Must be only digits"),
  }),

  bankInstitutionNumber: yup
    .string()
    .when(["plaidPublicToken", "plaidData", "businessCountry"], {
      is: (fieldTwo: string, fieldThree: string, fieldFour: string) =>
        fieldFour === "Canada" &&
        (!fieldTwo || fieldTwo.length === 0) &&
        (!fieldThree || fieldThree.length === 0),
      then: yup
        .string()
        .required("Institution number is required")
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(3, "Must be exactly 3 digits")
        .max(3, "Must be exactly 3 digits"),
    }),
  bankTransitNumber: yup
    .string()
    .when(["plaidPublicToken", "plaidData", "businessCountry"], {
      is: (fieldTwo: string, fieldThree: string, fieldFour: string) =>
        fieldFour === "Canada" &&
        (!fieldTwo || fieldTwo.length === 0) &&
        (!fieldThree || fieldThree.length === 0),
      then: yup.string().required("Transit number is required"),
    })
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(5, "Must be exactly 5 digits")
    .max(5, "Must be exactly 5 digits"),
  bankContactName: yup.string().when(["plaidPublicToken", "plaidData"], {
    is: (fieldTwo: string, fieldThree: string) =>
      (!fieldTwo || fieldTwo.length === 0) &&
      (!fieldThree || fieldThree.length === 0),
    then: yup.string().required("Bank contact name is required"),
  }),
  bankContactEmail: yup
    .string()
    .email()
    .when(["plaidPublicToken", "plaidData"], {
      is: (fieldTwo: string, fieldThree: string) =>
        (!fieldTwo || fieldTwo.length === 0) &&
        (!fieldThree || fieldThree.length === 0),
      then: yup.string().email().required("Bank contact email is required"),
    }),
  bankContactPhoneNumber: yup
    .string()
    .matches(/\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/, "Phone number is not valid")
    .when(["plaidPublicToken", "plaidData"], {
      is: (fieldTwo: string, fieldThree: string) =>
        (!fieldTwo || fieldTwo.length === 0) &&
        (!fieldThree || fieldThree.length === 0),
      then: yup
        .string()
        .required("Phone number is required")
        .matches(
          /\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/,
          "Phone number is not valid",
        ),
    }),
})

export const isRoutingEnabled = (
  template: ApplicationTemplate | undefined,
  data: CreditApplication["data"],
) => {
  if (!template) return true

  const config = template?.formTemplate?.pages
    .filter(
      (page) =>
        isPageEnabled(page.label, template, data) &&
        isPageRequired(page.label, template, data),
    )
    .find((tstep) => tstep.label === PAGE_LABEL_BANK_REFERENCE)
    ?.config?.find((c) => c.label === BANK_REFERENCE_CONFIG_ROUTING_NUMBER)

  if (!config) return true

  return Boolean(config.value)
}

export const getBankEnabledSchema = (
  template: ApplicationTemplate,
  data: Application["data"] = {},
): yup.AnyObjectSchema => {
  if (
    isPageEnabled(PAGE_LABEL_BANK_REFERENCE, template, data) &&
    isPageRequired(PAGE_LABEL_BANK_REFERENCE, template, data)
  ) {
    let enabledSchema = yup.object({}).concat(BANK_REFERENCES_YUP_SCHEMA)
    let customSchema = yup.object({})
    if (
      data &&
      (!data.plaidData || data.plaidData.length === 0) &&
      (!data.plaidPublicToken || data.plaidPublicToken.length === 0)
    ) {
      template.customFields
        ?.filter((field) => field.associatedPage === PAGE_LABEL_BANK_REFERENCE)
        .filter((field) => field.required && !!field.id)
        .filter((field) => !hasConditions(field))
        .forEach((field) => {
          if (field.fieldType === "file") {
            customSchema = customSchema.concat(
              yup.object({
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                [field.id!]: yup.mixed().required("Please upload a file"),
              }),
            )
          } else {
            customSchema = customSchema.concat(
              yup.object({
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                [field.id!]: yup
                  .object({
                    response: yup.string().required("Field is required"),
                  })
                  .required("Field is required"),
              }),
            )
          }
        })
    }
    if (
      data &&
      (!data.plaidData || data.plaidData.length === 0) &&
      (!data.plaidPublicToken || data.plaidPublicToken.length === 0)
    ) {
      template.customFields
        ?.filter((field) => field.associatedPage === PAGE_LABEL_BANK_REFERENCE)
        .filter((field) => field.required && !!field.id)
        .filter((field) => hasConditions(field))
        .filter((field) =>
          hasPassedConditions(
            field,
            data["customFields"],
            data,
            PAGE_LABEL_BANK_REFERENCE,
          ),
        )
        .forEach((field) => {
          if (field.fieldType === "file") {
            customSchema = customSchema.concat(
              yup.object({
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                [field.id!]: yup.mixed().required("Please upload a file"),
              }),
            )
          } else {
            customSchema = customSchema.concat(
              yup.object({
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                [field.id!]: yup
                  .object({
                    response: yup.string().required("Field is required"),
                  })
                  .required("Field is required"),
              }),
            )
          }
        })
    }
    enabledSchema = enabledSchema.concat(
      yup.object({
        customFields: customSchema,
      }),
    )
    if (isRoutingEnabled(template, data)) {
      return enabledSchema.concat(
        yup.object({
          bankRoutingNumber: yup
            .string()
            .when(["plaidPublicToken", "plaidData", "businessCountry"], {
              is: (fieldTwo: string, fieldThree: string, fieldFour: string) =>
                fieldFour === "United States" &&
                (!fieldTwo || fieldTwo.length === 0) &&
                (!fieldThree || fieldThree.length === 0),
              then: yup
                .string()
                .required("Routing number is required")
                .matches(/^[0-9]+$/, "Must be only digits")
                .min(9, "Must be exactly 9 digits")
                .max(9, "Must be exactly 9 digits"),
            }),
        }),
      )
    }
  } else if (
    template?.formTemplate?.pages
      .filter((page) => isPageEnabled(page.label, template, data))
      .find((tstep) => tstep.label === PAGE_LABEL_BANK_REFERENCE)
  ) {
    return yup.object({
      plaidPublicToken: yup.string(),
      bankAccountNumber: yup.string().when("plaidPublicToken", {
        is: (fieldTwo: string) => !fieldTwo || fieldTwo.length === 0,
        then: yup
          .string()
          .matches(/^[0-9]+$/, "Must be only digits")
          .when(["bankInstitutionNumber", "bankTransitNumber"], {
            is: (fieldThree: string, fieldFour: string) => {
              return Boolean(fieldThree || fieldFour)
            },
            then: yup
              .string()
              .required(
                "Bank reference is optional. However, if you decide to provide it, a valid account number must be entered",
              ),
            // .matches(/^[0-9]+$/, "Must be only digits"),
          }),
      }),
      bankRoutingNumber: yup
        .string()
        .when(["plaidPublicToken", "businessCountry"], {
          is: (fieldTwo: string, fieldThree: string) =>
            fieldThree === "United States" &&
            (!fieldTwo || fieldTwo.length === 0),
          then: yup
            .string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .test(
              "len",
              "Bank reference is optional. However, if you decide to provide it, routing number must be exactly 9 digits",
              (val) => {
                if (val === undefined) {
                  return true
                }
                return val.length === 0 || val.length === 9
              },
            ),
          // .when(["bankAccountNumber"], {
          //   is: (fieldTwo: string) => {
          //     return Boolean(fieldTwo)
          //   },
          //   then: yup
          //     .string()
          //     .required(
          //       "Bank reference is optional. However, if you decide to provide it, routing number must be exactly 9 digits",
          //     ),
          // }),
        }),
      bankInstitutionNumber: yup
        .string()
        .when(["plaidPublicToken", "businessCountry"], {
          is: (fieldTwo: string, fieldThree: string) =>
            fieldThree === "Canada" && (!fieldTwo || fieldTwo.length === 0),
          then: yup
            .string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .test(
              "len",
              "Bank reference is optional. However, if you decide to provide it, institution number must be exactly 3 digits",
              (val) => {
                if (val === undefined) {
                  return true
                }
                return val.length === 0 || val.length === 3
              },
            )
            .when(["bankAccountNumber", "bankTransitNumber"], {
              is: (fieldTwo: string, fieldThree: string) => {
                return Boolean(fieldTwo || fieldThree)
              },
              then: yup
                .string()
                .required(
                  "Bank reference is optional. However, if you decide to provide it, institution number must be exactly 3 digits",
                ),
            }),
        }),
      bankTransitNumber: yup
        .string()
        .when(["plaidPublicToken", "businessCountry"], {
          is: (fieldTwo: string, fieldThree: string) =>
            fieldThree === "Canada" && (!fieldTwo || fieldTwo.length === 0),
          then: yup
            .string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .test(
              "len",
              "Bank reference is optional. However, if you decide to provide it, transit number must be exactly 5 digits",
              (val) => {
                if (val === undefined) {
                  return true
                }
                return val.length === 0 || val.length === 5
              },
            )
            .when(["bankAccountNumber", "bankInstitutionNumber"], {
              is: (fieldTwo: string, fieldThree: string) => {
                return Boolean(fieldTwo || fieldThree)
              },
              then: yup
                .string()
                .required(
                  "Bank reference is optional. However, if you decide to provide it, transit number must be exactly 5 digits",
                ),
            }),
        }),
    })
  }
  return yup.object({})
}
