import { useQuery } from "react-query"
import { User } from "../../types"

export function useInternalSelectableUserInvite(businessId: string) {
  return useQuery<User[]>(
    "/internal/selectable-user-invite/?business_id=" + businessId,
    {
      retry: (failureCount, error) => {
        if (failureCount < 1) {
          return true
        }
        // do not retry on 404
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (error as any)?.response?.status !== 404
      },
    },
  )
}
