import { useNavigate } from "react-router-dom"
import { AppState, Auth0Provider, User } from "@auth0/auth0-react"

type Props = {
  children: JSX.Element
}

export default ({ children, ...props }: Props) => {
  const navigate = useNavigate()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onRedirectCallback = (appState?: AppState, _user?: User) => {
    if (appState && appState.returnTo) {
      const split = appState.returnTo?.split("?")
      const params = appState?.params ? appState?.params.split("?") : []
      let search = split.length > 1 ? split[1] : ""
      if (params.length > 1) {
        search = search + params[1]
      }
      navigate({
        pathname: split[0],
        search,
      })
    } else {
      navigate(window.location.pathname)
    }
  }

  return (
    <Auth0Provider
      onRedirectCallback={onRedirectCallback}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      domain={process.env.REACT_APP_AUTH0_DOMAIN!}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
      authorizationParams={{
        ui_locales:
          // if hostname is XX.my.netnow.io
          ["fr", "es"].includes(window.location.hostname.split(".")[0])
            ? window.location.hostname.split(".")[0]
            : "en",
        redirect_uri: window.location.pathname.includes("/trade-credit/base")
          ? window.location.origin + window.location.pathname
          : window.location.origin,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      }}
      cacheLocation={
        ["development", "test"].includes(process.env.NODE_ENV)
          ? "localstorage"
          : "memory"
      }
      {...props}
    >
      {children}
    </Auth0Provider>
  )
}
