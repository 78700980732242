import {
  Stack,
  Container,
  Paper,
  Card,
  CardHeader,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Tooltip,
  TextField,
} from "@mui/material"
import { useListInternalBusiness } from "src/queries/internal/useListInternalBusiness"
import CancelIcon from "@mui/icons-material/Cancel"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { TableHeadCustom } from "src/components/table"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import { Add } from "@mui/icons-material"
import Label from "src/components/label"
import AddBusinessDialog from "./components/AddBusinessDialog"
import AssignOnboardingTaskDialog from "./components/AssignOnboardingTaskDialog"
import { ListBusiness } from "src/types"

export default function ClientAccount() {
  const { data, refetch, isFetching } = useListInternalBusiness()
  const [openBusiness, setOpenBusiness] = useState(false)
  const navigate = useNavigate()
  const [searchQuery, setSearchQuery] = useState<string | null>(null)
  const TABLE_HEAD = [
    { id: "name", label: "Business Name", align: "left" },
    { id: "email", label: "Email", align: "left" },
    { id: "country", label: "Country", align: "left" },
    { id: "language", label: "Language", align: "left" },
    { id: "assignee", label: "Onboarding Assignee", align: "left" },
    { id: "status", label: "Status", align: "left" },
    { id: "action", label: "Action", align: "left" },
  ]
  const [openAssignOnboardingTask, setOpenAssignOnboardingTask] =
    useState(false)
  const [currentBusiness, setCurrentBusiness] = useState<
    ListBusiness | undefined
  >(undefined)

  const filterQuery = (data: ListBusiness[]) => {
    if (searchQuery) {
      return data.filter((business) => {
        return (
          (business.name &&
            business.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (business.email &&
            business.email.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (business.country &&
            business.country
              .toLowerCase()
              .includes(searchQuery.toLowerCase())) ||
          (business.language &&
            business.language.toLowerCase().includes(searchQuery.toLowerCase()))
        )
      })
    }
    return data
  }
  return (
    <>
      <Container sx={{ my: 10 }}>
        <Stack spacing={1} sx={{ p: 1 }} direction="row" alignItems="flex-end">
          <Button
            variant="contained"
            onClick={() => {
              setOpenBusiness(true)
            }}
          >
            <Add /> Add Business
          </Button>
        </Stack>
        {!isFetching && (
          <Stack spacing={3}>
            <Paper variant="outlined">
              <Stack
                spacing={3}
                direction="row"
                alignItems="center"
                justifyContent="center"
                flexWrap="wrap"
              >
                <Card sx={{ width: 1 }}>
                  <CardHeader title="Client Account" />
                  <Stack sx={{ marginX: 2 }}>
                    <TextField
                      label="Search"
                      margin="normal"
                      fullWidth
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </Stack>
                  <TableContainer sx={{ mt: 3, overflow: "unset" }}>
                    <Table sx={{ minWidth: 800 }}>
                      <TableHeadCustom headLabel={TABLE_HEAD} />

                      <TableBody>
                        {data &&
                          filterQuery(data).map((row) => (
                            <TableRow key={row.id}>
                              <TableCell align="left">{row.name}</TableCell>
                              <TableCell align="left">{row.email}</TableCell>
                              <TableCell align="left">{row.country}</TableCell>
                              <TableCell align="left">{row.language}</TableCell>
                              <TableCell align="left">
                                {row.onboardingTaskAssignee && (
                                  <Tooltip
                                    title={
                                      row.onboardingTaskNotes
                                        ? row.onboardingTaskNotes
                                        : "No Task Notes"
                                    }
                                    placement="bottom"
                                  >
                                    <Label variant="outlined" sx={{ m: 0.3 }}>
                                      {row.onboardingTaskAssignee}
                                    </Label>
                                  </Tooltip>
                                )}
                              </TableCell>
                              <TableCell align="left">
                                {row.creditApplicationOnboardingComplete ? (
                                  <Label variant="outlined" sx={{ m: 0.3 }}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                    Credit Application
                                  </Label>
                                ) : (
                                  <Label variant="filled" sx={{ m: 0.3 }}>
                                    <CancelIcon sx={{ color: "red" }} /> Credit
                                    Application
                                  </Label>
                                )}
                                {row.cashApplicationOnboardingComplete && (
                                  <Label variant="outlined" sx={{ m: 0.3 }}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                    Cash Application
                                  </Label>
                                )}
                                {row.personalGuaranteeOnboardingComplete && (
                                  <Label variant="outlined" sx={{ m: 0.3 }}>
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                    Personal Guarantee
                                  </Label>
                                )}
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  variant="contained"
                                  onClick={() => {
                                    navigate(row.id)
                                  }}
                                >
                                  View
                                </Button>
                                <Button
                                  sx={{ mt: 2 }}
                                  variant="outlined"
                                  onClick={() => {
                                    setCurrentBusiness(row)
                                    setOpenAssignOnboardingTask(true)
                                  }}
                                >
                                  Assign
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Card>
              </Stack>
            </Paper>
          </Stack>
        )}
        <AddBusinessDialog
          open={openBusiness}
          onClose={() => {
            setOpenBusiness(false)
          }}
          refetch={refetch}
        />
        {currentBusiness && currentBusiness.id && (
          <AssignOnboardingTaskDialog
            open={openAssignOnboardingTask}
            onClose={() => {
              setOpenAssignOnboardingTask(false)
            }}
            businessId={currentBusiness.id}
            refetch={refetch}
          />
        )}
      </Container>
    </>
  )
}
