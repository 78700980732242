import { QueryClient } from "react-query"
import { Business, CreditApplication } from "../../types"
import { useTypedMutation } from "../useTypedMutation"

export function usePostBuyerApplication(
  onSuccess?: (data: CreditApplication) => void,
) {
  const ENDPOINT = "/application/base/save/"
  const post = useTypedMutation<{
    data: {
      [key: string]: any
    }
    seller?: string | Business
  }>("post/application/base/save", onSuccess)

  const execute = (
    body: {
      data: {
        [key: string]: any
      }
      seller?: string | Business
    },
    onSuccess?: () => void,
    onError?: () => void,
  ) => {
    post.mutate(
      {
        endpoint: ENDPOINT,
        body,
        anonymous: true,
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
          // await new QueryClient().invalidateQueries({
          //   queryKey: `/application/${body.id}`,
          // })
          if (onSuccess) onSuccess()
        },
        onError,
      },
    )
  }
  return { ...post, execute }
}
