import { QueryClient } from "react-query"
import { CreditApplication, CustomFieldResponse } from "../../types"
import { useTypedMutation } from "../useTypedMutation"
import { AxiosError } from "axios"
import queryString from "query-string"

export function usePatchApplication(
  onSuccess?: (data: CreditApplication) => void,
  anonymous = false,
) {
  const ENDPOINT = "/application/"
  const patchCreditApplication = useTypedMutation<FormData>(
    "patch/application",
    onSuccess,
  )

  const execute = (
    id: string,
    body: Partial<CreditApplication>,
    onSuccess?: () => void,
    onError?: (error: AxiosError) => void,
    queryParams: Record<string, string> = { notifyBuyer: "0" },
  ) => {
    if (body.seller) {
      delete body.seller
    }
    const formData = new FormData()
    for (const item of Object.keys(body)) {
      if (item === "files" && body["files"]) {
        body["files"].forEach((file, index) => {
          if (file instanceof File) {
            formData.append("file" + index, file as File)
          } else {
            formData.append("file" + index, JSON.stringify(file))
          }
        })
      } else if (item.includes("data")) {
        const data = body["data"]
        if (data) {
          if (data["customFields"]) {
            // const existingCustomResponses = new Array<CustomFieldResponse>()
            const customFields = data[
              "customFields"
            ] as Array<CustomFieldResponse>
            Object.entries(customFields).forEach(([key, value]) => {
              if (value.field.fieldType === "file") {
                formData.append(key, value.file as File)
              } else {
                formData.append(key, value.response || "")
              }
            })
          }
          delete data["customFields"]
          if (
            data["customResponsesToBeDeleted"] &&
            Object.keys(data["customResponsesToBeDeleted"]).length > 0
          ) {
            if (typeof data["customResponsesToBeDeleted"] != "string") {
              data["customResponsesToBeDeleted"] = JSON.stringify(
                data["customResponsesToBeDeleted"],
              )
            }
          } else if (data["customResponsesToBeDeleted"]) {
            delete data["customResponsesToBeDeleted"]
          }
          formData.append(item, JSON.stringify(body["data"]))
        }
      } else if (item.includes("Date")) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formData.append(
          item,
          new Date(body[item as keyof CreditApplication] as Date).toISOString(),
        )
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formData.append(item, body[item as keyof CreditApplication])
      }
    }

    patchCreditApplication.mutate(
      {
        method: "patch",
        endpoint: ENDPOINT + id + "/?" + queryString.stringify(queryParams),
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        anonymous,
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
        onError: (error) => {
          if (onError) onError(error as AxiosError)
        },
      },
    )
  }
  return { ...patchCreditApplication, execute }
}
