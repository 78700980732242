import { Box, Typography, Divider, TextField, Button } from "@mui/material"

import { CreditApplication } from "src/types"
import { ArrowForward } from "@mui/icons-material"
import ReactPhoneInput from "react-phone-input-material-ui"
import Address from "../components/Address"
import { useApplicationTemplate } from "src/queries/credit/useApplicationTemplate"
import { useLocation, useParams } from "react-router-dom"
import { useCreditApplication } from "src/queries/credit/useCreditApplication"
import { OWNER_CONFIGS, PAGE_LABEL_OWNERS } from "./constants"
import InputAdornment from "@mui/material/InputAdornment"
import ReusableCustomQuestions from "../components/ReusableCustomQuestions"
import {
  isAddressEnabled,
  isContactsEnabled,
  isDobEnabled,
  isLicenseEnabled,
  isOwnersEnabled,
  isPhoneNumberEnabled,
  isSsnEnabled,
  isOwnersTitleEnabled,
  isOwnersPercentageEnabled,
  isOwnersTitleRequired,
  isOwnersPercentageRequired,
  isDobRequired,
  isLicenseRequired,
  isSsnRequired,
  getNumberOfContacts,
  isControllerEnabled,
} from "./schemas/OwnersSectionSchema"
import {
  isPageRequired,
  isSubSectionEnabled,
  isSubSectionRequired,
} from "./template_helpers"
import { customWordMapping } from "src/statics"

const PAGE_LABEL = PAGE_LABEL_OWNERS

type Props = {
  data: CreditApplication["data"]
  errors: CreditApplication["data"] | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (key: string, value: any) => void
  onContinue: () => void
  onSkip?: () => void
  onCustomChange: (key: string, value: any) => void
}
export default ({
  data,
  errors,
  onChange,
  onContinue,
  onSkip,
  onCustomChange,
}: Props) => {
  const params = useParams()
  const { id } = params
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const businessId = queryParams.get("business_id")

  const { data: application } = useCreditApplication(id || "")

  const { data: template, businessTemplate } = useApplicationTemplate(
    id ? application?.seller?.id : businessId || "",
    true,
    id ? !application?.seller?.id : !businessId,
    id,
    data,
  )

  return (
    <>
      <Typography component="h1" variant="h5" style={{ marginTop: "8px" }}>
        {customWordMapping("Owners & Contacts", businessTemplate)}
        {isPageRequired(PAGE_LABEL, template, data)
          ? ""
          : ` (${customWordMapping("Optional", businessTemplate)})`}
      </Typography>
      <Typography variant="body2" style={{ margin: "8px 0 8px 0" }}>
        Please enter the details of the business owners and/or contacts
      </Typography>
      <Divider style={{ margin: "16px 0 16px 0" }} />

      {isOwnersEnabled(template) &&
        Array.from(Array(2).keys()).map((i) => (
          <Box key={i}>
            <Typography
              component="h1"
              variant="h5"
              style={{ marginTop: "8px" }}
            >
              {customWordMapping("Owner/Principal", businessTemplate)} {i + 1}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "5%",
              }}
            >
              <TextField
                id={"owner-name" + (i + 1)}
                margin="normal"
                fullWidth
                label="Full Name"
                required={isPageRequired(PAGE_LABEL, template, data) && i === 0}
                inputProps={
                  isAddressEnabled(template)
                    ? {
                        style: { height: "56px" },
                      }
                    : {}
                }
                value={data[`ownerName${i + 1}`]}
                onChange={(event) => {
                  onChange("ownerName" + (i + 1), event.target.value)
                }}
                error={!!errors && Boolean(errors[`ownerName${i + 1}`])}
              />
              <TextField
                id={"owner-email" + (i + 1)}
                margin="normal"
                fullWidth
                label="Email"
                required={isPageRequired(PAGE_LABEL, template, data) && i === 0}
                inputProps={
                  isAddressEnabled(template)
                    ? {
                        style: { height: "56px" },
                      }
                    : {}
                }
                value={data[`ownerEmail${i + 1}`]}
                onChange={(event) => {
                  onChange("ownerEmail" + (i + 1), event.target.value)
                }}
                error={!!errors && Boolean(errors[`ownerEmail${i + 1}`])}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "5%",
              }}
            >
              {isOwnersTitleEnabled(template) && (
                <TextField
                  id={"owner-title" + (i + 1)}
                  margin="normal"
                  fullWidth
                  label={"Title/Position"}
                  required={isOwnersTitleRequired(template, data) && i === 0}
                  inputProps={
                    isAddressEnabled(template)
                      ? {
                          style: { height: "56px" },
                        }
                      : {}
                  }
                  value={data[`ownerTitle${i + 1}`]}
                  onChange={(event) => {
                    onChange("ownerTitle" + (i + 1), event.target.value)
                  }}
                  error={!!errors && Boolean(errors[`ownerTitle${i + 1}`])}
                />
              )}
              {isOwnersPercentageEnabled(template) && (
                <TextField
                  id={"owner-percentage" + (i + 1)}
                  margin="normal"
                  type="number"
                  fullWidth
                  label={"Owner Percentage"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  required={
                    isOwnersPercentageRequired(template, data) && i === 0
                  }
                  inputProps={
                    isAddressEnabled(template)
                      ? {
                          style: { height: "56px" },
                        }
                      : {}
                  }
                  value={data[`ownerPercentage${i + 1}`]}
                  onChange={(event) => {
                    onChange("ownerPercentage" + (i + 1), event.target.value)
                  }}
                  error={!!errors && Boolean(errors[`ownerPercentage${i + 1}`])}
                  helperText={!!errors && errors[`ownerPercentage${i + 1}`]}
                />
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "5%",
              }}
            >
              {isDobEnabled(template) && (
                <TextField
                  type="date"
                  id={"owner-dob" + (i + 1)}
                  margin="normal"
                  fullWidth
                  label="Date of Birth"
                  inputProps={
                    isAddressEnabled(template)
                      ? {
                          style: { height: "56px" },
                        }
                      : {}
                  }
                  value={data[`ownerDob${i + 1}`]}
                  required={isDobRequired(template, data) && i === 0}
                  onChange={(event) => {
                    onChange("ownerDob" + (i + 1), event.target.value)
                  }}
                  error={!!errors && Boolean(errors[`ownerDob${i + 1}`])}
                />
              )}
              {isPhoneNumberEnabled(template) && (
                <ReactPhoneInput
                  value={data[`ownerPhoneNumber${i + 1}`]}
                  country={"us"}
                  onChange={(event) => {
                    onChange(`ownerPhoneNumber${i + 1}`, "+" + event)
                  }}
                  countryCodeEditable={false}
                  label="Phone Number"
                  component={TextField}
                  containerStyle={{
                    width: "100%",
                    marginTop: "8px",
                    height: "56px",
                  }}
                  inputProps={{
                    id: `owner-phone-number${i + 1}`,
                    // required: isPageRequired(PAGE_LABEL, template, data),
                    error:
                      !!errors && Boolean(errors[`ownerPhoneNumber${i + 1}`]),
                    inputProps: isAddressEnabled(template)
                      ? {
                          style: { height: "56px" },
                        }
                      : {},
                  }}
                />
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "5%",
              }}
            >
              {isLicenseEnabled(template) && (
                <TextField
                  id={"owner-driver-license" + (i + 1)}
                  margin="normal"
                  fullWidth
                  label="Driver License Number"
                  required={isLicenseRequired(template, data) && i === 0}
                  inputProps={
                    isAddressEnabled(template)
                      ? {
                          style: { height: "56px" },
                        }
                      : {}
                  }
                  value={data[`ownerDriverLicense${i + 1}`]}
                  onChange={(event) => {
                    onChange("ownerDriverLicense" + (i + 1), event.target.value)
                  }}
                  error={
                    !!errors && Boolean(errors[`ownerDriverLicense${i + 1}`])
                  }
                />
              )}

              {isSsnEnabled(template) && (
                <TextField
                  id={"owner-ssn" + (i + 1)}
                  margin="normal"
                  fullWidth
                  label="SSN / SIN"
                  required={isSsnRequired(template, data) && i === 0}
                  inputProps={
                    isAddressEnabled(template)
                      ? {
                          style: { height: "56px" },
                        }
                      : {}
                  }
                  value={data[`ownerSsn${i + 1}`]}
                  onChange={(event) => {
                    onChange("ownerSsn" + (i + 1), event.target.value)
                  }}
                  error={!!errors && Boolean(errors[`ownerSsn${i + 1}`])}
                />
              )}
            </Box>
            {isAddressEnabled(template) && (
              <Address
                keyPrefix={"owner"}
                keySuffix={String(i + 1)}
                data={data}
                onChange={onChange}
                errors={errors}
                required={i === 0}
                defaults={{ country: application?.seller?.country || "" }}
              />
            )}
          </Box>
        ))}
      {isContactsEnabled(template) && (
        <>
          <Typography component="h1" variant="h5" style={{ marginTop: "8px" }}>
            Accounts Payable Contacts
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "5%",
            }}
          >
            <TextField
              id={"ap-name"}
              margin="normal"
              fullWidth
              required={isPageRequired(PAGE_LABEL, template, data)}
              label="Name"
              variant="outlined"
              value={data["apName"]}
              inputProps={
                isAddressEnabled(template)
                  ? {
                      style: { height: "56px" },
                    }
                  : {}
              }
              onChange={(event) => {
                onChange("apName", event.target.value)
              }}
              error={Boolean(errors?.apName)}
            />
            <TextField
              id={"ap-position"}
              margin="normal"
              fullWidth
              required={isPageRequired(PAGE_LABEL, template, data)}
              label="Position"
              variant="outlined"
              value={data["apPosition"]}
              inputProps={
                isAddressEnabled(template)
                  ? {
                      style: { height: "56px" },
                    }
                  : {}
              }
              onChange={(event) => {
                onChange("apPosition", event.target.value)
              }}
              error={Boolean(errors?.apPosition)}
            />
            <TextField
              id={"ap-email"}
              margin="normal"
              fullWidth
              required={isPageRequired(PAGE_LABEL, template, data)}
              label="Email"
              variant="outlined"
              value={data["apEmail"]}
              inputProps={
                isAddressEnabled(template)
                  ? {
                      style: { height: "56px" },
                    }
                  : {}
              }
              onChange={(event) => {
                onChange("apEmail", event.target.value)
              }}
              error={Boolean(errors?.apEmail)}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              gap: "32px",
            }}
          >
            <ReactPhoneInput
              value={data.apPhoneNumber}
              country={"us"}
              onChange={(event) => {
                onChange("apPhoneNumber", "+" + event)
              }}
              countryCodeEditable={false}
              label="Phone Number"
              component={TextField}
              containerStyle={{
                width: "100%",
                marginTop: "8px",
                height: "56px",
              }}
              inputProps={{
                id: "ap-phone-number",
                required: isPageRequired(PAGE_LABEL, template, data),
                error: Boolean(errors?.apPhoneNumber),
                inputProps: isAddressEnabled(template)
                  ? {
                      style: { height: "56px" },
                    }
                  : {},
              }}
            />
            <TextField
              id={"ap-phone-number-ext"}
              fullWidth
              margin="normal"
              label="Extension"
              type="text"
              inputProps={
                isAddressEnabled(template)
                  ? {
                      style: { height: "56px" },
                    }
                  : {}
              }
              value={data.apPhoneNumberExt}
              onChange={(event) => {
                onChange("apPhoneNumberExt", event.target.value)
              }}
            />
          </Box>

          {getNumberOfContacts(template) > 1 &&
            new Array(getNumberOfContacts(template) - 1).fill(0).map((i) => (
              <>
                <Typography
                  component="h1"
                  variant="h5"
                  style={{ marginTop: "8px" }}
                >
                  Contact {i + 2}{" "}
                  {isSubSectionRequired(
                    template,
                    PAGE_LABEL,
                    OWNER_CONFIGS.AP_2_BASE,
                    data,
                  )
                    ? ""
                    : `(${customWordMapping("Optional", businessTemplate)})`}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    gap: "5%",
                  }}
                >
                  {isSubSectionEnabled(
                    template,
                    PAGE_LABEL_OWNERS,
                    OWNER_CONFIGS.AP_2_NAME,
                  ) && (
                    <TextField
                      id={"ap-name-" + (i + 2)}
                      margin="normal"
                      fullWidth
                      label="Name"
                      required={isSubSectionRequired(
                        template,
                        PAGE_LABEL_OWNERS,
                        OWNER_CONFIGS.AP_2_NAME,
                        data,
                      )}
                      variant="outlined"
                      value={data["apName" + (i + 2)]}
                      inputProps={
                        isAddressEnabled(template)
                          ? {
                              style: { height: "56px" },
                            }
                          : {}
                      }
                      onChange={(event) => {
                        onChange("apName" + (i + 2), event.target.value)
                      }}
                      error={Boolean(errors?.["apName" + (i + 2)])}
                    />
                  )}

                  {isSubSectionEnabled(
                    template,
                    PAGE_LABEL_OWNERS,
                    OWNER_CONFIGS.AP_2_ROLE,
                  ) && (
                    <TextField
                      id={"ap-position-" + (i + 2)}
                      margin="normal"
                      fullWidth
                      label="Position"
                      variant="outlined"
                      required={isSubSectionRequired(
                        template,
                        PAGE_LABEL_OWNERS,
                        OWNER_CONFIGS.AP_2_ROLE,
                      )}
                      value={data["apPosition" + (i + 2)]}
                      inputProps={
                        isAddressEnabled(template)
                          ? {
                              style: { height: "56px" },
                            }
                          : {}
                      }
                      onChange={(event) => {
                        onChange("apPosition" + (i + 2), event.target.value)
                      }}
                      error={Boolean(errors?.["apPosition" + (i + 2)])}
                    />
                  )}

                  {isSubSectionEnabled(
                    template,
                    PAGE_LABEL_OWNERS,
                    OWNER_CONFIGS.AP_2_EMAIL,
                  ) && (
                    <TextField
                      id={"ap-email-" + (i + 2)}
                      margin="normal"
                      fullWidth
                      label="Email"
                      required={isSubSectionRequired(
                        template,
                        PAGE_LABEL_OWNERS,
                        OWNER_CONFIGS.AP_2_EMAIL,
                      )}
                      variant="outlined"
                      value={data["apEmail" + (i + 2)]}
                      inputProps={
                        isAddressEnabled(template)
                          ? {
                              style: { height: "56px" },
                            }
                          : {}
                      }
                      onChange={(event) => {
                        onChange("apEmail" + (i + 2), event.target.value)
                      }}
                      error={Boolean(errors?.["apEmail" + (i + 2)])}
                    />
                  )}
                </Box>
                {isSubSectionEnabled(
                  template,
                  PAGE_LABEL_OWNERS,
                  OWNER_CONFIGS.AP_2_PHONE,
                ) && (
                  <Box
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      gap: "32px",
                    }}
                  >
                    <ReactPhoneInput
                      value={data[`apPhoneNumber${i + 2}`]}
                      country={"us"}
                      onChange={(event) => {
                        onChange("apPhoneNumber" + (i + 2), "+" + event)
                      }}
                      countryCodeEditable={false}
                      label="Phone Number"
                      component={TextField}
                      containerStyle={{
                        width: "100%",
                        marginTop: "8px",
                        height: "56px",
                      }}
                      inputProps={{
                        id: "ap-phone-number-" + (i + 2),
                        required: isSubSectionRequired(
                          template,
                          PAGE_LABEL_OWNERS,
                          OWNER_CONFIGS.AP_2_PHONE,
                        ),
                        error: Boolean(errors?.[`apPhoneNumber${i + 2}`]),
                        inputProps: isAddressEnabled(template)
                          ? {
                              style: { height: "56px" },
                            }
                          : {},
                      }}
                    />
                    <TextField
                      id={"ap-phone-number-ext-" + (i + 2)}
                      fullWidth
                      margin="normal"
                      label="Extension"
                      type="text"
                      inputProps={
                        isAddressEnabled(template)
                          ? {
                              style: { height: "56px" },
                            }
                          : {}
                      }
                      value={data[`apPhoneNumberExt${i + 2}`]}
                      onChange={(event) => {
                        onChange(
                          "apPhoneNumberExt" + (i + 2),
                          event.target.value,
                        )
                      }}
                    />
                  </Box>
                )}
              </>
            ))}
        </>
      )}

      {isControllerEnabled(template) && (
        <>
          <Typography component="h1" variant="h5" style={{ marginTop: "8px" }}>
            Controller
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "5%",
            }}
          >
            {isSubSectionEnabled(
              template,
              PAGE_LABEL,
              OWNER_CONFIGS.CONTROLLER_NAME,
            ) && (
              <TextField
                id={"controller-name"}
                margin="normal"
                fullWidth
                required={isSubSectionRequired(
                  template,
                  PAGE_LABEL,
                  OWNER_CONFIGS.CONTROLLER_NAME,
                  data,
                )}
                label="Name"
                variant="outlined"
                value={data["controllerName"]}
                inputProps={
                  isAddressEnabled(template)
                    ? {
                        style: { height: "56px" },
                      }
                    : {}
                }
                onChange={(event) => {
                  onChange("controllerName", event.target.value)
                }}
                error={Boolean(errors?.controllerName)}
              />
            )}

            {isSubSectionEnabled(
              template,
              PAGE_LABEL,
              OWNER_CONFIGS.CONTROLLER_ROLE,
            ) && (
              <TextField
                id={"controller-position"}
                margin="normal"
                fullWidth
                required={isSubSectionRequired(
                  template,
                  PAGE_LABEL,
                  OWNER_CONFIGS.CONTROLLER_ROLE,
                  data,
                )}
                label="Position"
                variant="outlined"
                value={data["controllerPosition"]}
                inputProps={
                  isAddressEnabled(template)
                    ? {
                        style: { height: "56px" },
                      }
                    : {}
                }
                onChange={(event) => {
                  onChange("controllerPosition", event.target.value)
                }}
                error={Boolean(errors?.controllerPosition)}
              />
            )}
            {isSubSectionEnabled(
              template,
              PAGE_LABEL,
              OWNER_CONFIGS.CONTROLLER_EMAIL,
            ) && (
              <TextField
                id={"controller-email"}
                margin="normal"
                fullWidth
                required={isSubSectionRequired(
                  template,
                  PAGE_LABEL,
                  OWNER_CONFIGS.CONTROLLER_EMAIL,
                  data,
                )}
                label="Email"
                variant="outlined"
                value={data["controllerEmail"]}
                inputProps={
                  isAddressEnabled(template)
                    ? {
                        style: { height: "56px" },
                      }
                    : {}
                }
                onChange={(event) => {
                  onChange("controllerEmail", event.target.value)
                }}
                error={Boolean(errors?.controllerEmail)}
              />
            )}
          </Box>
          {isSubSectionEnabled(
            template,
            PAGE_LABEL,
            OWNER_CONFIGS.CONTROLLER_PHONE,
          ) && (
            <Box
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                gap: "32px",
              }}
            >
              <ReactPhoneInput
                value={data.controllerPhoneNumber}
                country={"us"}
                onChange={(event) => {
                  onChange("controllerPhoneNumber", "+" + event)
                }}
                countryCodeEditable={false}
                label="Phone Number"
                component={TextField}
                containerStyle={{
                  width: "100%",
                  marginTop: "8px",
                  height: "56px",
                }}
                inputProps={{
                  id: "controller-phone-number",
                  required: isSubSectionRequired(
                    template,
                    PAGE_LABEL,
                    OWNER_CONFIGS.CONTROLLER_PHONE,
                    data,
                  ),
                  error: Boolean(errors?.controllerPhoneNumber),
                  inputProps: isAddressEnabled(template)
                    ? {
                        style: { height: "56px" },
                      }
                    : {},
                }}
              />
              <TextField
                id={"controller-phone-number-ext"}
                fullWidth
                margin="normal"
                label="Extension"
                type="text"
                inputProps={
                  isAddressEnabled(template)
                    ? {
                        style: { height: "56px" },
                      }
                    : {}
                }
                value={data.controllerPhoneNumberExt}
                onChange={(event) => {
                  onChange("controllerPhoneNumberExt", event.target.value)
                }}
              />
            </Box>
          )}
        </>
      )}

      {template?.customFields && (
        <ReusableCustomQuestions
          application={application}
          associatedPage={PAGE_LABEL_OWNERS}
          data={data["customFields"]}
          dataKeyResponses={data}
          errors={errors}
          onChange={onCustomChange}
        />
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          gap: "2rem",
        }}
      >
        <Button
          id="continue-owner"
          onClick={() => {
            onContinue()
          }}
          size="large"
          fullWidth
          endIcon={<ArrowForward />}
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Save & Continue
        </Button>
        {template?.formTemplate.pages.find((step) => step.label === PAGE_LABEL)
          ?.skippable &&
          onSkip && (
            <Button
              id="continue-owner"
              onClick={() => {
                if (onSkip) onSkip()
              }}
              size="large"
              variant="outlined"
              sx={{ mt: 3, mb: 2 }}
            >
              Skip
            </Button>
          )}
      </Box>
    </>
  )
}
