import * as React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import queryString from "query-string"

export default ({}) => {
  const { search } = useLocation()
  const navigate = useNavigate()

  const queryParams = new URLSearchParams(search)
  const applicationId = queryParams.get("application_id") || ""
  const businessId = queryParams.get("business_id") || ""
  const type = queryParams.get("type") || "trade-credit"

  React.useEffect(() => {
    if (applicationId && businessId) {
      navigate({
        pathname: `/${type}/${applicationId}`,
        search: queryString.stringify({
          business_id: businessId,
        }),
      })
    }
  }, [applicationId, businessId, type, navigate])

  return <>Redirecting...</>
}
