import * as React from "react"

import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import DialogTitle from "@mui/material/DialogTitle"
import Dialog from "@mui/material/Dialog"
import { UploadedFile } from "../../../types"
import { FileOpenOutlined } from "@mui/icons-material"
import { ListItemAvatar } from "@mui/material"

export interface FileListProps {
  open: boolean
  options: Array<UploadedFile | File> | undefined
  onClose: (file: File | UploadedFile | null) => void
}

export default function FileListDialog(props: FileListProps) {
  const { onClose, open, options } = props

  const handleClose = () => {
    onClose(null)
  }

  const handleListItemClick = (value: UploadedFile | File) => {
    onClose(value)
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Select File to View</DialogTitle>
      <List sx={{ pt: 0 }}>
        {options &&
          options.map((file) => (
            <ListItem disableGutters key={file.name}>
              <ListItemButton
                onClick={() => handleListItemClick(file)}
                key={file.name}
              >
                <ListItemAvatar>
                  <FileOpenOutlined />
                </ListItemAvatar>
                <ListItemText primary={file.name} />
              </ListItemButton>
            </ListItem>
          ))}
      </List>
    </Dialog>
  )
}
