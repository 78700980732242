// component
import { Dashboard } from "@mui/icons-material"
import { INTERNAL_EMAIL_ENDING } from "src/statics"
// ----------------------------------------------------------------------

const navConfig = [
  {
    title: "Client Accounts",
    path: "client-accounts",
    icon: <Dashboard />,
  },
]

const getNavConfig = (user: any) => {
  const config = navConfig

  if (user && user.email && user.email.endsWith(INTERNAL_EMAIL_ENDING)) {
    return config
  }
  return []
}

export default getNavConfig
