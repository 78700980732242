import React, { useCallback, useState } from "react"
import {
  Stack,
  Box,
  IconButton,
  MenuItem,
  Popover,
  Tooltip,
  Button,
  useTheme,
  Divider,
  Typography,
  Theme,
  Card,
  Chip,
} from "@mui/material"
import { CreditApplication, TradeReference } from "src/types"
import { useTradeReferences } from "src/queries/credit/useTradeReferences"
import {
  EditOutlined,
  ForwardToInboxOutlined,
  MoreVertOutlined,
} from "@mui/icons-material"
import EditTradeReferenceDialog from "./EditTradeReferenceDialog"
import RequestReferenceDialog from "./RequestReferenceDialog"
import AddNewTradeReferenceDialog from "./AddNewTradeReferenceDialog"
import { useEmailStatus } from "src/queries/vendors/useEmailStatus"
import { formatCurrency } from "src/utils/formatNumber"
import TimelineStepper from "../../components/TimelineStepper"
import { useTradeReferenceTimeline } from "src/queries/credit/useTradeReferenceTimeline"

import { styled } from "@mui/material/styles"
import { BUSINESS_PREFERENCES, usePreference } from "src/hooks/use-preference"
import { EmailWithValidation } from "./EmailWithValidation"

interface TradeReferenceCardProps {
  application: CreditApplication
  reference: TradeReference
  theme: Theme
  hasBounced: (email?: string) => boolean
  index: number
  refetch: any
}

const ReceivedChip = styled(Chip)(() => ({
  backgroundColor: "rgba(0, 200, 83, 0.1)",
  color: "#00C853",
  borderRadius: "16px",
  fontWeight: "bold",
  marginLeft: "8px",
}))

const ReminderSentChip = styled(Chip)(() => ({
  backgroundColor: "rgba(255, 152, 0, 0.1)",
  color: "#FF9800",
  borderRadius: "16px",
  fontWeight: "bold",
  marginLeft: "8px",
}))

const RequestedChip = styled(Chip)(() => ({
  backgroundColor: "rgba(158, 158, 158, 0.1)",
  color: "#616161",
  borderRadius: "16px",
  fontWeight: "bold",
  marginLeft: "8px",
}))

const TradeReferenceCard: React.FC<TradeReferenceCardProps> = ({
  application,
  reference,
  theme,
  hasBounced,
  index,
  refetch,
}) => {
  const [open, setOpen] = useState<TradeReference | null>(null)

  const [add, setAdd] = useState<TradeReference | undefined>(undefined)
  const [request, setRequest] = useState<TradeReference | undefined>(undefined)

  const handleCloseMenu = () => {
    setOpen(null)
  }

  const {
    data,
    isLoading,
    error,
    refetch: refetchSteps,
  } = useTradeReferenceTimeline(reference.id, application.id)

  const { preference: netTermsPreference } = usePreference(
    BUSINESS_PREFERENCES.ASK_NET_TERMS_IN_TRADE_REFERENCE,
    application.seller,
  )

  if (isLoading) return <div>Loading...</div>

  const { timeline: STEPS = [], activeStep = 0 } = data || {}

  return (
    <Card>
      <Stack spacing={3} sx={{ p: 3, typography: "body2" }}>
        {hasBounced(reference?.email) && (
          <Box
            style={{
              border: "solid",
              borderWidth: "2px",
              borderRadius: "16px",
              padding: "8px",
              borderColor: theme.palette.error.main,
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              gap: "8px",
            }}
          >
            <Typography style={{ wordBreak: "break-word" }}>
              Deliverability warning: our systems indicate that emails sent to
              this address have previously been rejected. Please verify the
              email address in case the reference request is not fulfilled in
              time.
            </Typography>
          </Box>
        )}

        <Box
          style={{
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          {reference && STEPS && (
            <Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="h5" gutterBottom>
                    Reference {index}
                  </Typography>
                  {STEPS[activeStep - 1].label === "Reminder" && (
                    <ReminderSentChip label="Reminder Sent" />
                  )}
                  {STEPS[activeStep - 1].label === "Received" && (
                    <ReceivedChip label="Received" />
                  )}
                  {STEPS[activeStep - 1].label === "Requested" && (
                    <RequestedChip label="Requested" />
                  )}
                  {STEPS[activeStep - 1].label === "Not Requested" && (
                    <RequestedChip label="Not Requested" />
                  )}
                </Box>
                {reference && (
                  <IconButton
                    id={"more-" + reference?.id}
                    size="large"
                    color="inherit"
                    onClick={() => setOpen(reference)}
                  >
                    <MoreVertOutlined />
                  </IconButton>
                )}
              </Box>

              <Stack spacing={2}>
                <Typography variant="h6">Reference Info</Typography>

                <Box
                  display="grid"
                  gridTemplateColumns="1fr 1fr"
                  gap="8px"
                  alignItems="center"
                  justifyItems="start"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width="60%"
                  >
                    {/* Vendor Name */}
                    <Box>
                      <Typography variant="body2" style={{ color: "#637381" }}>
                        Vendor Name
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" align="left">
                        {reference.name}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width="70%"
                  >
                    {/* Email */}
                    <Box>
                      <Typography variant="body2" style={{ color: "#637381" }}>
                        Email
                      </Typography>
                    </Box>
                    <Box>
                      <EmailWithValidation email={reference.email} />
                    </Box>
                  </Box>

                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width="60%"
                  >
                    {/* Phone Number */}
                    <Box>
                      <Typography variant="body2" style={{ color: "#637381" }}>
                        Phone number
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" align="left">
                        {reference.phoneNumber}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width="60%"
                  >
                    {/* Fax Number */}
                    <Box>
                      <Typography variant="body2" style={{ color: "#637381" }}>
                        Fax number
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" align="left">
                        {reference.faxNumber}
                      </Typography>
                    </Box>
                  </Box>
                  {/* Account Number */}
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width="70%"
                  >
                    <Box>
                      <Typography variant="body2" style={{ color: "#637381" }}>
                        Account number
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" align="left">
                        {reference.accountNumber
                          ? `Account # ${reference.accountNumber}`
                          : "No Account"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Typography variant="h6">Trade Reference Response</Typography>

                <Box
                  display="grid"
                  gridTemplateColumns="1fr 1fr"
                  gap="8px"
                  alignItems="center"
                  justifyItems="start"
                >
                  {netTermsPreference !== false && (
                    <Tooltip
                      title="Indicates the payment terms extended to the customer at the time of reporting"
                      arrow
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        width="60%"
                      >
                        <Box>
                          <Typography
                            variant="body2"
                            style={{ color: "#637381" }}
                          >
                            Payment Terms
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="body2">
                            {reference.terms
                              ? reference.terms === "0"
                                ? "Cash"
                                : `Net ${reference.terms}`
                              : "---"}
                          </Typography>
                        </Box>
                      </Box>
                    </Tooltip>
                  )}

                  <Tooltip
                    title="Balance of the customer at the time of reporting"
                    arrow
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="70%"
                    >
                      <Box>
                        <Typography
                          variant="body2"
                          style={{ color: "#637381" }}
                        >
                          Current Balance
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="body2">
                          {reference.balance
                            ? `${formatCurrency(
                                reference.balance,
                                reference.currency,
                              )}`
                            : "---"}
                        </Typography>
                      </Box>
                    </Box>
                  </Tooltip>

                  <Tooltip
                    title="Indicates the highest credit limit extended to the customer at the time of reporting"
                    arrow
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="60%"
                    >
                      <Box>
                        <Typography
                          variant="body2"
                          style={{ color: "#637381" }}
                        >
                          Credit Limit
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="body2">
                          {reference.creditLimit
                            ? `${formatCurrency(
                                reference.creditLimit,
                                reference.currency,
                              )}`
                            : "---"}
                        </Typography>
                      </Box>
                    </Box>
                  </Tooltip>

                  <Tooltip
                    title="Average number of days it has historically taken the borrower to repay the invoice to the reference"
                    arrow
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="70%"
                    >
                      <Box>
                        <Typography
                          variant="body2"
                          style={{ color: "#637381" }}
                        >
                          Avg. Days to Repay
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="body2">
                          {reference.avgDaysToRepay
                            ? `${reference.avgDaysToRepay} days`
                            : "---"}
                        </Typography>
                      </Box>
                    </Box>
                  </Tooltip>

                  <Tooltip
                    title="Indicates how many days past due the customer is at the time of reporting"
                    arrow
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="60%"
                    >
                      <Box>
                        <Typography
                          variant="body2"
                          style={{ color: "#637381" }}
                        >
                          Days Past
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="body2">
                          {reference.paymentStatus
                            ? reference.paymentStatus.toLowerCase() ===
                                "current" ||
                              reference.paymentStatus.toLowerCase() === "future"
                              ? reference.paymentStatus
                              : `${reference.paymentStatus} days`
                            : "---"}
                        </Typography>
                      </Box>
                    </Box>
                  </Tooltip>

                  <Tooltip
                    title="Date the customer account was opened with the reference"
                    arrow
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="70%"
                    >
                      <Box>
                        <Typography
                          variant="body2"
                          style={{ color: "#637381" }}
                        >
                          Customer Since
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="body2">
                          {reference.accountOpenDate || "---"}
                        </Typography>
                      </Box>
                    </Box>
                  </Tooltip>

                  <Tooltip
                    title="Date the customer last purchased from the reference"
                    arrow
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="60%"
                    >
                      <Box>
                        <Typography
                          variant="body2"
                          style={{ color: "#637381" }}
                        >
                          Last Sale
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="body2">
                          {reference.lastSoldDate || "---"}
                        </Typography>
                      </Box>
                    </Box>
                  </Tooltip>
                </Box>
              </Stack>
            </Box>
          )}
        </Box>
        {reference && (
          <Box
            style={{
              border: "solid",
              borderWidth: "2px",
              borderRadius: "16px",
              padding: "8px",
              borderColor: theme.palette.primary.main,
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              gap: "8px",
            }}
          >
            <Typography variant="subtitle1" style={{ wordBreak: "break-word" }}>
              Considerations:
            </Typography>
            <Typography style={{ wordBreak: "break-word" }}>
              {reference.reviewItems && reference.reviewItems.length > 0
                ? reference.reviewItems.join(", ")
                : "None"}
            </Typography>
            <Typography variant="subtitle1" style={{ wordBreak: "break-word" }}>
              Comments:
            </Typography>
            <Typography style={{ wordBreak: "break-word" }}>{`${
              reference.comments || "None"
            }`}</Typography>
          </Box>
        )}
        {!error &&
          !isLoading &&
          STEPS &&
          STEPS[activeStep - 1].label !== "Not Requested" && (
            <TimelineStepper steps={STEPS} activeStep={activeStep} />
          )}

        {open && (
          <Popover
            open={Boolean(open)}
            anchorEl={document.getElementById("more-" + open.id)}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            PaperProps={{
              sx: {
                p: 1,
                "& .MuiMenuItem-root": {
                  px: 1,
                  typography: "body2",
                  borderRadius: 0.75,
                },
              },
            }}
          >
            <MenuItem
              onClick={() => {
                setAdd(open)
                setOpen(null)
              }}
            >
              <Box style={{ display: "flex", gap: "1rem" }}>
                <EditOutlined />
                View / Edit
              </Box>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setRequest(open)
                setOpen(null)
              }}
            >
              <Box style={{ display: "flex", gap: "1rem" }}>
                <ForwardToInboxOutlined />
                Send Request for Reference
              </Box>
            </MenuItem>
          </Popover>
        )}

        {request && (
          <RequestReferenceDialog
            reference={request}
            open={!!request}
            onClose={() => {
              setRequest(undefined)
              refetchSteps()
            }}
          />
        )}

        {add && (
          <EditTradeReferenceDialog
            reference={add}
            open={!!add}
            onClose={() => {
              setAdd(undefined)
              refetch()
            }}
          />
        )}
      </Stack>
    </Card>
  )
}

export default ({ application }: { application: CreditApplication }) => {
  const { data: tradeRefs, refetch } = useTradeReferences(application.id)

  const theme = useTheme()
  const [createNewReference, setCreateNewReference] = useState(false)

  const { data: emailStatus } = useEmailStatus(
    tradeRefs?.map((r) => r.email) || [],
    application.id || "",
  )

  const hasBounced = useCallback(
    (email?: string) => {
      if (!email) return false
      return emailStatus
        ?.find((response: any) => response.email === email)
        ?.messages?.find(
          (e: any) => e.events?.find((ev: any) => ev.eventName === "bounce"),
        )
    },
    [emailStatus],
  )

  const newRefButton = (
    <Button
      variant="contained"
      onClick={() => {
        setCreateNewReference(true)
      }}
      fullWidth
      style={{
        height: "50px",
        marginTop: "20px",
        backgroundColor: "#919EAB29",
        color: "black",
        border: "1px solid black",
        borderRadius: "12px", // Ensure text is readable on the background
      }}
    >
      + New Reference
    </Button>
  )

  return (
    <>
      {tradeRefs?.map((v, i) => (
        <React.Fragment key={i}>
          <TradeReferenceCard
            application={application}
            reference={v}
            hasBounced={hasBounced}
            theme={theme}
            index={i + 1}
            refetch={refetch}
          />
          {i < tradeRefs.length - 1 && <Divider style={{ marginTop: "8px" }} />}
        </React.Fragment>
      ))}
      {tradeRefs?.length === 0 && (
        <Typography
          variant="subtitle1"
          style={{ marginTop: "20px", marginLeft: "20px" }}
        >
          No trade references added
        </Typography>
      )}
      {newRefButton}
      {createNewReference && application.id && (
        <AddNewTradeReferenceDialog
          applicationId={application.id}
          onClose={() => {
            setCreateNewReference(false)
            refetch()
          }}
          open={createNewReference}
        />
      )}
    </>
  )
}
