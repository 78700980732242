import { QueryClient } from "react-query"
import { useTypedMutation } from "../useTypedMutation"

// to be used for generating apps based on the base template
export function usePostUploadedFile(onSuccess?: () => void) {
  const post = useTypedMutation<FormData>(
    "post/application/base/uploads",
    onSuccess,
  )

  const execute = (body: FormData, onSuccess: () => void) => {
    post.mutate(
      {
        method: "post",
        endpoint: "/application/base/uploads",
        body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: ["/application/base/uploads".replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
      },
    )
  }
  return { ...post, execute }
}
