import { useAuth0 } from "@auth0/auth0-react"
import { useQuery } from "react-query"
import { User, Group } from "../../types"

export function useUser() {
  const { user: auth0User, isLoading, isAuthenticated, logout } = useAuth0()

  const djangoResponse = useQuery<User>(
    `/users/${(auth0User?.sub || "").replace("|", ".")}/`,
    {
      enabled: !isLoading && isAuthenticated && !!auth0User?.sub,
      retry: (failureCount, error) => {
        if (failureCount < 1) {
          return true
        }
        // do not retry on 404
        return (error as any)?.response?.status !== 404
      },
    },
  )

  let isBuyer = false
  let isSales = false
  let isCreditManager = false
  let isNetNowAdmin = false

  if (djangoResponse.isSuccess) {
    if (djangoResponse.data.groups) {
      isBuyer = djangoResponse.data.groups.includes(Group.Buyer)
      isSales = djangoResponse.data.groups.includes(Group.Sales)
      isCreditManager = djangoResponse.data.groups.includes(Group.CreditManager)
      isNetNowAdmin = djangoResponse.data.groups.includes(Group.NetNowAdmin)
    }
  }

  return {
    auth0User,
    isBuyer,
    isSales,
    isCreditManager,
    isNetNowAdmin,
    logout,
    ...djangoResponse,
  }
}
