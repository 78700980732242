import queryString from "query-string"
import { useQuery } from "react-query"
import { ExperianSearchResult } from "src/types/vendors.types"

export function useExperianSearch(
  businessId: string,
  searchQuery: string,
  perPage: number,
  page: number,
  queryParams: Record<string, string>,
) {
  const queryKey =
    "/document/experian_search?" +
    queryString.stringify({
      business_id: businessId,
      name: searchQuery,
      per_page: perPage,
      page,
      ...queryParams,
    })
  type Response = {
    results: ExperianSearchResult[]
    purchasedIds: string[]
  }
  return useQuery<Response>(queryKey, {
    enabled: !!businessId && !!searchQuery,
    retry: (failureCount, error) => {
      if (failureCount < 1) {
        return true
      }
      // do not retry on 404
      return (error as any)?.response?.status !== 404
    },
  })
}

export function useExperianPurchasedReport(applicationId: string) {
  const queryKey = "/document/ex_reports?application_id=" + applicationId
  return useQuery<{ url: string; createdAt: string }[]>(queryKey, {
    enabled: !!applicationId,
    retry: (failureCount, error) => {
      if (failureCount < 1) {
        return true
      }
      // do not retry on 404
      return (error as any)?.response?.status !== 404
    },
  })
}

// disabled by default
export function useExperianReport(
  applicationId: string,
  bin: string,
  enabled = false,
) {
  const queryKey =
    "/document/experian_credit_report_pdf?" +
    queryString.stringify({
      application_id: applicationId,
      bin,
    })
  return useQuery<any>(queryKey, {
    enabled,
    retry: (failureCount, error) => {
      if (failureCount < 1) {
        return true
      }
      // do not retry on 404
      return (error as any)?.response?.status !== 404
    },
  })
}
