import { useQuery } from "react-query"
import { TradeReference } from "../../types"
import queryString from "query-string"

export function useTradeReferences(
  applicationId?: string,
  referenceId?: string,
) {
  return useQuery<Array<TradeReference>>(
    queryString.stringifyUrl({
      url: "/application/base/trade_references",
      query: { application_id: applicationId, reference_id: referenceId },
    }),
    {
      enabled: !!applicationId || !!referenceId,
      meta: { anonymous: !Boolean(applicationId) },
      retry: (failureCount, error) => {
        if (failureCount < 1) {
          return true
        }
        // do not retry on 404
        return (error as any)?.response?.status !== 404
      },
    },
  )
}
