import { useMutation } from "react-query"
import { MutationVariables } from "../types/global.types"

/**
 * An implementation of react-query's useMutation
 * The benefit here is that the type of the variable passed to .mutate() is defined.
 * MutationVariables is the type of the variables
 * T is the type of the body of the request. See MutationVariables and CustomReactQueryProvider
 * @param queryKey
 * @returns a mutation you call .mutate(variables) on.
 */
export function useTypedMutation<T>(
  queryKey: string,
  onSuccess = (data: any) => {
    return
  },
) {
  return useMutation<unknown, unknown, MutationVariables<T>>(queryKey, {
    onSuccess,
    retry: 0,
    retryDelay: 3000,
  })
}
