import { CloudUploadOutlined } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import { Box, Typography, Divider } from "@mui/material"
import { CreditApplication, ApplicationTemplate } from "src/types"
import ReusableCustomQuestions from "../components/ReusableCustomQuestions"
import * as yup from "yup"

type Props = {
  application?: CreditApplication
  errors: CreditApplication["data"] | undefined
  data: CreditApplication["data"]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (key: string, value: any) => void
  onContinue: () => void
}

export default ({ application, errors, data, onChange, onContinue }: Props) => {
  return (
    <>
      <Typography component="h1" variant="h5" style={{ marginTop: "8px" }}>
        Additional Questions
      </Typography>
      <Typography variant="body2" style={{ margin: "8px 0 8px 0" }}>
        We have some more questions to finalize your application.
      </Typography>
      <Divider style={{ margin: "16px 0 16px 0" }} />
      <form>
        <ReusableCustomQuestions
          application={application}
          associatedPage="Additional Questions"
          data={data["customFields"]}
          dataKeyResponses={data}
          errors={errors}
          onChange={onChange}
        />
        <Box style={{ display: "flex", flexDirection: "row", gap: "32px" }}>
          <LoadingButton
            onClick={() => {
              onContinue()
            }}
            size="large"
            fullWidth
            endIcon={<CloudUploadOutlined />}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Submit
          </LoadingButton>
        </Box>
      </form>
    </>
  )
}
