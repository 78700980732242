import { useState } from "react"
import OpenCorporateSearchbar from "./components/OpenCorporateSearchbar"
import { CreditApplication } from "src/types"

import { Box, Button, Card, CardContent, CardHeader } from "@mui/material"
import { ScreenSearchDesktopOutlined } from "@mui/icons-material"

import emitter from "src/services/emitter-service"

export default function CreditApplicationBusinessRegistries({
  application,
  setOpenCompanyDataDialogs,
}: {
  application: CreditApplication
  setOpenCompanyDataDialogs: (value: boolean) => void
}) {
  const [isSearchDialogOpen, setIsSearchDialogOpen] = useState(false)
  const [timeStamp, setTimeStamp] = useState(0)

  const handleSearchButtonClick = () => {
    setIsSearchDialogOpen(true)
    setTimeStamp(Date.now())
    emitter.emit(
      "applicationLegalBusinessName",
      application.data.legalBusinessName,
    )
  }

  const handleCloseSearchDialog = () => {
    setIsSearchDialogOpen(false)
    setOpenCompanyDataDialogs(true)
  }
  return (
    <>
      <CardHeader
        title={"Business Registries"}
        subheader="Search global business registries for incorporation, officer and corporate events"
      />
      <CardContent style={{ display: "flex", justifyContent: "right" }}>
        <Button
          onClick={handleSearchButtonClick}
          variant="contained"
          style={{ margin: "1rem" }}
          startIcon={<ScreenSearchDesktopOutlined />}
        >
          Search
        </Button>
      </CardContent>
      <OpenCorporateSearchbar
        isSearchDialogOpen={isSearchDialogOpen}
        handleCloseSearchDialog={handleCloseSearchDialog}
      />
    </>
  )
}
