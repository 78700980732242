import { useQuery } from "react-query"
import { DocumentFields } from "../../types"

export function useDocumentFields(reqId: string, category = "application") {
  return useQuery<DocumentFields>(
    `/document/fields?req_id=${reqId}&category=${category}`,
    {
      enabled: !!reqId,
      retry: (failureCount, error) => {
        if (failureCount < 1) {
          return true
        }
        // do not retry on 404
        return (error as any)?.response?.status !== 404
      },
    },
  )
}
