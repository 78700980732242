import { ArrowForward } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Button,
  Checkbox,
  FormControlLabel,
} from "@mui/material"
import ReactPhoneInput from "react-phone-input-material-ui"

import { ApplicationTemplate, Application } from "src/types"
import { useState, useEffect } from "react"
import PDFViewerDialog from "src/components/pdf/PDFViewerDialog"
import ReusableCustomQuestions from "../components/ReusableCustomQuestions"
import { useLocation, useParams } from "react-router-dom"
import { APPLICANT_TYPES, ROLES } from "src/statics"
import { PDFDocument } from "pdf-lib"
import { useAnonymousBusiness } from "src/queries/credit/useAnonymousBusiness"
import {
  FORM_INPUT_NAMES,
  PAGE_LABEL_USER_DETAILS,
  USER_CONFIG_APPLICANT_TYPE,
  USER_CONFIG_BUSINESS_TYPE,
} from "./constants"
import {
  getAdditionalApplicantTypes,
  getApplicantTypes,
  getBusinessTypes,
} from "../utils"
import { getIndividualConsumerHomeownerEnabled } from "./schemas/UserRegistrationSectionSchema"
import { isSubSectionEnabled } from "./template_helpers"

type Props = {
  application?: Application
  data: Application["data"]
  errors: Application["data"] | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (key: string, value: any) => void
  onCustomChange: (key: string, value: any) => void
  onContinue: (onFormValidationError: () => void) => void
  template: ApplicationTemplate
}

export default ({
  application,
  data,
  errors,
  onChange,
  onCustomChange,
  onContinue,
  template,
}: Props) => {
  const [openTerms, setOpenTerms] = useState<boolean | string>(false)

  const [loading, setLoading] = useState(false)

  const [showApplicantType, setShowApplicantType] = useState(true)

  const params = useParams()
  const { id } = params

  const { search } = useLocation()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const queryParams = new URLSearchParams(search)
  const businessId = queryParams.get("business_id") || ""
  const [termsAccepted, setTermsAccepted] = useState(false)

  const { data: business } = useAnonymousBusiness(businessId || "")

  useEffect(() => {
    if (template) {
      if (
        template.formTemplate.pages
          .find((step) => step.label === PAGE_LABEL_USER_DETAILS)
          ?.config?.find(
            (config) => config.label === USER_CONFIG_APPLICANT_TYPE,
          )?.value === false
      ) {
        onChange(FORM_INPUT_NAMES.APPLICANT_TYPE, APPLICANT_TYPES.CORPORATION)
        onChange(FORM_INPUT_NAMES.BUSINESS_TYPE, "Other")
        setShowApplicantType(false)
      }
    }
  }, [template, onChange])

  return (
    <>
      <Typography component="h1" variant="h5" style={{ marginTop: "8px" }}>
        Your Details
      </Typography>
      <Typography variant="body2" style={{ margin: "8px 0 8px 0" }}>
        Information of the person filling out this form. In case we have any
        questions, this will be the main point of contact.
      </Typography>
      <Divider style={{ margin: "16px 0 16px 0" }} />
      <form>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            gap: "32px",
            alignItems: "center",
          }}
        >
          <TextField
            id="first-name"
            fullWidth
            label="First Name"
            type="text"
            required
            value={data.firstName}
            onChange={(event) => {
              onChange(FORM_INPUT_NAMES.FIRST_NAME, event.target.value)
            }}
            error={Boolean(errors?.firstName)}
          />
          <TextField
            id="last-name"
            margin="normal"
            required
            fullWidth
            label="Last Name"
            type="text"
            value={data.lastName}
            onChange={(event) => {
              onChange(FORM_INPUT_NAMES.LAST_NAME, event.target.value)
            }}
            error={Boolean(errors?.lastName)}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "32px",
            alignItems: "center",
          }}
        >
          <TextField
            margin="normal"
            disabled={!!id}
            fullWidth
            required
            label="Contact Email"
            type="email"
            id="email"
            value={data.email}
            onChange={(event) => {
              onChange(
                FORM_INPUT_NAMES.EMAIL,
                event.target.value?.toLowerCase() || "",
              )
            }}
            error={Boolean(errors?.email)}
          />
          <Box
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              gap: "32px",
            }}
          >
            <ReactPhoneInput
              value={data.userPhoneNumber}
              country={"us"}
              onChange={(event) => {
                onChange(FORM_INPUT_NAMES.USER_PHONE_NUMBER, "+" + event)
              }}
              countryCodeEditable={false}
              label="Phone Number"
              component={TextField}
              containerStyle={{
                width: "100%",
                marginTop: "8px",
                height: "56px",
              }}
              inputProps={{
                id: "phone-number",
                required: true,
                error: Boolean(errors?.userPhoneNumber),
              }}
            />
            <TextField
              id="phone-number-ext"
              fullWidth
              margin="normal"
              label="Extension"
              type="text"
              value={data.userPhoneNumberExt}
              onChange={(event) => {
                onChange(
                  FORM_INPUT_NAMES.USER_PHONE_NUMBER_EXT,
                  event.target.value,
                )
              }}
            />
          </Box>
        </Box>
        {showApplicantType && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "5%",
              alignItems: "center",
            }}
          >
            <FormControl
              fullWidth
              margin="normal"
              style={{ flex: "1 1 0px" }}
              required
            >
              <InputLabel id="applicant-type-label">Applicant Type</InputLabel>
              <Select
                labelId="applicant-type-label"
                id="applicant-type-select"
                label="Applicant Type"
                value={data.applicantType}
                onChange={(event) => {
                  onChange(FORM_INPUT_NAMES.APPLICANT_TYPE, event.target.value)
                  onChange("businessType", "")
                  onChange("role", "")
                }}
                style={{ height: "56px" }}
                error={Boolean(errors?.applicantType)}
              >
                <MenuItem disabled value={undefined}>
                  Select one
                </MenuItem>
                {getApplicantTypes(template)
                  .filter((option) =>
                    getIndividualConsumerHomeownerEnabled(template)
                      ? true
                      : option !==
                        APPLICANT_TYPES.INDIVIDUAL_CONSUMER_HOMEOWNER,
                  )
                  .map((type) => (
                    <MenuItem value={type} key={"applicant" + type}>
                      {type}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {getAdditionalApplicantTypes(template)
              .concat([
                APPLICANT_TYPES.LLC,
                APPLICANT_TYPES.CORPORATION,
                APPLICANT_TYPES.LIMITED_PARTNERSHIP,
                APPLICANT_TYPES.PARTNERSHIP,
                APPLICANT_TYPES.NON_PROFIT,
                APPLICANT_TYPES.SOLE_PROPRIETORSHIP,
                APPLICANT_TYPES.OTHER,
              ])
              .includes(data.applicantType) &&
              isSubSectionEnabled(
                template,
                PAGE_LABEL_USER_DETAILS,
                USER_CONFIG_BUSINESS_TYPE,
              ) && (
                <>
                  <FormControl
                    fullWidth
                    margin="normal"
                    style={{ flex: "1 1 0px" }}
                    required
                  >
                    <InputLabel id="business-type-label">
                      Business Type
                    </InputLabel>
                    <Select
                      labelId="business-type-label"
                      id="business-type-select"
                      label="Business Type"
                      value={data.businessType}
                      onChange={(event) => {
                        onChange(
                          FORM_INPUT_NAMES.BUSINESS_TYPE,
                          event.target.value,
                        )
                      }}
                      style={{ height: "56px" }}
                      error={Boolean(errors?.businessType)}
                    >
                      <MenuItem disabled value={undefined}>
                        Select one
                      </MenuItem>

                      {getBusinessTypes(template).map((type) => (
                        <MenuItem value={type} key={"business" + type}>
                          {type}
                        </MenuItem>
                      ))}

                      <MenuItem value={"Other"} key={"businessOther"}>
                        Other
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {data.businessType === "Other" && (
                    <TextField
                      id="specify"
                      margin="normal"
                      label="Specify Business Type"
                      type="text"
                      required
                      value={data.businessTypeOther}
                      onChange={(event) => {
                        onChange(
                          FORM_INPUT_NAMES.BUSINESS_TYPE_OTHER,
                          event.target.value,
                        )
                      }}
                    />
                  )}
                </>
              )}
          </Box>
        )}

        {getAdditionalApplicantTypes(template)
          .concat([
            APPLICANT_TYPES.CORPORATION,
            APPLICANT_TYPES.LLC,
            APPLICANT_TYPES.PARTNERSHIP,
            APPLICANT_TYPES.LIMITED_PARTNERSHIP,
            APPLICANT_TYPES.SOLE_PROPRIETORSHIP,
            APPLICANT_TYPES.NON_PROFIT,
            APPLICANT_TYPES.OTHER,
          ])
          .includes(data.applicantType) &&
          showApplicantType && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "32px",
                alignItems: "center",
              }}
            >
              <Typography style={{ width: "100%" }}>
                What is your role in the business?
              </Typography>

              <FormControl fullWidth margin="normal">
                <InputLabel id="role">Role</InputLabel>

                <Select
                  labelId="role-label"
                  id="role-select"
                  label="Role"
                  value={data.role}
                  onChange={(event) => {
                    onChange(FORM_INPUT_NAMES.ROLE, event.target.value)
                  }}
                  style={{ height: "56px" }}
                >
                  <MenuItem disabled value={undefined}>
                    Select one
                  </MenuItem>
                  {ROLES.map((role) => (
                    <MenuItem value={role} key={"role" + role}>
                      {role}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {data.role === "Other" && (
                <TextField
                  id="specify"
                  margin="normal"
                  label="Specify Role"
                  type="text"
                  required
                  value={data.roleOther}
                  onChange={(event) => {
                    onChange(FORM_INPUT_NAMES.ROLE_OTHER, event.target.value)
                  }}
                />
              )}
            </Box>
          )}
        {template?.customFields && (
          <ReusableCustomQuestions
            application={application}
            associatedPage="User Details"
            data={data["customFields"]}
            dataKeyResponses={data}
            errors={errors}
            onChange={onCustomChange}
          />
        )}

        <Box
          sx={{
            display: "flex",
            width: "100%",
            justfityContent: "left",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                id="terms-checkbox"
                value="remember"
                color="primary"
                onChange={(event) => {
                  setTermsAccepted(event.target.checked)
                }}
                checked={termsAccepted}
              />
            }
            label={
              <div>
                I accept the
                <Button
                  onClick={async () => {
                    const mergedPdf = await PDFDocument.create()
                    let documents = [
                      "https://storage.googleapis.com/netnow-web-assets-production/Terms%20of%20Service%20-%20NetNow.pdf",
                    ]

                    if (business?.terms) {
                      // order is important
                      documents = [
                        business.terms as string,
                        "https://storage.googleapis.com/netnow-web-assets-production/Terms%20of%20Service%20-%20NetNow.pdf",
                      ]

                      for (const document of documents) {
                        const existingPdfBytes = await fetch(document).then(
                          (res) => res.arrayBuffer(),
                        )

                        const pdfDoc = await PDFDocument.load(existingPdfBytes)

                        const copiedPages = await mergedPdf.copyPages(
                          pdfDoc,
                          pdfDoc.getPageIndices(),
                        )
                        copiedPages.forEach((page) => mergedPdf.addPage(page))
                      }

                      const pdfBytes = await mergedPdf.save()

                      const docUrl = URL.createObjectURL(
                        new Blob([pdfBytes], { type: "application/pdf" }),
                      )
                      setOpenTerms(docUrl)
                    } else {
                      setOpenTerms(documents[0])
                    }
                  }}
                  style={{ marginBottom: "3px" }}
                >
                  terms and conditions
                </Button>
              </div>
            }
          />
        </Box>

        {openTerms && (
          <PDFViewerDialog
            fileUrl={openTerms as string}
            onClose={() => setOpenTerms(false)}
          />
        )}

        <Box style={{ display: "flex", flexDirection: "row", gap: "32px" }}>
          <LoadingButton
            id="user-continue"
            disabled={!termsAccepted}
            loading={loading}
            onClick={() => {
              setLoading(true)
              onContinue(() => {
                setLoading(false)
              })
            }}
            size="large"
            fullWidth
            endIcon={<ArrowForward />}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Continue
          </LoadingButton>
        </Box>
      </form>
    </>
  )
}
