import { QueryClient } from "react-query"
import { UploadedCreditReport } from "../../types"
import { useTypedMutation } from "../useTypedMutation"

export function useDeleteUploadedCreditReport(onSuccess?: () => void) {
  const ENDPOINT = "/application/base/credit_report"
  const d = useTypedMutation<Pick<UploadedCreditReport, "id">>(
    "/application/base/credit_report",
    onSuccess,
  )

  const execute = (id: string) => {
    d.mutate(
      {
        method: "delete",
        endpoint: ENDPOINT + "/" + id + "/",
        body: {},
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
        },
      },
    )
  }
  return { ...d, execute }
}