import { Button, Paper, Typography } from "@mui/material"
import { useState } from "react"
import ViewCreditApplicationDialog from "../ViewCreditApplicationDialog"

export default function CreditApplicationsCard({ id }: { id: string }) {
  const [openCreditDialog, setOpenCreditDialog] = useState(false)
  return (
    <>
      <Paper elevation={3} sx={{ marginY: 2, p: 2 }}>
        <Typography variant="h6" sx={{ marginBottom: 1 }}>
          Credit Applications
        </Typography>
        <Button
          variant="contained"
          onClick={() => {
            setOpenCreditDialog(true)
          }}
        >
          View Credit Applications
        </Button>
      </Paper>
      {openCreditDialog && (
        <ViewCreditApplicationDialog
          open={openCreditDialog}
          onClose={() => {
            setOpenCreditDialog(false)
          }}
          businessId={id}
        />
      )}
    </>
  )
}
