import PropTypes from "prop-types"
import { forwardRef } from "react"
// @mui
import { Box } from "@mui/material"

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx }, ref) => {
  // using local (public folder)
  // -------------------------------------------------------
  const logo = (
    <Box
      component="img"
      src="/assets/logo.png"
      sx={{ width: 40, height: 40, cursor: "pointer", ...sx }}
    />
  )

  if (disabledLink) {
    return <>{logo}</>
  }

  return <a href="https://netnow.io">{logo}</a>
})

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
}

export default Logo
