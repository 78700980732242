export const loadLogoIntoImg = (img, logo) => {
  if (logo && img) {
    if (logo instanceof Blob) {
      if (FileReader) {
        const fr = new FileReader()
        fr.onload = function () {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          img.src = fr.result
        }
        fr.readAsDataURL(logo)
      }
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      img.src = logo
    }
  }
}
