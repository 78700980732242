import { QueryClient } from "react-query"
import { CreditApplicationApprovalRequest } from "../../types"
import { useTypedMutation } from "../useTypedMutation"

export function usePostCreditApplicationApproval(
  onSuccess?: (data: CreditApplicationApprovalRequest) => void,
) {
  const ENDPOINT = "/application/base/credit/approval_request/"
  const post = useTypedMutation<Partial<CreditApplicationApprovalRequest>>(
    "post/application/base/credit/approval_request",
    onSuccess,
  )

  const execute = (
    id: string,
    body: CreditApplicationApprovalRequest,
    onSuccess?: () => void,
    onError?: () => void,
  ) => {
    post.mutate(
      {
        endpoint: ENDPOINT + id + "/",
        body,
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
        onError,
      },
    )
  }
  return { ...post, execute }
}
