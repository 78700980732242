import { Button, Container, Stack } from "@mui/material"
import { enqueueSnackbar } from "notistack"
import { useCreateInternalCashApplicationTemplate } from "src/queries/internal/useCreateInternalCashApplicationTemplate"
import { CreateApplicationTemplate } from "src/types"
import { confirm } from "src/components/confirm/"
import { useCashApplicationTemplate } from "src/queries/cod/useCashApplicationTemplate"
import EditCashEmailNotes from "./components/EditCashEmailNotes"
import EditCashApplicationTemplate from "./components/EditCashApplicationTemplate"
import CashUrlPopulation from "./components/ApplicationTemplate/CashUrlPopulation"
import CashApplicationsCard from "./components/ApplicationTemplate/CashApplicationsCard"

export default function ClientCashDetails({ id }: { id: string }) {
  const {
    execute: executeCreateCashApplication,
    isLoading: isLoadingCreateTemplate,
  } = useCreateInternalCashApplicationTemplate(() => {
    enqueueSnackbar("Cash Application Template Created", {
      variant: "success",
    })
  })
  const {
    data,
    refetch,
    isLoading: isFetchingTemplate,
  } = useCashApplicationTemplate(id)
  return (
    <Container sx={{ p: 2 }}>
      {isFetchingTemplate && "Loading..."}
      {!isFetchingTemplate && !data && (
        <>
          <Stack sx={{ p: 1 }}>
            <Button
              variant="contained"
              disabled={isLoadingCreateTemplate}
              onClick={() => {
                confirm(
                  "Are you Sure you want to Activate Cash Applications For This Account?",
                )
                  .then(
                    () => {
                      executeCreateCashApplication(
                        { seller: id } as CreateApplicationTemplate,
                        () => {
                          refetch()
                        },
                      )
                      return
                    },
                    () => {
                      console.log("error")
                    },
                  )
                  .catch(() => {
                    console.log("error")
                  })
              }}
            >
              Activate Cash Application
            </Button>
          </Stack>
        </>
      )}
      {data && (
        <>
          <CashApplicationsCard id={id as string} />
          <CashUrlPopulation id={id as string} />
          <EditCashApplicationTemplate
            applicationTemplate={data}
            refetch={refetch}
          />
          <EditCashEmailNotes applicationTemplate={data} refetch={refetch} />
        </>
      )}
    </Container>
  )
}
