import { QueryClient } from "react-query"
import { useTypedMutation } from "../useTypedMutation"
import { UserToBusinessRequest } from "src/types"

export function useInternalSelectableUserSendInviteEmail(
  onSuccess?: () => void,
) {
  const post = useTypedMutation<UserToBusinessRequest>(
    "post/internal/selectable-user/invite/send",
    onSuccess,
  )
  const execute = (body: UserToBusinessRequest, onSuccess: () => void) => {
    post.mutate(
      {
        method: "post",
        endpoint: "/internal/selectable-user/invite/send/",
        body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [
              "internal/selectable-user/invite/send/".replaceAll("/", ""),
            ],
          })
          if (onSuccess) onSuccess()
        },
      },
    )
  }
  return { ...post, execute }
}
